.sl-lang {
    --padding-top: 3px;
    --padding-bottom: 3px;
    --padding-start: calc(20px - 36px / 4);
    --border-width: 0 0 0 1px;
    --border-style: none none none solid;
    --border-color: transparent transparent transparent rgba(224, 222, 220, 0.5);

    .bazis-dropdown {
        --width: 112px;
        --top: 32px;
        height: 24px;
        line-height: 24px;

        &__body {
            &::before {
                position: absolute;
                content: '';
                top: -16px;
                height: 16px;
                left: 0;
                right: 0;
            }

            .bazis-list_select {
                --padding-top-list: var(--bazis-padding-3x);
                --padding-bottom-list: var(--bazis-padding-3x);
                --border-radius-list: var(--bazis-border-radius);
            }
        }
    }
}
