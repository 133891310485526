.bazis-backdrop {
    /*
    * @prop --background: фон подложки
    * @prop --opacity: прозрачность подложки
    */
    --background: initial;
    --opacity: initial;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: var(--background);
    opacity: var(--opacity);
}
