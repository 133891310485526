.bazis-context-menu {
    --position-x: 0;
    --position-y: 0;
    position: fixed;
    z-index: 2000;

    top: calc(var(--position-y) * 1px - 10px);
    left: calc(var(--position-x) * 1px + 10px);
    // min-width: 150px;
    // min-height: 150px;
    // background: pink;
}