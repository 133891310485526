.bazis-paginator {
    &,
    &_secondary {
        .bazis-btn-group {
            .bazis-btn-group__item > bazis-button.bazis-btn_solid {
                --background-hover: var(--bazis-color-info-tint);
                --color-hover: var(--color);

                &:hover::part(native),
                &:focus-visible::part(native) {
                    border-color: var(--border-color);
                }

                &:active:hover::part(native) {
                    border-color: var(--border-color);
                    color: var(--color);
                }
            }
        }
    }

    @media (max-width: 1024px) {
        &,
        &_secondary {
            margin-top: var(--bazis-margin-6x);
        }
    }

    @media (max-width: 576px) {
        &,
        &_secondary {
            .bazis-control {
                --control-width: 64px;
            }
        }
    }

    @media (max-width: 400px) {
        &_secondary {
            display: flex;
        }
    }
}
