.bazis-dateplate {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    &__time {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        bazis-icon {
            --bazis-icon-xs-size: 14px;
            margin-right: 6px;
        }

        &_small {
            font-size: 14px;
            line-height: 20px;

            bazis-icon {
                margin-bottom: 2px;
            }
        }
    }
}
