@mixin props-position() {
    --top: initial;
    --bottom: initial;
    --left: initial;
    --right: initial;
}
@mixin position() {
    top: var(--top);
    bottom: var(--bottom);
    left: var(--left);
    right: var(--right);
}

@mixin props-block-model() {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --box-shadow: initial;
}
@mixin block-model() {
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    box-shadow: var(--box-shadow);
}

@mixin props-margin() {
    --margin-start: 0;
    --margin-end: 0;
    --margin-top: 0;
    --margin-bottom: 0;
}
@mixin margin() {
    margin-left: var(--margin-start);
    margin-right: var(--margin-end);
    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
}

@mixin props-padding() {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
}
@mixin padding() {
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
}

@mixin props-border() {
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
}
@mixin border() {
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
}

@mixin props-width() {
    --min-width: initial;
    --width: initial;
    --max-width: initial;
}
@mixin width() {
    min-width: var(--min-width);
    width: var(--width);
    max-width: var(--max-width);
}

@mixin props-height() {
    --min-height: initial;
    --height: initial;
    --max-height: initial;
}
@mixin height() {
    min-height: var(--min-height);
    height: var(--height);
    max-height: var(--max-height);
}

@mixin props-width-height {
    --width: initial;
    --height: initial;
}
@mixin width-height {
    width: var(--width);
    height: var(--height);
}

@mixin props-colors() {
    --background: initial;
    --color: inherit;
}
@mixin colors() {
    background: var(--background);
    color: var(--color);
}

@mixin props-font(){
    --font-size: inherit;
    --font-weight: inherit;
    --line-height: initial;
}
@mixin font(){
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
}
