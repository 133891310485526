.bazis-selectable-cards {
    --padding-top: var(--bazis-padding-3x);
    --padding-bottom: var(--bazis-padding-3x);
    --padding-start: var(--bazis-padding-3x);
    --padding-end: var(--bazis-padding-3x);

    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-width: 1px;
    --border-radius: var(--bazis-border-radius);

    --background: var(--bazis-color-white);
    --color: var(--bazis-text-color-rgb);
    --background-active: var(--bazis-color-action);
    --border-color-active: var(--border-color-action);
    --color-active: var(--bazis-text-color-contrast);
    --background-disabled: var(--bazis-text-color-secondary);
    --border-color-disabled: var(--background-disabled);
    --color-disabled: var(--bazis-text-color-contrast);

    &__item {
        &__title {
            gap: var(--bazis-margin-4x);
            font-weight: 500;
        }

        bazis-icon {
            color: var(--bazis-placeholder-color);
        }

        &__description {
            margin-top: var(--bazis-margin-1x);
            font-size: 11px;
            line-height: 12px;
            color: var(--bazis-text-color-secondary);
        }

        input:checked + & {
            .bazis-selectable-cards__item__description {
                color: rgba(var(--bazis-text-color-contrast-rgb), .8);
            }
            bazis-icon {
                color: var(--color-active);
            }
        }
    }
}
