.bazis-coordinates {
    display: inline-flex;
    align-items: center;
    margin-bottom: var(--bazis-margin-3x);
    color: var(--bazis-text-color-secondary);
    cursor: pointer;

    // bazis-button {
    //     --padding-start: 18px;
    //     --padding-end: 18px;
    //     margin-left: var(--bazis-margin-1x);
    // }

    bazis-icon {
        margin-left: var(--bazis-margin-2x);
        font-size: 12px;
    }
}
