.bazis-stepper {
    /*
    * @prop --background-list: фон списка
    *
    * @prop --border-radius-list: радиус скругления списка
    * @prop --border-width-list: толщина границы списка
    * @prop --border-style-list: стиль границы списка
    * @prop --border-color-list: цвет границы списка
    *
    * @prop --padding-top-list: внутренний верхний отступ списка
    * @prop --padding-end-list: внутренний конечный отступ списка
    * @prop --padding-bottom-list: внутренний нижний отступ списка
    * @prop --padding-start-list: внутренний начальный отступ списка
    *
    * @prop --margin-top-list: внешний верхний отступ списка
    * @prop --margin-end-list: внешний конечный отступ списка
    * @prop --margin-bottom-list: внешний нижний отступ списка
    * @prop --margin-start-list: внешний начальный отступ списка
    * @prop --box-shadow: тень списка
    *
    * @prop --max-height: максимальная высота списка
    *
    * @prop --background: фон шага
    * @prop --background-hover: фон шага при наведении
    * @prop --background-active: фон активного шага
    *
    * @prop --border-radius: радиус скругления шага
    * @prop --border-width: толщина границы шага
    * @prop --border-style: стиль границы шага
    * @prop --border-color: цвет границы шага
    *
    * @prop --padding-top: внутренний верхний отступ шага
    * @prop --padding-end: внутренний конечный отступ шага
    * @prop --padding-bottom: внутренний нижний отступ шага
    * @prop --padding-start: внутренний начальный отступ шага
    *
    * @prop --margin-top: внешний верхний отступ шага
    * @prop --margin-end: внешний конечный отступ шага
    * @prop --margin-bottom: внешний нижний отступ шага
    * @prop --margin-start: внешний начальный отступ шага
    *
    * @prop --min-height: минимальная высота шага
    *
    * @prop --list-style: стиль списка
    *
    * @prop --color: цвет текста
    * @prop --color-hover: цвет текста при наведении
    * @prop --color-active: цвет текста активного шага
    * @prop --font-size: размер шрифта
    * @prop --font-weight: жирность шрифта
    * @prop --line-height: межстрочный интервал для текста
    */
    --min-height: initial;
    --min-width: initial;
    --border-width: initial;
    --border-color: initial;
    --border-style: initial;
    --padding-start: initial;
    --padding-end: initial;
    --padding-top: initial;
    --padding-bottom: initial;
    --margin-start: initial;
    --margin-end: initial;
    --margin-top: initial;
    --margin-bottom: initial;
    --background: initial;
    --background-hover: var(--background);
    --background-disabled: var(--background);
    --background-active: var(--background);
    --color: inherit;
    --color-hover: var(--color);
    --color-disabled: var(--color);
    --color-active: var(--color);
    --font-size: inherit;
    --font-weight: inherit;
    --line-height: inherit;

    --width-item-before: initial;
    --width-item-after: initial;

    --max-height: initial;
    --padding-start-list: 0;
    --padding-end-list: 0;
    --padding-top-list: 0;
    --padding-bottom-list: 0;
    --margin-start-list: 0;
    --margin-end-list: 0;
    --margin-top-list: 0;
    --margin-bottom-list: 0;
    --background-list: initial;
    --border-radius-list: initial;
    --border-width-list: initial;
    --border-color-list: initial;
    --border-style-list: initial;
    --box-shadow: none;

    display: flex;
    align-items: center;
    max-height: var(--max-height);
    border-radius: var(--border-radius-list);
    border-width: var(--border-width-list);
    border-style: var(--border-style-list);
    border-color: var(--border-color-list);
    padding: var(--padding-top-list) var(--padding-end-list) var(--padding-bottom-list)
        var(--padding-start-list);
    margin: var(--margin-top-list) var(--margin-end-list) var(--margin-bottom-list)
        var(--margin-start-list);
    background: var(--background-list);
    box-shadow: var(--box-shadow);

    .bazis-item {
        position: relative;
        flex-shrink: 0;
        min-width: var(--min-width);
        min-height: var(--min-height);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        margin-left: var(--margin-start);
        margin-right: var(--margin-end);
        margin-top: var(--margin-top);
        margin-bottom: var(--margin-bottom);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        background: var(--background);
        list-style: var(--list-style);
        font-size: var(--font-size);
        font-weight: var(--font-weight);
        line-height: var(--line-height);
        color: var(--color);
        cursor: pointer;

        &:first-child {
            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 0;
                clip-path: url(#step-arrow);
                width: var(--width-item-before);
                height: var(--min-height);
                transform: translateY(-50%);
                background-color: var(--background);
            }
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 50%;
            left: calc(100% - 0.5px);
            clip-path: url(#step-arrow);
            width: var(--width-item-after);
            height: var(--min-height);
            transform: translateY(-50%);
            background-color: var(--background);
            background-image: url('/assets/images/step-border.svg');
            background-position: 100% 50%;
        }

        &:hover {
            background: var(--background-hover);
            color: var(--color-hover);
        }

        &--disabled {
            background: var(--background-disabled);
            color: var(--color-disabled);
            pointer-events: none;

            &::after {
                background-color: var(--background-disabled);
            }
        }

        &--active,
        &--active:hover,
        &:active,
        &:active:hover {
            background: var(--background-active);
            color: var(--color-active);

            &::after {
                background-color: var(--background-active);
            }
        }
    }
}
