.bazis-contacts-control {
    &__group {
        --margin-bottom: var(--bazis-margin-6x);
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 0;

        bazis-toolbar {
            --height: 36px;
            --bazis-font-size-p-major: 16px;
            --bazis-line-height-p-major: 24px;
            --margin-bottom: var(--bazis-margin-3x);
        }
    }

    bazis-toolbar {
        --margin-bottom: 2px;

        h6 {
            line-height: 36px;
        }
    }
}
