th {
    --padding-start: var(--bazis-padding-2x);
    --padding-top: var(--bazis-padding-2x);
    --padding-end: var(--bazis-padding-2x);
    --padding-bottom: var(--bazis-padding-2x);
    --font-size: 13px;
    --font-weight: 500;
    --line-height: 20px;

    text-align: left;

    &:last-child {
        --padding-end: var(--bazis-padding-6x);
        text-align: right;
    }

    thead & {
        --background: var(--bazis-background-medium);
        --color: var(--bazis-text-color-secondary);
    }

    tbody & {
        --background: var(--bazis-background-accent);
        --color: var(--bazis-text-color);
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--bazis-background-dark);
    }
}

td {
    --padding-start: var(--bazis-padding-2x);
    --padding-top: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-2x);
    --padding-bottom: var(--bazis-padding-4x);
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-width: 0 0 1px 0;
    --line-height: 20px;

    tbody & {
        .bazis-dropdown {
            &__trigger {
                --background: var(--bazis-color-white);
                --color: var(--bazis-placeholder-color);
            }
            &__body {
                --top: 36px;
            }

            .bazis-list_control-dd {
                --margin-start-list: 0;
                --margin-end-list: 0;
                --margin-top-list: 0;
                --margin-bottom-list: 0;
                width: var(--width);
            }
        }

        bazis-icon {
            --bazis-icon-s-size: 16px;
            color: var(--bazis-placeholder-color);
        }
    }

    &:last-child {
        --padding-end: var(--bazis-padding-3x);
    }
}

table {
    --margin-bottom: var(--bazis-margin-6x);

    .bazis-control {
        --margin-bottom: 0;
    }

    .bazis-checkbox__label,
    .bazis-radio__label {
        &::before {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
