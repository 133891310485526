.sl-section {
    @include props-margin;
    @include margin;

    &__content {
        @include props-padding;
        @include padding;
    }
}

.sl-section {
    --margin-top: var(--bazis-margin-8x);
    // --margin-bottom: 40px;

    .sl-form_draft & {
        // --margin-bottom: 0;
        max-width: 884px;
    }
}

/////////

.sl-header-section {
    @include props-margin;
    @include margin;
}

.sl-header-section.bazis-block {
    --margin-top: var(--bazis-margin-5x);
    --margin-bottom: calc(var(--bazis-margin-6x) * 2);
    --padding-top: var(--bazis-padding-8x);
}
