.bazis-card {
    &.sl-card_with-button {
        --padding-bottom: calc(36px + 16px + var(--bazis-padding-3x));

        > bazis-button,
        > bazis-skeleton:last-child {
            position: absolute;
            bottom: 16px;
            left: var(--bazis-padding-3x);
            right: var(--bazis-padding-3x);
        }

        .bazis-card__title {
            margin-bottom: var(--bazis-margin-1x);
        }
    }
}

.sl-card {
    &__date {
        position: absolute;
        bottom: 8px;
        left: var(--padding-start);
        right: var(--padding-end);
        color: var(--bazis-text-color-secondary);
    }

    &_trip {
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --border-radius: 0;
        --width: auto;
        --border-color: transparent;
        --border-color-hover: var(--bazis-background-dark);
        --margin-bottom: var(--bazis-margin-4x);
        height: auto;
        cursor: pointer;

        .bazis-card__header {
            height: 36px;
            padding: 0 var(--bazis-padding);
            background: var(--bazis-background-medium);
            line-height: 36px;
        }

        .bazis-card__body {
            padding: var(--bazis-padding);
        }

        .bazis-card__title {
            font-size: 16px;
            line-height: 24px;
        }

        &--selected {
            --border-color: var(--bazis-color-action);
            --border-color-hover: var(--border-color);
        }

        &-wrapper {
            height: 500px;
            overflow: auto;
            padding-right: var(--bazis-padding-2x);
        }
    }
}
