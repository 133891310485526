.bazis-avatar {
    --width: 64px;
    --height: 64px;
    --background: var(--bazis-color-primary);
    --border-radius: 50%;
    --color: var(--bazis-color-white);
    --font-size: 18px;
    letter-spacing: 0.5px;

    &_small {
        --font-size: 12px;
        --font-weight: 500;
    }
}