bazis-tabs {
    --padding-bottom: var(--bazis-padding-2x);
    --color: var(--bazis-text-color);
    --height: 48px;
    --font-size: 16px;
    --font-weight: 400;
    --line-height: 1;

    --font-weight-selected: 500;

    --tab-background-selected: transparent;
    --tab-border-width: 0 0 2px 0;
    --tab-border-style: solid;
    --tab-border-color: transparent;
    --tab-border-color-hover: var(--bazis-text-color);
    --tab-border-color-selected: var(--bazis-text-color);
    --tab-margin-end: var(--bazis-margin-6x);

    --dropdown-width: 256px;
    --dropdown-top: calc(var(--height) + var(--bazis-margin-2x));
    --dropdown-padding-top: var(--bazis-padding-4x);
    --dropdown-padding-bottom: var(--bazis-padding-4x);
    --dropdown-background: var(--bazis-color-white);
    --dropdown-border-radius: var(--bazis-border-radius);
    --dropdown-border-width: 1px;
    --dropdown-border-style: solid;
    --dropdown-border-color: var(--bazis-background-medium);
    --dropdown-box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);

    &::part(wrapper) {
        margin-bottom: calc(-1 * var(--padding-bottom));
    }

    &::part(dropdown) {
        --tab-border-width: 0 0 0 2px;
        --tab-padding-top: var(--bazis-padding-3x);
        --tab-padding-bottom: var(--bazis-padding-3x);
        --tab-padding-start: var(--bazis-padding-4x);
        --tab-padding-end: var(--bazis-padding-4x);
        --tab-margin-end: 0;
    }

    .bazis-tile & {
        --height: 36px;
        --tab-margin-end: var(--bazis-margin-4x);
        --font-size: 13px;

        &::part(badge) {
            --background: var(--bazis-color-action);
        }
    }
}
