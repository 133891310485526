.bazis-vehicle-number-control {
    --width-group: initial;

    .bazis-control__field {
        --height: auto;
        flex-shrink: 0;
        padding: 0;

        &-group {
            align-items: flex-end;
            width: var(--width-group);
        }
    }

    input {
        outline: none;
        border: none;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        background: transparent;
        text-align: center;

        &.ng-dirty.ng-touched + .bazis-vehicle-number-control__placeholder {
            opacity: 0;
        }
    }

    &__placeholder {
        position: absolute;
        background: transparent;
        pointer-events: none;

        &::placeholder {
            text-align: center;
        }
    }

    &_rus {
        .bazis-vehicle-number-control__part {
            @for $i from 0 through 3 {
                &_#{$i} {
                    @include props-margin;
                    @include props-font;

                    @include margin;
                    @include font;
                }
            }
        }
    }
}
