.bazis-counter {
    // type
    &_line {
        .bazis-counter__item {
            --border-radius: 1px;
            --height: 12px;
            --width: 2px;
            margin: 0 1px;
        }

        .bazis-counter__total {
            --font-size: 12px;
            --line-height: 12px;

            --margin-end: var(--bazis-margin-2x);
        }
    }
}
