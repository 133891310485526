.bazis-accordion_clear {
    .sl-grid_global {
        margin-bottom: 40px;
    }
}

// все типы аккордионов с переопределяющими переменными компонента
.sl-accordion {
    &_faq {
        --icon-l-size: 20px;
        --icon-color: var(--bazis-text-color-secondary);
        --icon-color-hover: var(--bazis-color-primary);
        --icon-color-active: var(--bazis-color-primary);

        --open-close-icon-size: 16px;
        --open-close-width: 32px;
        --open-close-height: 32px;
        --open-close-color-hover: var(--bazis-color-primary);
        --open-close-color-active: var(--bazis-color-primary);

        --padding-top-body: 0;
        --padding-bottom-body: var(--bazis-padding-5x);

        &::part(header):hover {
            --color-header: var(--bazis-color-action);
        }

        &.bazis-accordion--opened {
            --color-header: var(--bazis-color-primary);
        }

        app-faq-answer &.bazis-accordion_underline:first-child {
            --border-width-header: 0 0 1px 0;
        }

        app-faq-answer &.bazis-accordion_underline.bazis-accordion--opened:first-child {
            --border-width-header: 0;
        }

        app-faq-answer:first-of-type &.bazis-accordion_underline,
        app-faq-answer-skeleton:last-of-type &.bazis-accordion_underline {
            --border-width-header: 1px 0;
        }

        app-faq-answer:first-of-type &.bazis-accordion_underline.bazis-accordion--opened,
        app-faq-answer-skeleton &.bazis-accordion_underline {
            --border-width-header: 1px 0 0;
        }

        app-faq-answer:last-of-type & {
            --border-width-body: 0 0 1px 0;
        }
    }

    &_info {
        --margin-top: var(--bazis-margin-5x);
        --open-close-color: var(--bazis-text-color-secondary);

        --background-header: var(--bazis-background-accent);
        --padding-start-header: var(--bazis-padding-5x);
        --padding-end-header: var(--bazis-padding-5x);
        --padding-top-header: var(--bazis-padding-3x);
        --padding-bottom-header: var(--bazis-padding-3x);
        --border-width-header: 0;

        --padding-start-body: var(--bazis-padding-5x);
        --padding-end-body: var(--bazis-padding-5x);
        --padding-top-body: 0;
        --padding-bottom-body: var(--bazis-padding-3x);
        --background-body: var(--bazis-background-accent);
        --border-radius-body: 0 0 var(--bazis-border-radius) var(--bazis-border-radius);

        &:not(.bazis-accordion--opened) {
            --border-radius-header: var(--bazis-border-radius);
        }

        &.bazis-accordion--opened {
            --border-radius-header: var(--bazis-border-radius) var(--bazis-border-radius) 0 0;
        }

        .sl-table_clear tr {
            &:first-child {
                td {
                    --padding-top: 0;
                }
            }
            &:last-child {
                td {
                    --padding-bottom: 0;
                }
            }
            td {
                --padding-top: var(--bazis-padding-1x);
                --padding-bottom: var(--bazis-padding-1x);
            }
        }
    }

    &_profile {
        --open-close-color: var(--bazis-color-action);
        --open-close-icon-size: 24px;

        --background-header: transparent;
        --border-width-header: 0;
        --padding-bottom-header: 0;
        --padding-top-header: 0;
        --padding-start-header: 0;
        --padding-end-header: 0;

        --padding-top-body: var(--bazis-padding-6x);
        --padding-start-body: calc(var(--bazis-padding-6x) * 2);

        &::part(header) {
            font-size: 18px;
            line-height: 24px;
        }

        &::part(body) {
            .sl-form + bazis-toolbar {
                --padding-top: var(--bazis-padding-8x);
            }
        }

        &:last-child {
            --margin-bottom: 0;
        }

        bazis-icon[slot='icon'] {
            --icon-l-size: 24px;
            margin-right: var(--bazis-margin-6x);
        }

        section {
            h6 {
                font-weight: 500;
            }
            hr {
                --margin-top: var(--bazis-margin-6x);
                --margin-bottom: var(--bazis-margin-6x);
            }
        }

        @media (max-width: 1024px) {
            --padding-start-body: calc(var(--bazis-padding-6x) * 2 - 4px);

            bazis-icon[slot='icon'] {
                margin-right: var(--bazis-margin-5x);
            }
        }

        @media (max-width: 576px) {
            // bazis-grid bazis-button {
            //     display: block;
            // }

            bazis-input-options-button,
            bazis-browse-options-button {
                .bazis-btn-group__item {
                    width: 50%;

                    bazis-button {
                        display: block;
                    }
                }
            }

            bazis-toolbar.bazis-toolbar_wrap {
                // flex-direction: column;

                &::part(start),
                &::part(end) {
                    width: 100%;
                }
            }

            .bh-line-elements {
                flex-direction: column;
            }

            bazis-button:not(
                    .bazis-control__field-group bazis-button,
                    .bazis-browse-default bazis-button
                ) {
                display: block;
                width: 100%;
            }
        }
    }

    &_landing {
        --margin-bottom: var(--bazis-margin-3x);

        --border-radius-header: 12px;
        --border-width-header: 0;
        --padding-start-header: var(--bazis-padding-6x);
        --padding-end-header: var(--bazis-padding-6x);
        --padding-top-header: var(--bazis-padding-6x);
        --padding-bottom-header: var(--bazis-padding-6x);
        --background-header: var(--bazis-background);

        --padding-start-body: var(--bazis-padding-6x);
        --padding-end-body: var(--bazis-padding-6x);
        --padding-bottom-body: var(--bazis-padding-6x);
        --padding-top-body: 6px;
        --background-body: var(--bazis-background);
        --box-shadow-body: 0px 4px 24px rgba(0, 0, 0, 0.1);
        --border-radius-body: 0 0 12px 12px;

        position: relative;

        &::part(header) {
            position: relative;
            font-size: 18px;
            line-height: 24px;
        }

        &::part(body) {
            position: absolute;
            top: calc(100% - 9px);
            left: 0;
            right: 0;
            max-height: 285px;
            overflow: auto;
            font-size: 14px;
            line-height: 20px;
        }

        &.bazis-accordion--opened {
            --color-header: var(--bazis-color-primary);
            z-index: 151;
            border-radius: 12px;
            background: var(--bazis-background);

            &::part(header) {
                z-index: 1;
            }
        }
    }
}

@media (max-width: 576px) {
    app-wastes-widget-group bazis-accordion.bazis-accordion_clear {
        --margin-bottom: var(--bazis-margin-4x);

        &::part(header) {
            --padding-start-header: var(--bazis-padding-5x);
            --padding-end-header: var(--bazis-padding-5x);
            --padding-top-header: var(--bazis-padding-5x);
            --padding-bottom-header: var(--bazis-padding-5x);

            --border-style-header: solid;
            --border-color-header: var(--bazis-border-color);
        }

        &:not(.bazis-accordion--opened) {
            &::part(header) {
                --border-radius-header: 8px;
                --border-width-header: 1px;
            }
        }

        &.bazis-accordion--opened {
            &::part(header) {
                --border-radius-header: 8px 8px 0 0;
                --border-width-header: 1px 1px 0;
            }
        }

        &::part(body) {
            --padding-start-body: var(--bazis-padding-5x);
            --padding-end-body: var(--bazis-padding-5x);
            --padding-top-body: 0;
            --padding-bottom-body: var(--bazis-padding-5x);

            --border-width-body: 0 1px 1px;
            --border-style-body: solid;
            --border-color-body: var(--bazis-border-color);

            --border-radius-body: 0 0 8px 8px;
        }

        h6.bazis-h6_major {
            line-height: var(--bazis-line-height-h6);

            span.bh-block {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
            }
        }
    }
}
