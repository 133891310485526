bazis-badge {
    --background: rgba(var(--bazis-text-color-secondary-rgb, 0, 0, 0), 0.2);

    --height: 12px;
    --height-xxs: 6px;
    --height-xs: 8px;
    --height-medium: 16px;
    --height-large: 24px;
    --padding-start: 2px;
    --padding-end: 2px;
    --margin-start: var(--bazis-margin-1x);
    --margin-end: var(--bazis-margin-1x);

    --font-size: 9px;
    --font-size-medium: 12px;
    --font-size-large: 16px;
    --font-weight: 500;
    --color: var(--bazis-text-color-secondary);
}
