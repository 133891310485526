.bazis-map-wrapper {
    --height: 350px;

    .bazis-map-point-control & {
        margin-bottom: var(--bazis-margin-6x);

        &.bh-no-margin {
            margin-bottom: 0;
        }
    }

}
