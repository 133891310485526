.bazis-blocking-message {
    @include props-padding;
    @include props-colors;
    @include props-font;

    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    @include colors;
    @include font;

    @extend .bazis-container;

    .bazis-container & {
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
    }

    &.bazis-color {
        --background: var(--bazis-color-base);
        --color: var(--bazis-color-contrast);
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > *:not(.bazis-blocking-message__close){
            width: 100%;
        }

        > .bazis-blocking-message__close {
            flex-shrink: 0;
        }
    }
}
