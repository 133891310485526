// все типы панелей инструментов с переопределяющими переменными компонента сетки
.sl-toolbar {
    &_table {
        --padding-top: var(--bazis-padding-8x);
        --padding-bottom: var(--bazis-padding-5x);

        flex-wrap: wrap;

        & + & {
            &::part(end) {
                margin-top: 0;
            }
        }

        app-bid &,
        & + & {
            --padding-top: 0;
        }

        .bazis-btn-group {
            margin: 0 var(--bazis-margin-8x) 0 var(--bazis-margin-4x);

            &:last-child {
                margin-right: 0;
            }
        }

        .sl-form & {
            --margin-top: var(--bazis-margin);
            --padding-top: 14px;
            --padding-bottom: var(--bazis-padding-3x);
        }

        @media (max-width: 992px) {
            &::part(start),
            &::part(end) {
                width: 100%;
                flex-shrink: 0;
            }
            &::part(start) {
                margin-bottom: var(--bazis-margin-8x);
            }

            .sl-dropdown_settings-filter {
                bazis-button.bazis-dropdown__trigger {
                    height: 40px;

                    bazis-icon[slot='end'] {
                        display: none;
                    }
                    bazis-icon[slot='start'] {
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }

    &_searchable {
        --width-other-control: 184px;
        --margin-bottom: var(--bazis-margin-5x);

        &::part(start) {
            width: 100%;
        }

        bazis-input-default.sl-search-control {
            width: calc(100% - var(--width-other-control) - var(--bazis-margin));
        }
    }

    &_header-dispatcher {
        --padding-top: 10px;
        --padding-bottom: 10px;
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --background: var(--bazis-color-white);

        bazis-input-default {
            width: 330px;
        }

        .bazis-tab-status {
            bazis-badge {
                --height-xs: 6px;
                --margin-start: 0;
                --margin-end: var(--bazis-margin-2x);
                border-radius: 2px;
                border-bottom: none;
                vertical-align: middle;
            }
        }
    }

    &_table-header {
        --margin-top: calc(var(--bazis-margin-1x) * -1);
        --padding-bottom: var(--bazis-padding-4x);

        .bazis-dropdown {
            --top: 24px;
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);
            --padding-top: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);
            --margin-start-list: calc(var(--bazis-padding-3x) * -1);
            --margin-end-list: calc(var(--bazis-padding-3x) * -1);

            bazis-button.bazis-dropdown__trigger {
                --color: var(--bazis-placeholder-color);
            }
        }
    }

    &_notification {
        --padding-bottom: var(--bazis-padding-5x);
        --border-width: 0 0 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
    }

    &_tabs {
        flex-wrap: nowrap;
        gap: var(--bazis-margin-8x);

        // &::part(start) {
        //     overflow-x: auto;
        // }

        &::part(end) {
            margin-top: var(--bazis-margin-5x);
            flex-shrink: 0;
        }
    }

    @media (max-width: 1024px) {
        &_table-header {
            --margin-top: 0;
            --padding-top: var(--bazis-padding-1x);
            --padding-bottom: var(--bazis-padding-1x);
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-1x);
            --border-width: 1px 1px 0 1px;
            --border-style: solid;
            --border-color: var(--bazis-border-color);

            .bazis-dropdown {
                --width: 250px;
            }
        }

        &_notification {
            --border-width: 0;
        }
    }

    @media (max-width: 992px) {
        &_searchable {
            .bazis-control {
                --height: 36px;
            }
        }
    }

    @media (max-width: 576px) {
        bazis-accordion &_searchable {
            --width-other-control: 100%;

            .bh-line-elements {
                --gap: var(--bazis-margin-2x);
                flex-direction: column;
            }

            .bazis-control,
            bazis-input-default.sl-search-control {
                width: 100%;
                max-width: 100%;
            }
        }
        &_notification {
            flex-direction: column;

            &::part(start),
            &::part(end) {
                width: 100%;
            }

            .bh-line-elements {
                flex-direction: column;
                width: 100%;
            }

            .bazis-control {
                --width: 100%;
            }
        }
    }
}

bazis-toolbar {
    app-account-history & {
        gap: var(--bazis-margin-4x);

        &::part(start),
        [slot='start'],
        bazis-tabs-slider {
            width: 100%;
        }
    }

    @media (max-width: 576px) {
        app-account-history &:not(.sl-card-mobile bazis-toolbar),
        app-account-reservation &:not(.sl-card-mobile bazis-toolbar) {
            &::part(start),
            &::part(end) {
                width: 100%;
            }
        }
    }
}
