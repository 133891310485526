.bazis-tag {
    .sl-filter &,
    .sl-filter-applied-wrap &,
    bazis-analytics-selected-item & {
        --height: 24px;
        --border-radius: 12px;
        --padding-start: var(--bazis-padding);
        margin-bottom: var(--bazis-margin);
        margin-right: var(--bazis-margin-3x);
        line-height: 2;

        > span {
            @extend .bh-ellipsis;
            max-width: 350px;

            @media (max-width: 576px) {
                max-width: 300px;
            }

            &.sl-text_minor {
                color: rgba(var(--bazis-color-contrast-rgb), 0.7);
            }
        }

        button {
            padding: 0;
            margin-left: var(--bazis-margin-2x);
        }
    }

    .sl-dispatcher-page .sl-filter-applied-wrap & {
        margin-bottom: var(--bazis-margin-2x);
    }
}
