.sl-trip-tape {
    .bazis-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--bazis-margin-3x);

        .bazis-status {
            --margin-bottom: var(--bazis-margin-1x);
        }

        > * {
            &:first-child {
                max-width: 180px;
                width: 100%;
            }
        }
    }

    table {
        --margin-bottom: 0;

        & + .bazis-item {
            --border-width: 0;
        }
    }

    .bazis-dl_inline {
        margin-right: var(--bazis-margin-4x);
    }

    &__price {
        position: relative;
        padding-right: var(--bazis-padding-4x);
        cursor: pointer;

        bazis-icon {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -4px;
            font-size: 8px;
        }
    }

    &__segment {
        // TODO: переписать имплементацию, вынесено из блока
        @include props-block-model;
        @include props-margin;
        @include props-width;
        @include props-colors;

        --background-hover: var(--background);
        --border-color-hover: var(--border-color);
        --box-shadow-hover: var(--box-shadow);

        @include block-model;
        @include margin;
        @include width;
        @include colors;

        --padding-top: var(--bazis-padding-3x);
        --padding-start: var(--bazis-padding-3x);
        --padding-end: var(--bazis-padding-3x);
        --padding-bottom: var(--bazis-padding-3x);
        --margin-bottom: 0;
        --border-width: 1px;
        --border-style: solid;
        --border-color: transparent;

        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            --border-color: var(--bazis-background-dark);
        }

        > bazis-button {
            margin-left: var(--bazis-margin-2x);
        }

        bazis-trip-line {
            --width: 301px;

            @media (min-width: 1024.98px) and (max-width: 1140px) {
                --width: 215px;
            }
        }

        &.sl-trip-tape__segment--active {
            --background: var(--bazis-background-dark);
        }
    }

    &.bazis-block {
        .bazis-map-wrapper {
            --height: 400px;
            --margin-top: var(--bazis-margin);
        }
    }

    @media screen and (min-width: 1025px) and (max-width: 1130px) {
        .bazis-item > *:first-child {
            max-width: 160px;
        }

        .bazis-h6.bh-text-right {
            text-align: left !important;
        }
    }
}
