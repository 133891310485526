bazis-toolbar {
    & + .bazis-list_short-info {
        --margin-bottom-list: calc(var(--bazis-margin-4x) + 2px);
    }
}

.bazis-toolbar_page-headline {
    margin-bottom: var(--bazis-margin-8x);

    &::part(end) {
        align-self: center;
    }
}
