.bazis-contacts-control {
    &__group {
        app-edit-wasteplace &,
        app-edit-facility &,
        app-draft-sale-lot & {
            --padding-top: var(--bazis-margin-8x);
            --padding-start: var(--bazis-margin-8x);
            --padding-end: var(--bazis-margin-8x);
            --padding-bottom: var(--bazis-margin-8x);
            --margin-bottom: var(--bazis-margin-6x);
            --background: var(--bazis-background-accent);
        }

        app-edit-wasteplace &,
        app-edit-facility & {
            bazis-row:last-child .bazis-control {
                --margin-bottom: 0;
            }
        }

        app-edit-wasteplace &,
        app-edit-facility &,
        app-draft-sale-lot & {
            &:last-of-type {
                margin-bottom: var(--bazis-margin-6x);
            }
        }
    }

    @media (max-width: 768px) {
        app-edit-wasteplace &,
        app-edit-facility & {
            .bazis-grid_form bazis-row:last-child {
                .bazis-control {
                    --margin-bottom: var(--bazis-margin-6x);
                }

                bazis-col:last-child .bazis-control {
                    --margin-bottom: 0;
                }
            }
        }
    }
}
