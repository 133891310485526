.bazis-chart-column {
    display: flex;
    flex-wrap: nowrap;
    height: 328px;
    justify-content: space-between;
    border: 1px solid var(--bazis-border-color);
    border-top: none;
    background: linear-gradient(var(--bazis-border-color) 1px, transparent 0);
    background-size: 1px 82px;
    padding: 0 var(--bazis-padding-5x);
    margin-bottom: var(--bazis-margin-5x);

    &__item {
        position: relative;
        bottom: -20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        width: 24px;
        // margin: 0 var(--bazis-margin-1x);

        &__value {
            padding-bottom: var(--bazis-padding-2x);
            font-size: 11px;
            line-height: 12px;
            text-align: center;
        }
        &__bar {
            background: var(--bazis-border-color);
        }
        &__order {
            padding-top: var(--bazis-padding-2x);
            font-size: 11px;
            line-height: 12px;
            text-align: center;
            color: var(--bazis-text-color-secondary);
        }

        &--current {
            .bazis-chart-column__item__order {
                color: var(--bazis-text-color);
            }
        }
        &--selected {
            .bazis-chart-column__item__order {
                color: var(--bazis-text-color);
            }
            .bazis-chart-column__item__bar {
                background: var(--bazis-color-action);
            }
        }
    }
}
