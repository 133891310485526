.sl-apps-page {
    .sl-section {
        &_banner {
            border-top: 1px solid rgba(var(--bazis-color-white-rgb), 0.1);
            background: url('/assets/images/apps-page-banner.png') 50% 100% no-repeat, linear-gradient(0deg, #062443 0%, #07151A 100%);
            background-size: cover;
            color: var(--bazis-text-color-contrast);

            .sl-section__content {
                --padding-top: 88px;
                --padding-bottom: 96px;
                text-align: center;
            }

            h1 {
                --bazis-h1-margin: 0 auto var(--bazis-margin-4x);
                max-width: 704px;
            }

            p {
                --bazis-font-size-p: 18px;
                --bazis-line-height-p: 28px;
                --bazis-p-margin: 0 0 40px;
                color: rgba(var(--bazis-text-color-contrast-rgb), 0.7);
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                letter-spacing: 0.5px;
                color: var(--bazis-text-color-contrast);
            }

            .sl-qr-code {
                width: 230px;
                height: 230px;
                background: var(--bazis-color-white);
                border-radius: calc(var(--bazis-border-radius) * 2);
                padding: var(--bazis-padding-4x);
                margin: 0 auto 40px;
            }

            bazis-button {
                --height: 52px;
                --padding-start: 40px;
                --padding-end: 40px;
                --padding-top: var(--bazis-padding-3x);
                --padding-bottom: var(--bazis-padding-3x);
                font-size: 16px;
            }

            @media (max-width: 1024px) {
                h1 {
                    --bazis-font-size-h1: 45px;
                    --bazis-line-height-h1: 52px;
                }
            }
            @media (max-width: 576px) {
                h1 {
                    --bazis-font-size-h1: 28px;
                    --bazis-line-height-h1: 36px;
                }

                bazis-button {
                    display: block;
                }
            }
        }
        &_how {
            .sl-section__content {
                --padding-top: 72px;
            }

            .bazis-slider {
                &__content {
                    gap: var(--bazis-margin-8x);
                }
                &__controls,
                &__indicator {
                    display: none;
                }
            }

            .sl-slider__card {
                width: calc((100% - var(--bazis-margin-8x) * 2) / 3);
                box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);

                .bazis-block {
                    --padding-top: var(--bazis-padding-5x);
                    --padding-bottom: var(--bazis-padding-5x);
                    --padding-start: var(--bazis-padding-6x);
                    --padding-end: var(--bazis-padding-6x);
                    --margin-bottom: 0;
                    --border-radius: 12px;
                    --background: rgba(var(--bazis-color-white-rgb), 0.8);
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    right: 20px;

                    h6 {
                        --bazis-font-size-h6: 14px;
                        --bazis-line-height-h6: 24px;
                        --bazis-h6-margin: 0 0 8px;
                        display: flex;
                        align-items: center;
                        font-weight: 500;

                        img {
                            width: 24px;
                            margin-right: var(--bazis-margin-2x);
                        }
                    }

                    .bazis-p_minor {
                        color: rgba(var(--bazis-text-color-rgb), 0.6);
                    }
                }

                figure {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    margin: auto;
                    padding: 0  56px;

                    img {
                        max-height: 100%;
                        margin: auto;
                        padding-top: var(--bazis-padding-5x);
                    }
                }

                &__figure {
                    position: relative;
                    background: url('/assets/images/apps-frame-bg.svg') 50% 20px no-repeat, linear-gradient(180deg, #07151a, #062443);
                    background-size: cover;
                    border-radius: 12px 12px 0 0;
                    height: 280px;
                }

                &__btns {
                    display: flex;
                    justify-content: space-between;
                    margin-top: var(--bazis-margin-4x);
                    font-size: 14px;
                    line-height: 20px;

                    span + span {
                        font-weight: 500;
                        text-align: right;
                    }
                }

                &__description {
                    height: calc(100% - 280px);
                    padding: var(--bazis-padding-6x) var(--bazis-padding-5x);
                    background: var(--bazis-color-white);
                    border-radius: 0 0 12px 12px;

                    p {
                        --bazis-line-height-p-major: 24px;
                    }

                    strong {
                        font-weight: 500;
                    }
                }
            }

            @media (max-width: 1024px) {
                .bazis-slider {
                    &__indicator {
                        display: flex;
                    }
                    &__content {
                        gap: var(--bazis-margin-4x);
                    }

                    .sl-slider__card {
                        margin-bottom: var(--bazis-margin-6x);
                        width: calc((100% - var(--bazis-margin-4x)) / 2);
                    }
                }
            }

            @media (max-width: 576px) {
                .bazis-slider {
                    .sl-slider__card {
                        width: 100%;
                    }
                }
            }
        }
        &_faq {
            .sl-section__content {
                --padding-top: 72px;
                --padding-bottom: 120px;
            }
        }
    }
}
