app-lot-item-order,
app-lot-item-contract {
    .sl-btn_hidden {
        visibility: hidden;
    }

    &:hover {
        .sl-btn_hidden {
            visibility: visible;
        }
    }
}
