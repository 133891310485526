/* Base Components of alphabet */
@import 'balance';
@import 'card-mobile';
@import 'contacts-group';
@import 'lang';
@import 'referral-block';
@import 'scheduler';
@import 'section-sign';
@import 'search';
@import 'tabs.cmp';
@import 'tools';
@import 'other';