.sl-empty {
    &_notifications {
        --margin-top: 0;
        --padding-top: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-6x);
        --background: var(--bazis-background-medium);
        --font-size: 12px;
        --line-height: 16px;
    }

    &_primary {
        --color: var(--bazis-text-color);
    }
}
