.bazis-menu {
    --multiple: 4;
    --margin-bottom: var(--bazis-margin-2x);
    --padding-top: calc(var(--bazis-padding-1x) * var(--multiple));
    --padding-bottom: calc(var(--bazis-padding-1x) * var(--multiple));
    --padding-start: calc(var(--bazis-padding-1x) * var(--multiple));
    --padding-end: calc(var(--bazis-padding-1x) * var(--multiple));
    --border-radius: 0;
    --background-hover: var(--bazis-background-accent);
    --color-hover: var(--color);
    --list-style: none;
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 20px;

    bazis-icon {
        flex-shrink: 0;
        margin-right: var(--bazis-margin-4x);
    }

    bazis-button {
        --color: var(--bazis-text-color);
        color: var(--color);

        &:not(.bazis-item) {
            --padding-start: calc(var(--bazis-padding-1x) * var(--multiple));
            --padding-end: calc(var(--bazis-padding-1x) * var(--multiple));
            --padding-top: calc(var(--bazis-padding-1x) * var(--multiple));
            --padding-bottom: calc(var(--bazis-padding-1x) * var(--multiple));
        }
    }

    // subtype
    &_rounded {
        --multiple: 2;
        --border-radius: var(--bazis-border-radius);
        --margin-bottom-list: var(--bazis-margin-8x);

        .bazis-item {
            --font-size: 14px;
            --font-weight: 500;
            display: flex;
            align-items: center;
            text-align: left;

            &:hover,
            &.active {
                bazis-icon {
                    background: transparent;
                }
            }

            .bazis-text_minor {
                font-weight: 400;
            }
        }

        bazis-icon {
            padding: 10px;
            background: var(--bazis-background-medium);
            margin-right: var(--bazis-margin-3x);
        }
    }
}
