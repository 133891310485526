* {
    scrollbar-color: var(--bazis-background-dark) var(--bazis-border-color-dark);
    scrollbar-width: thin;
}
@supports selector(::-webkit-scrollbar) {
    * {
        scrollbar-color: unset;
        scrollbar-width: unset;
    }
    *::-webkit-scrollbar,
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        -webkit-overflow-scrolling: auto;
        width: 4px;
        height: 4px;
    }
    *::-webkit-scrollbar-track,
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border-radius: var(--bazis-border-radius);
        background: var(--bazis-background-dark);
    }
    *::-webkit-scrollbar-track:horizontal,
    ::-webkit-scrollbar-track:horizontal {
        -webkit-box-shadow: none;
    }
    *::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
        border-radius: var(--bazis-border-radius);
        background: var(--bazis-border-color-dark);
    }
}
