.bazis-interval-control {
    --background: var(--bazis-color-white);
    --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px ;
    --border-style: solid;
    --border-color: var(--bazis-background-medium);

    bazis-timepicker,
    bazis-datepicker {
        --box-shadow: none;
        --border-width: 0;
    }

    bazis-timeinterval & {
        --width: 418px;
        --padding-top: 26px;

        bazis-timepicker {
            --padding-top: 0;
        }

        p {
            --bazis-p-margin: 0 var(--bazis-margin-4x) var(--bazis-margin-2x);
        }
    }

    // Устаревший компонент ?
    bazis-dateinterval & {
        --width: 792px;
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-3x);
        --padding-top: var(--bazis-padding-2x);
    }
}
