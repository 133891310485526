.bazis-vehicle-number {
    /**
    *
    **/
    @include props-block-model;
    @include props-width;
    @include props-height;
    @include props-colors;

    @include block-model;
    @include width;
    @include height;
    @include colors;

    display: inline-flex;
    align-items: flex-end;
    font-family: 'RoadNumbers';
    width: auto;

    .bazis-vehicle-number__part {
        @include props-margin;
        @include props-font;

        @include margin;
        @include font;
    }
}
