.bazis-pane {
    --border-radius: 4px 4px 0px 0px;
    --background: var(--bazis-background);

    --height-header: 36px;

    &__header {
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-1x);
        --border-width: 0 0 1px;
        --border-color: var(--bazis-border-color);
        --border-style: solid;
        --border-radius: 4px 4px 0px 0px;

        bazis-tabs {
            --font-size: 13px;
            margin-left: var(--bazis-margin-5x);
        }

        &__header__btns {
            background: linear-gradient(
                270deg,
                var(--bazis-color-white) 75%,
                rgba(var(--bazis-color-white-rgb), 0.8) 94.14%
            );
            padding-right: var(--padding-end);
            padding-left: var(--padding-end);
            border-radius: 0 4px 0 0;
        }
    }

    &--expanded {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --border-color-hover: var(--bazis-border-color-dark);
        --box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);

        position: absolute;
        z-index: 152;
    }
}
