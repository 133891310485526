.bazis-dateplate {
    .bazis-block__content_main__left & {
        .bazis-text-action {
            letter-spacing: 0;
            color: var(--bazis-text-color);
        }
    }

    h5 &,
    h6 & {
        display: inline-block;
        padding-left: var(--bazis-padding-3x);
        font-weight: 400;
        vertical-align: middle;
    }
}
