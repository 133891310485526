.bazis-interval-control {
    --background: initial;
    --box-shadow: initial;

    @include props-border;
    @include border;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    background: var(--background);
    box-shadow: var(--box-shadow);
}
