.bazis-error {
    /*
    * @prop --error-font-size: размер шрифта ошибки
    * @prop --error-line-height: интерльяж ошибки
    * @prop --error-margin: отступ ошибки
    * @prop --error-color: цвет ошибки
    */

    --error-font-size: 11px;
    --error-line-height: 12px;
    --error-margin: var(--bazis-margin-2x);
    --error-color: var(--bazis-color-danger);

    font-size: var(--error-font-size);
    font-weight: 400;
    line-height: var(--error-line-height);
    color: var(--error-color);

    p {
        --bazis-font-size-p: var(--error-font-size);
        --bazis-line-height-p: var(--error-line-height);
        --bazis-p-margin: var(--error-margin) 0 0;
    }

    bazis-link {
        --font-size: var(--error-font-size);
        --line-height: var(--error-line-height);
    }

    .bazis-color {
        color: var(--bazis-color-base);
    }
}
