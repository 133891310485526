.bazis-block {
    &__footer {
        .bazis-block_accent & {
            > .bh-flex {
                app-bid-detail & {
                    margin-left: 56px;
                }
            }
        }
    }

    .sl-block-total {
        float: right;
        border-top: 1px solid var(--bazis-border-color);
        padding-top: var(--bazis-padding-2x);
    }

    &_outline {
        .sl-page_detail main & {
            --margin-bottom: 40px;
        }
    }

    &_accent {
        .bazis-block & {
            .sl-table_clear td {
                --padding-top: 2px;
                --padding-bottom: 2px;
            }
        }

        .sl-profile-page & {
            --padding-top: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-6x);
            --padding-end: var(--bazis-padding-6x);
            --margin-top: var(--bazis-margin-4x);
            --margin-bottom: var(--bazis-margin-4x);
        }

        &.sl-block_large {
            --margin-bottom: 40px;
        }

        app-legal-document & {
            --margin-top: var(--bazis-margin-3x);
            --padding-top: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);

            a {
                font-weight: 500;
                letter-spacing: 0.25px;
                text-decoration: none;
            }
        }
    }

    &_warning {
        @media (max-width: 576px) {
            app-wasteplace-widget &,
            app-facility-widget & {
                --margin-bottom: 0;
                --margin-top: var(--bazis-margin-5x);
            }
        }
    }

    &_on-map {
        .sl-close {
            position: absolute;
            right: 16px;
            top: 16px;
        }
    }

    app-waste-position &,
    app-waste-position-skeleton & {
        --padding-top: var(--bazis-padding-8x);
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: var(--bazis-padding-8x);
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--bazis-border-color);

        .bazis-block__content_end {
            --width: 432px;
            --padding-start: 84px;
            --margin-start: var(--bazis-margin-8x);
        }
    }

    aside & {
        &.sl-block_small {
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
        }
    }

    aside.sl-aside_left & {
        // 20 16 20 16 боковые
        --padding-top: var(--bazis-padding-4x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-4x);

        @media (max-width: 992px) {
            &,
            &_outline {
                --padding-start: 0;
                --padding-end: 0;
                --padding-top: 0;
                --padding-bottom: 0;
                --border-width: 0;

                bazis-button {
                    display: inline-block;
                }
            }
        }

        @media (max-width: 576px) {
            &,
            &_outline {
                bazis-button {
                    display: block;
                }
            }
        }
    }
}

.sl-block {
    &_mini {
        --padding-top: var(--bazis-padding-2x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-2x);
    }

    &_medium {
        --padding-top: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
    }

    &_propositions {
        position: relative;
        margin-top: 40px;
    }

    &_faq {
        --padding-top: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-8x);
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --border-color-hover: var(--bazis-border-color-dark);

        &:last-child {
            --margin-bottom: 0;
        }
    }
    &_long-card {
        --padding-top: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --margin-bottom: var(--bazis-margin-5x);
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --border-color-hover: var(--bazis-border-color-dark);
        --box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
        --box-shadow-hover: 0px 10px 20px -10px rgba(144, 150, 162, 0.2);

        .bazis-block__title {
            align-items: center;
            margin-bottom: var(--bazis-margin-2x);
        }

        @media (max-width: 1024px) {
            --padding-top: var(--bazis-padding-3x);
            --padding-bottom: var(--bazis-padding-3x);
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);

            .bazis-block__title {
                --gap: 10px;
                align-items: flex-start;
                flex-direction: column;
            }

            > .bh-flex {
                flex-wrap: wrap;
            }

            .bazis-block__content_main > .bh-flex {
                flex-direction: column;
                align-items: flex-start !important;
                gap: var(--bazis-margin-2x);
            }

            .bazis-block__content_end {
                --width: 100%;
                --border-width: 1px 0 0;
                --margin-start: 0;
                --padding-start: 0;
                --margin-top: var(--bazis-margin-2x);
                --padding-top: var(--bazis-padding-4x);
            }
        }
    }
}
