.bazis-collapsible-to-more {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    // gap: var(--bazis-margin-8x);
    width: 100%;
    // flex-wrap: wrap; - нельзя, при разных разрешениях, может вместо сворачивания табов, просто перенсти на строчку ниже

    // overflow: hidden; - нельзя, так как есть выпадающее меню

    padding-top: var(--bazis-padding-8x);
    padding-bottom: var(--bazis-padding-5x);

    &__start {
        // width: 100%; - нельзя, иначе не будет верно отрабатывать подсчет доступного места и сворачивания в пункт еще.
    }
    &__end {
        flex-shrink: 0;
    }

    &__shake {
        position: absolute;
        z-index: 1;
        top: var(--bazis-padding-8x);
        bottom: var(--bazis-padding-5x);
        left: 0;
        right: 0;
        background: var(--bazis-color-white);

        bazis-skeleton {
            height: 100%;
        }
    }

    &--opacity {
        .bazis-collapsible-to-more__start,
        .bazis-collapsible-to-more__end {
            opacity: 0;
        }
    }

    &--notabs {
        .bazis-collapsible-to-more__start {
            flex: 1 1 auto;
        }
    }
}
