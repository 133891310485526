bazis-step {
    --color-muted: var(--bazis-text-color-secondary);

    --sign-margin: var(--bazis-margin-4x);

    --sign-background: var(--bazis-color-success);
    --sign-background-muted: var(--bazis-background-medium);

    --sign-border-radius: 50%;
    --sign-border-style: solid;
    --sign-border-width: 1px;

    --sign-color: var(--bazis-color-white);
    --sign-color-muted: var(--bazis-text-color-secondary);
    --sign-size: 20px;
    --sign-icon-size: 12px;
    --sign-font-size: 11px;

    .bazis-step__label_another {
        --font-weight: 500;
    }

    [slot=label] {
        border-bottom: 1px dashed transparent;
    }

    &:hover {
        [slot=label] {
            border-bottom-color: var(--color);
        }
    }
}
