.bazis-list-page {
    &__head {
        app-referral-partners & {
            background: var(--bazis-background);
        }
    }

    @media (max-width: 992px) {
        app-lot-list & {
            --background-head: transparent;
            --background-filters: transparent;
            --background-body: var(--bazis-background-medium);

            .bazis-list-page__body {
                .bazis-block_shadow {
                    --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1),
                        inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
                    --border-radius: 8px;

                    --bazis-corner-right: 14px;
                    --bazis-corner-top: 14px;

                    hr {
                        --margin-top: var(--bazis-margin-5x);
                        --margin-bottom: var(--bazis-margin-5x);
                        --border-style: dashed;
                    }

                    .sl-accordion_info:last-child {
                        --margin-start: calc(var(--bazis-margin-6x) * -1);
                        --margin-end: calc(var(--bazis-margin-6x) * -1);
                        --margin-bottom: calc(var(--bazis-margin-6x) * -1);
                        --bazis-border-radius: 8px;

                        --padding-top-header: var(--bazis-padding-5x);
                        --padding-bottom-header: var(--bazis-padding-5x);

                        --padding-bottom-body: var(--bazis-padding-5x);

                        &:not(.bazis-accordion--opened) {
                            --border-radius-header: 0 0 var(--bazis-border-radius);
                        }

                        &.bazis-accordion--opened {
                            --border-radius-header: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        .bazis-list-page__body {
            .bazis-block_shadow {
                .sl-accordion_info {
                    table,
                    tbody,
                    tr,
                    td {
                        display: block;
                    }

                    td.bh-text-right {
                        --padding-start: 0;
                        text-align: left !important;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                    }

                    tr:not(:last-child) {
                        margin-bottom: var(--bazis-margin-6x);
                    }
                }
            }
        }
    }
}
