aside {
    &.bh-sticky {
        overflow-y: auto;
        max-height: calc(100vh - 150px);
        margin-right: calc(var(--bazis-margin-2x) * -1);
        padding-right: var(--bazis-padding-2x);
    }

    app-summary &,
    app-sale-lot-summary & {
        margin-top: calc(var(--bazis-line-height-h3) + var(--bazis-margin-4x));
    }
}
