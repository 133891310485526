bazis-range {
    --bar-height: 7px;
    --thumb-background: var(--bazis-color-white, #ffffff);
    // --thumb-background-disabled: var(--thumb-background);
    --thumb-width: 20px;
    --thumb-height: 20px;
    --thumb-border-radius: 50%;
    --thumb-border-width: 0;
    --thumb-border-style: solid;
    --thumb-border-color-disabled: var(--thumb-border-color);
    --thumb-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);

    --track-background: var(--bazis-placeholder-color, #BCC1CC);
    // --track-background-disabled: var(--track-background);
    // --track-width: initial;
    --track-height: var(--bar-height);
    --track-border-radius: 2px;
    --track-border-width: 0;
    --track-border-style: solid;
    --track-border-color-disabled: var(--track-border-color);
    --track-box-shadow: none;

    --progress-background: var(--bazis-color-primary, #00A2AD);
    --progress-background-disabled: rgba(var(--bazis-color-primary-rgb), 0.7);

    --font-size: 14px;
    --font-weight: normal;
    --line-height: 20px;
}
