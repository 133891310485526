app-carrier-tasks-group {
    &:last-child {
        > hr {
            display: none;
        }
    }

    @media screen and (min-width: 1025px) and (max-width: 1180px) {
        .bazis-block__content_main__left {
            width: 180px;
            margin-right: var(--bazis-margin-5x);
        }

        .sl-percentages_column {
            width: 100%;
        }

    }
}

@media screen and (min-width: 1025px) and (max-width: 1180px) {
    app-carrier-tasks-widget .bazis-block_outline {
        --padding-top: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);
    }
}