.bazis-display-error {
    &_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 var(--bazis-margin-4x);
        padding: var(--bazis-padding-2x);
        background: var(--bazis-background-dark);
        border-radius: var(--bazis-border-radius);
        font-size: 13px;
        line-height: 20px;

        p {
            margin: 0;
        }
    }
}
