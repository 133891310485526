.bazis-control.bazis-range-control {
  --padding-field-start: 0;
  --padding-field-end: 0;
  --background: transparent;
  --background-active: transparent;
  --border-width: 0;

  .bazis-control__field-group {
    margin-top: var(--bazis-margin-2x);
  }

  bazis-range {
    display: block;
    width: 100%;
  }
}
