.bazis-counter {
    &__total {
        @include props-font();
        @include font();

        --margin-side: initial;
    }

    &__item {
        --height: initial;
        --width: initial;
        --border-radius: initial;
        border-radius: var(--border-radius);
        height: var(--height);
        width: var(--width);
        background: var(--bazis-color-base, var(--bazis-placeholder-color));
    }

    // type
    &_line {
        display: flex;
        align-items: center;

        .bazis-counter__item {
        }

        .bazis-counter__total {
            &:first-child {
                margin-right: var(--margin-side);
            }
            &:last-child {
                margin-left: var(--margin-side);
            }
        }
    }
}
