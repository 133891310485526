.bazis-coordinates {
    bazis-grid.sl-grid_table & {
        margin-bottom: 0;
    }

    .sl-dispatcher-panel__card dd & {
        margin-top: -10px;
        color: var(--bazis-text-color);
    }
}
