.bazis-breadcrumbs {
    --padding-top: initial;
    --padding-bottom: initial;
    --margin-bottom: initial;
    --text-decoration: initial;
    --color: initial;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin-bottom: var(--margin-bottom);
    color: var(--color);

    a {
        text-decoration: var(--text-decoration);
        color: var(--color);
    }

    .bazis-container & {
        padding-left: 0;
        padding-right: 0;
    }
}
