// implemented
.bazis-map-wrapper {
    &.sl-map-wrapper_small {
        --height: 260px;
        --margin-bottom: var(--bazis-margin-8x);
    }

    app-route-widget & {
        --height: 400px;
    }

    app-tracker &,
    app-list-telemetry-map & {
        --height: 700px;
    }
}

.sl-map-wrapper_dd {
    @extend .bazis-map-wrapper;
    --height: 100px;
    --width: 332px;
    --margin-top: var(--bazis-margin-2x);
    border: 1px solid var(--bazis-border-color);
    border-radius: var(--bazis-border-radius);

    @media (max-width: 1180px) {
        --height: 106px;
    }
}

@media (max-width: 576px) {
    app-route-widget .bazis-map-wrapper {
        --height: 184px;
        border: 1px solid var(--bazis-border-color);
        border-radius: 8px;

        .bazis-map {
            border-radius: 8px;
        }

        .sl-open-btn,
        .sl-mobile-content {
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 40;
        }

        .sl-mobile-content {
            right: 8px;
        }

        .sl-mobile-content:not(.bazis-modal .sl-mobile-content) {
            display: none;
        }
    }
}
