.bazis-breadcrumbs {
    --padding-top: var(--bazis-padding-4x);
    --padding-bottom: var(--bazis-padding-4x);
    --margin-bottom: var(--bazis-margin-4x);
    --text-decoration: none;
    --color: var(--bazis-text-color-secondary);

    .bazis-list {
        --font-size: 12px;
        --line-height: 16px;

        > .bazis-item {
            --margin-end: var(--bazis-margin-2x);
        }
    }

    a:hover {
        color: var(--color);
    }

    bazis-icon {
        --bazis-icon-s-size: 12px;
        margin-right: var(--margin-end);
    }
}
