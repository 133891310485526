.leaflet-oldie {
    // popup
    .leaflet-popup-content-wrapper {
        zoom: 1;
    }

    .leaflet-popup-tip {
        width: 24px;
        margin: 0 auto;

        -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
        filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
    }

    .leaflet-popup-tip-container {
        margin-top: -1px;
    }

    .leaflet-control-layers,
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
        border: 1px solid #999;
    }
}
