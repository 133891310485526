.bazis-page {

    @media (min-width: 1024.98px) {
        &-header {
            bazis-toolbar {  // sl-toolbar_page-header in agro and sewage
                --margin-bottom: var(--bazis-margin-3x);

                h1 {
                    margin: 0;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        &-header {
            bazis-toolbar + .bazis-list_short-info {
                --margin-top-list: 0;
            }
        }
    }
}