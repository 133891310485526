.bazis-contact {
    @include props-margin;
    @include props-font;
    @include margin;
    @include font;

    display: block;
    color: inherit;

    a {
        text-decoration: none;
    }

    &__name {}
    &__position {}
    &__mail {}
    &__phone {}
}
