.sl-percentages {
    display: flex;
    justify-content: space-between;

    &__item {
        position: relative;
        width: 100%;
        padding-left: 14px;
        margin-left: 14px;

        &--hidden {
            display: none;
        }

        &:first-child,
        &--hidden:first-child + & {
            padding-left: 0;
            margin-left: 0;
        }

        .bazis-percentage-bar {
            --height: 4px;
            margin-top: var(--bazis-margin-2x);
        }

        &:hover {
            .bazis-tooltip_static {
                display: block;
                top: 38px;
            }
        }
    }

    &_column {
        width: 144px;

        .sl-percentages__item {
            padding: 0;
            margin-left: 0;
        }
    }
}
