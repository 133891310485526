.bazis-vehicle-number-control {
    &_ae {
        --height: 24px;
        --width-group: 195px;

        .bazis-vehicle-number-control__part {
            &_0 {
                --margin-start: var(--bazis-margin-3x);
                --margin-end: var(--bazis-margin-1x);
                --margin-bottom: 2px;
                --font-size: 26px;
                --line-height: 16px;
                --height: 32px;
                width: 48px;
                margin-left: var(--bazis-margin-3x);

                input {
                    letter-spacing: 4px;
                }
            }
            &_1 {
                --margin-end: 0;
                --margin-bottom: 2px;
                --font-size: 26px;
                --height: 32px;
                --line-height: 16px;
                width: 112px;
                margin-left: var(--bazis-margin-2x);

                input {
                    letter-spacing: 4px;
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                    --margin-bottom: -1px;
                }
            }

            @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
                &_0 {
                    --line-height: 12px;
                }
                &_1 {
                    --line-height: 12px;
                }
            }
        }
    }
}
