.bazis-card {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    &:hover {
        background: var(--background-hover);
        border-color: var(--border-color-hover);
        box-shadow: var(--box-shadow-hover);
    }

    &__header {}
    &__body {}
    &__date {}
    &__title {}
}
