@import "color";

body {
    background: var(--bazis-background);
}

body.bazis-backdrop-no-scroll {
    overflow: hidden;
}

@supports ( padding-top: constant(safe-area-inset-top) ){
    html {
        --bazis-safe-area-top: constant(safe-area-inset-top);
        --bazis-safe-area-bottom: constant(safe-area-inset-bottom);
        --bazis-safe-area-left: constant(safe-area-inset-left);
        --bazis-safe-area-right: constant(safe-area-inset-right);
    }
}
@supports ( padding-top: env(safe-area-inset-top) ){
    html {
        --bazis-safe-area-top: env(safe-area-inset-top);
        --bazis-safe-area-bottom: env(safe-area-inset-bottom);
        --bazis-safe-area-left: env(safe-area-inset-left);
        --bazis-safe-area-right: env(safe-area-inset-right);
    }
}

input::-webkit-date-and-time-value {
    text-align: start;
}
