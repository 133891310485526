bazis-totop {
    --bottom: 84px;
    --margin-end: 40px;

    &::part(button) {
        --height-large: 56px;
        --border-radius: 50%;
        width: 56px;
        margin-top: calc(var(--bazis-margin-7x) * -1);
    }

    &::part(icon) {
        margin-right: calc(var(--bazis-margin-1x) * -1);
        margin-left: calc(var(--bazis-margin-1x) * -1);
        font-size: 24px;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        &::part(icon) {
            margin-left: unset;
            margin-right: unset;
            margin-inline-start: calc(var(--bazis-margin-1x) * -1);
            margin-inline-end: calc(var(--bazis-margin-1x) * -1);
        }
    }
}

@media (max-width: 1024px) {
    bazis-totop {
        --bottom: 44px;
        --margin-end: var(--bazis-margin-5x);

        &::part(button) {
            --height-large: 44px;
            width: 44px;
            margin-top: -22px;
        }
    }
}
