.bh-no-padding {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

@for $i from 1 through 8 {
    .bh-padding-#{$i}x {
        --bazis-padding: var(--bazis-padding-#{$i}x);

        --padding-start: var(--bazis-padding, 12px);
        --padding-end: var(--bazis-padding, 12px);
        --padding-top: var(--bazis-padding, 12px);
        --padding-bottom: var(--bazis-padding, 12px);
        padding-left: var(--bazis-padding, 12px) !important;
        padding-right: var(--bazis-padding, 12px) !important;
        padding-top: var(--bazis-padding, 12px) !important;
        padding-bottom: var(--bazis-padding, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-padding-#{$i}x {
            padding-left: unset;
            padding-right: unset;
            -webkit-padding-start: var(--bazis-padding, 12px) !important;
            padding-inline-start: var(--bazis-padding, 12px) !important;
            -webkit-padding-end: var(--bazis-padding, 12px) !important;
            padding-inline-end: var(--bazis-padding, 12px) !important;
        }
    }

    .bh-padding-top-#{$i}x {
        --bazis-padding-top: var(--bazis-padding-#{$i}x);
        --padding-top: var(--bazis-padding-top, 12px);
        padding-top: var(--bazis-padding-top, 12px) !important;
    }
    .bh-padding-start-#{$i}x {
        --bazis-padding-start: var(--bazis-padding-#{$i}x);
        --padding-start: var(--bazis-padding-start, 12px);
        padding-left: var(--bazis-padding-start, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-padding-start-#{$i}x {
            padding-left: unset;
            -webkit-padding-start: var(--bazis-padding-start, 12px) !important;
            padding-inline-start: var(--bazis-padding-start, 12px) !important;
        }
    }

    .bh-padding-end-#{$i}x {
        --bazis-padding-end: var(--bazis-padding-#{$i}x);
        --padding-end: var(--bazis-padding-end, 12px);
        padding-right: var(--bazis-padding-end, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-padding-end-#{$i}x {
            padding-right: unset;
            -webkit-padding-end: var(--bazis-padding-end, 12px) !important;
            padding-inline-end: var(--bazis-padding-end, 12px) !important;
        }
    }

    .bh-padding-bottom-#{$i}x {
        --bazis-padding-bottom: var(--bazis-padding-#{$i}x);
        --padding-bottom: var(--bazis-padding-bottom, 12px);
        padding-bottom: var(--bazis-padding-bottom, 12px) !important;
    }
    .bh-padding-vertical-#{$i}x {
        --bazis-padding-vertical: var(--bazis-padding-#{$i}x);
        --padding-top: var(--bazis-padding-vertical, 12px);
        --padding-bottom: var(--bazis-padding-vertical, 12px);
        padding-top: var(--bazis-padding-vertical, 12px) !important;
        padding-bottom: var(--bazis-padding-vertical, 12px) !important;
    }
    .bh-padding-horizontal-#{$i}x {
        --bazis-padding-horizontal: var(--bazis-padding-#{$i}x);
        --padding-start: var(--bazis-padding-horizontal, 12px);
        --padding-end: var(--bazis-padding-horizontal, 12px);
        padding-left: var(--bazis-padding-horizontal, 12px) !important;
        padding-right: var(--bazis-padding-horizontal, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-padding-horizontal-#{$i}x {
            padding-left: unset;
            padding-right: unset;
            -webkit-padding-start: var(--bazis-padding-horizontal, 12px) !important;
            padding-inline-start: var(--bazis-padding-horizontal, 12px) !important;
            -webkit-padding-end: var(--bazis-padding-horizontal, 12px) !important;
            padding-inline-end: var(--bazis-padding-horizontal, 12px) !important;
        }
    }
}

@media screen and (min-width: 576.98px) {
    .bh-no-padding-sm-up {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (max-width: 576px) {
    .bh-no-padding-sm-down {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 768.98px) {
    .bh-no-padding-md-up {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (max-width: 768px) {
    .bh-no-padding-md-down {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 992.98px) {
    .bh-no-padding-lg-up {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (max-width: 992px) {
    .bh-no-padding-lg-down {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 1024.98px) {
    .bh-no-padding-xl-up {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (max-width: 1024px) {
    .bh-no-padding-xl-down {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 1280.98px) {
    .bh-no-padding-xxl-up {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (max-width: 1280px) {
    .bh-no-padding-xxl-down {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 768px) {
    .bh-no-padding-sm-md {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 992px) {
    .bh-no-padding-sm-lg {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 1024px) {
    .bh-no-padding-sm-xl {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 1280px) {
    .bh-no-padding-sm-xxl {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 992px) {
    .bh-no-padding-md-lg {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 1024px) {
    .bh-no-padding-md-xl {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 1280px) {
    .bh-no-padding-md-xxl {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 992.98px) and (max-width: 1024px) {
    .bh-no-padding-lg-xl {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 992.98px) and (max-width: 1280px) {
    .bh-no-padding-lg-xxl {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 1024.98px) and (max-width: 1280px) {
    .bh-no-padding-xl-xxl {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
