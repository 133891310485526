// TODO: Подумать над переобразование в компонент, всех вариаций лоадера
.bazis-loader {
    /**
     * @prop --background: фон лоадера
     * @prop --border-radius: радиус скругления лоадера
     * @prop --border-width: толщина границы лоадера
     * @prop --border-style: стиль границы лоадера
     * @prop --border-color: цвет границы лоадера
     * @prop --box-shadow: тень лоадера
     *
     * @prop --max-width: максимальная ширина лоадера
     * @prop --min-width: минимальная ширина лоадера
     * @prop --width: ширина лоадера
     * @prop --height: высота лоадера
     *
     * @prop --spinner-background: фон спиннера
     * @prop --spinner-color: цвет спиннера
     * @prop --spinner-size: размер спиннера
     *
     * @prop --dot-size: размер точки (для лоадера точек)
     * @prop --dots-margin: отступ между точек
     *
     * @prop --font-size: размер шрифта сообщения
     * @prop --font-weight: жирность шрифта сообщения
     * @prop --color: цвет текста сообщения
     * @prop --color-rgb: цвет текста сообщения
    */

    --min-width: initial;
    --max-width: initial;
    --border-radius: var(--bazis-border-radius);
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --font-size: inherit;
    --font-weight: initial;
    --color: var(--bazis-text-color);
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: var(--min-width);
    max-width: var(--max-width);
    width: var(--width);
    height: var(--height);
    margin: auto;
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    padding: var(--bazis-padding-3x);
    background: var(--background);
    text-align: center;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    color: var(--color);

    &__spinner {
        width: var(--spinner-size);
        height: var(--spinner-size);
        margin: var(--bazis-margin-4x) auto;
        border-radius: 50%;
        background: var(--spinner-background);
        animation: rotate 2s linear infinite;

        circle {
            stroke: var(--spinner-color);
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }

    &_inline {
        @extend .bazis-loader;
        position: relative;
        width: 100%;
    }

    &_dots {
        display: inline-block;
        position: relative;
        margin: 0 calc(var(--dots-margin) + var(--dot-size));
        width: var(--dot-size);
        height: var(--dot-size);
        border-radius: 50%;
        background-color: var(--color);
        color: var(--color);
        vertical-align: middle;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;

        &::before,
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
        }

        &::before {
            left: calc(var(--dots-margin) * (-1));
            width: var(--dot-size);
            height: var(--dot-size);
            border-radius: 50%;
            background-color: var(--color);
            color: var(--color);
            animation: dot-flashing 1s infinite alternate;
            animation-delay: 0s;
        }
        &::after {
            left: var(--dots-margin);
            width: var(--dot-size);
            height: var(--dot-size);
            border-radius: 50%;
            background-color: var(--color);
            color: var(--color);
            animation: dot-flashing 1s infinite alternate;
            animation-delay: 1s;
        }
    }

    &_dark {
        --background: var(--bazis-color-black);
        --color: var(--bazis-color-white);
    }

    &_transparent {
        --background: transparent;
        --spinner-background: var(--bazis-color-white);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 94;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 56, 94;
        stroke-dashoffset: -19;
    }
    100% {
        stroke-dasharray: 56, 94;
        stroke-dashoffset: -87;
    }
}

@keyframes dot-flashing {
    0% {
        background-color: var(--color);
    }
    50%,
    100% {
        background-color: rgba(var(--color-rgb), 0.2);
    }
}

bazis-skeleton:first-child:last-child {
    --margin-top: 0;
    --margin-bottom: 0;
}

bazis-skeleton:last-of-type {
    --margin-bottom: 0;
}
