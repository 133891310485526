bazis-tooltip {
    --padding-start: var(--bazis-padding-3x);
    --padding-end: var(--bazis-padding-3x);
    --padding-top: var(--bazis-padding-3x);
    --padding-bottom: var(--bazis-padding-3x);
    --border-radius: var(--bazis-border-radius);
    --min-height: 40px;
    --min-width: 100px;
    --max-width: 312px;
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 16px;

    --height-arrow: 4px;
    --width-arrow: 12px;

    &:not(.bazis-tooltip_light) {
        --background: var(--bazis-text-color);
        --color: var(--bazis-text-color-contrast);
    }
}

bazis-tooltip.bazis-tooltip_light {
    --max-width: 405px;
    --max-height: 380px;

    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-background-medium);

    --height-arrow: 8px;
    --width-arrow: 24px;

    overflow: auto;
}
