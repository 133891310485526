.bazis-alert {
    @include props-position;
    @include props-block-model;
    @include props-width;
    @include props-height;
    @include props-colors;

    @include position;

    position: fixed;
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;

    &__wrapper {
        @include block-model;
        @include width;
        @include height;
        @include colors;

        display: flex;
        outline: none;
        font-family: var(--bazis-font-family, inherit);
    }

    &__content {
        flex: 1;
        overflow: auto;
    }

    &__title {
        margin-bottom: var(--bazis-margin-1x);
        font-size: 22px;
        line-height: 32px;
        font-weight: 500;
        color: var(--bazis-text-color);
    }

    &__message {
        white-space: var(--white-space);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: var(--bazis-text-color-secondary);

        p:not(.bazis-control__error p) {
            margin-top: var(--bazis-margin-3x);
            font-size: 14px;
            line-height: 20px;

            strong {
                font-weight: 500;
            }
        }
    }

    &__icon {
        flex-shrink: 0;
        position: relative;
        margin-left: var(--bazis-margin-3x);
        padding-right: var(--bazis-padding-5x);

        & + .bazis-alert__content {
            padding-left: var(--bazis-padding-4x);
            border-left: 1px solid var(--bazis-border-color);
        }
    }

    &__close {
        border: none;
        outline: 0;
        background: transparent;
        width: 24px;
        height: 24px;
        padding: 0;
        color: var(--bazis-placeholder-color);
    }

    & + .bazis-backdrop {
        --background: var(--bazis-background-medium);
        --opacity: 1;
    }

    &__buttons {
        display: flex;

        bazis-button {
            margin-top: calc(var(--bazis-margin-6x) * 2);

            + bazis-button {
                margin-left: var(--bazis-margin-3x);
            }
        }
    }

    @media (max-width: 1024px) {
        left: 20px;
        right: 20px;

        &__content {
            width: 100%;
        }
    }

    @media (max-width: 640px) {
        &__buttons {
            flex-direction: column;
            gap: var(--bazis-margin-4x);

            bazis-button {
                margin-top: var(--bazis-margin-6x);

                + bazis-button {
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }
    }

    @media (max-width: 576px) {
        // top: auto;
        // left: 8px;
        // right: 8px;
        // bottom: 8px;
        z-index: 1102;

        + .bazis-backdrop {
            z-index: 1101;
        }

        &__wrapper {
            position: relative;
            flex-direction: column;
            align-items: center;
            padding: var(--bazis-padding-5x);
        }

        &__icon {
            padding-right: 0;
            margin-left: 0;
            margin-bottom: var(--bazis-margin-6x);
        }

        &__content {
            padding-left: 0;
            border-left: 0;
            text-align: center;
        }

        &__title {
            font-size: 18px;
            line-height: 24px;
        }

        &__message {
            font-size: 14px;
            line-height: 20px;
            color: var(--bazis-text-color);
        }

        &__close {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
}
