/* Typography */
@import "typography";

$path: '/assets';

/* Components and base classes */
@import "components/web/main.scss";
@import "base/main.scss";
@import "components/common/main.scss";
@import "components/form/main.scss";
@import "components/table/table.base";
@import "components/map/map";

/* Leaflet */
@import "components/map/leaflet/main";
@import "components/map/leaflet/popup";
@import "components/map/leaflet/tooltip";
@import "components/map/leaflet/oldie";

/* Pages */
@import 'pages/page';
@import 'pages/dummy-page';
@import 'pages/list-page';
