table {
    @include props-margin;
    @include margin;
    width: 100%;

    &.bazis-table-fixed {
        table-layout: fixed;
    }
}

tr {
    vertical-align: top;
}

th,
td {
    @include props-block-model;
    @include props-colors;
    @include props-font;

    @include colors;
    @include font;
    @include block-model;
}

td {
    .bazis-td-no-border &,
    &.bazis-td-no-border {
        --border-width: 0;
    }
    &.bazis-td-top-zero {
        --padding-top: 0;
    }
    &.bazis-td-bottom-zero {
        --padding-bottom: 0;
    }
}
