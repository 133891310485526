bazis-grid.sl-grid_table {
    --bazis-grid-columns: 24;
    margin-bottom: var(--bazis-margin-6x);

    > bazis-row,
    *:not(bazis-row) bazis-row {
        border-bottom: 1px solid var(--bazis-border-color);

        > bazis-col {
            --bazis-grid-column-padding-start: var(--bazis-padding-3x);
            --bazis-grid-column-padding-end: var(--bazis-padding-3x);
            --bazis-grid-column-padding-top: var(--bazis-padding-3x);
            --bazis-grid-column-padding-bottom: var(--bazis-padding-3x);

            line-height: 20px;

            &.sl-col_th {
                --bazis-grid-column-padding-top: 7px;
                --bazis-grid-column-padding-bottom: 7px;

                background: var(--bazis-background-medium);

                font-size: 13px;
                font-weight: 500;
                text-align: left;
                color: var(--bazis-text-color-secondary);

                &.sl-col_selectable {
                    --bazis-grid-column-padding-start: 52px;
                }

                &.sl-col_sortable {
                    bazis-button {
                        margin-top: calc(var(--bazis-margin-2x) * -1);
                    }
                }
            }

            &.sl-table__cell-btns {
                --bazis-grid-column-padding-top: 7px;
            }
        }

        &.sl-row_thead {
            border-bottom: 0;

            &.sl-th_no-padding > bazis-col,
            > bazis-col.sl-th_no-padding {
                --bazis-grid-column-padding-bottom: 0;
            }
            &.sl-th_no-padding + & > bazis-col {
                --bazis-grid-column-padding-top: 0;
            }
        }

        &.bazis-td-no-border {
            border-width: 0;
        }

        &.sl-td-narrow {
            position: relative;
            top: -16px;

            > bazis-col {
                --bazis-grid-column-padding-top: var(--bazis-padding-1x);
                --bazis-grid-column-padding-bottom: var(--bazis-padding-1x);
            }

            &.bazis-td-no-border > bazis-col {
                --bazis-grid-column-padding-top: 0;
                --bazis-grid-column-padding-bottom: var(--bazis-padding-1x);
            }
        }
    }

    & & {
        margin-bottom: 0;

        > bazis-row,
        > * > bazis-row {
            &:first-child {
                > bazis-col {
                    --bazis-grid-column-padding-top: 0;
                }
            }

            &:last-child {
                border-bottom: 0;

                > bazis-col {
                    --bazis-grid-column-padding-bottom: 0;
                }
            }

            &.sl-tr_no-border {
                border-bottom: 0;
            }

            > bazis-col {
                --bazis-grid-column-padding-start: 0;
                --bazis-grid-column-padding-end: 0;
            }
        }
    }

    &.sl-grid_table_hoverable {
        > bazis-row,
        *:not(bazis-row) bazis-row {
            &.sl-row_tbody:hover {
                background: var(--bazis-background-accent);

                .sl-row__title {
                    --color: var(--bazis-link-hover-color);
                    color: var(--bazis-link-hover-color);
                }
            }
        }
    }

    &.sl-grid_proposition {
        --bazis-grid-columns: 12;
        margin-top: var(--bazis-margin-6x);
        margin-bottom: var(--bazis-margin-6x);

        bazis-row {
            margin-bottom: var(--bazis-margin-2x);
            border-bottom: 0;

            bazis-col {
                --bazis-grid-column-padding-start: var(--bazis-padding-4x);
                --bazis-grid-column-padding-end: var(--bazis-padding-4x);
                --bazis-grid-column-padding-top: var(--bazis-padding-4x);
                --bazis-grid-column-padding-bottom: var(--bazis-padding-4x);
                background: var(--bazis-color-white);

                &:first-child {
                    border-radius: var(--bazis-border-radius) 0 0 var(--bazis-border-radius);
                }
                &:last-child {
                    border-radius: 0 var(--bazis-border-radius) var(--bazis-border-radius) 0;
                }
            }
        }
    }
}

bazis-grid.sl-grid_table {
    .sl-dropdown_action {
        bazis-button.bazis-dropdown__trigger {
            --color-hover: var(--bazis-color-action);

            bazis-icon {
                color: currentColor;
            }
        }

        &:hover {
            bazis-button.bazis-dropdown__trigger {
                --color: var(--color-hover);
            }
        }
    }

    .bazis-dropdown {
        bazis-vehicle-number + bazis-button.bazis-dropdown__trigger {
            --background: transparent;
            --background-hover: transparent;
            position: relative;
            top: -4px;
        }
    }

    .sl-btn-expanded {
        position: relative;
        top: -9px;
    }

    > bazis-row,
    > * > bazis-row {
        > bazis-col {
            .bazis-dropdown {
                &__trigger {
                    --background: var(--bazis-color-white);
                    --color: var(--bazis-placeholder-color);
                }
                &__body {
                    --top: 36px;
                }

                &_vehicle {
                    .bazis-dropdown__trigger {
                        --color: var(--bazis-color-base);
                    }
                }
            }

            bazis-button bazis-icon {
                --bazis-icon-s-size: 16px;
                color: var(--bazis-placeholder-color);
            }

            .sl-tr-collapsed {
                cursor: pointer;

                bazis-icon {
                    font-size: 8px;
                    color: var(--bazis-text-color);
                }
            }

            * + bazis-status {
                display: block;
                margin-top: var(--bazis-margin-2x);
            }
        }
    }
}
