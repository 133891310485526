.bazis-cookie {
    --bottom: 20px;
    @include props-font();

    position: fixed;
    z-index: 1001;
    bottom: var(--bottom);
    left: 0;
    right: 0;

    @include font();

    .bazis-block {
        --max-width: 1360px;
        --margin-bottom: 0;
        --padding-top: 10px;
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        bazis-icon {
            margin-right: var(--bazis-margin-3x);
            flex-shrink: 0;
        }

        a {
            font-weight: 500;
            letter-spacing: 0.25px;
            text-decoration: none;
        }

        > div {
            justify-content: center;
            width: 100%;
            padding: 6px 0;
        }
    }

    @media (max-width: 992px) {
        .bazis-block > div {
            justify-content: flex-start;
            padding-right: 72px;
        }
    }

    @media (max-width: 576px) {
        .bazis-block {
            display: block;

            > div {
                padding-right: 0;
            }
        }

        bazis-button {
            margin-top: var(--bazis-margin-4x);
            margin-left: var(--bazis-margin-8x);
        }
    }
}

// implemented
.bazis-cookie {
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 20px;
}
