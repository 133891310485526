.sl-card-balance {
    position: relative;
    border-radius: 5px;
    background: var(--bazis-color-base);

    img {
        display: block;
    }

    bazis-icon {
        position: absolute;
        right: 10px;
        bottom: 8px;
        color: var(--bazis-color-white);
    }

    &__title {
        position: absolute;
        top: 5px;
        left: 25px;
        font-size: 8px;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--bazis-background-dark);
    }
}
