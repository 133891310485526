.bazis-gallery {
    &-preview {
        --width: 161px;
        --height: 120px;

        .bazis-figure {
            --width: 161px;
            --height: 120px;
        }

        &__more {
            --top: 0;
            --bottom: 0;
            --right: 0;
            --width: 28px;
            --height: 120px;
            --background: var(--bazis-color-black-rgb);
            --opacity: 0.3;
            --border-radius: 0px 4px 4px 0px;
            --line-height: var(--height);
            --font-size: 11px;
            --font-weight: 500;
            --color: var(--bazis-color-white);
        }
    }

    &-slider {
        --width: 100%;
    }

    &-single {
        .bazis-figure {
            --height: 455px;
            --width: 100%;
            margin: 0;
        }

        bazis-button.bazis-color-action {
            --border-color: var(--bazis-color-action);
            --border-color-hover: var(--bazis-color-action);
        }
    }
}
