.bazis-avatar {
    @include props-width-height;
    @include props-colors;
    @include props-font;

    --border-radius: initial;
    --color-hover: var(--color);

    @include width-height;
    @include colors;
    @include font;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);

    &:hover {
        color: var(--color-hover);
    }

    &_small {
        width: calc(var(--width) / 2);
        height: calc(var(--width) / 2)
    }

    &.bazis-color {
        --background: var(--bazis-color-base);
    }
}
