.sl-announcement {
    .bazis-list-page__body {
        padding: 0;
    }

    @media (max-width: 576px) {
        .bazis-container {
            padding: 0;
        }
    }
}
