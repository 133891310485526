.bazis-checkbox {
    &__label {
        .sl-checkbox-no-margin & {
            margin-right: 0;
        }
    }

    .bazis-list_vline .bazis-control & {
        &__label::before {
            margin-right: var(--bazis-margin-3x);
        }
    }

    .sl-item--selected_partial & {
        &__label::before {
            content: '—';
            border-color: var(--border-color-checked);
            background: var(--background-checked);

            color: var(--checkmark-color);
        }
    }

    .sl-col_selectable .bazis-control & {
        &__label::before {
            margin-right: var(--bazis-margin-6x);
        }
    }

    @media (max-width: 1024px) {
        .sl-toolbar_table-header .bazis-list_vline .bazis-control & {
            margin-left: 0;
        }
    }
}
