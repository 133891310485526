.bazis-btn-group {
    display: flex;
    border-radius: var(--border-radius);

    &__item {
        &:first-child {
            > bazis-button::part(native) {
                border-radius: var(--border-radius) 0 0 var(--border-radius);
                border-left: var(--border-width) var(--border-style) var(--border-color);
            }
        }
        &:last-child {
            > bazis-button {
                &::part(native) {
                    border-radius: 0 var(--border-radius) var(--border-radius) 0;
                }
                &.bazis-btn_clear::part(native)::after {
                    opacity: 0;
                }
            }
        }
        &:only-child {
            > bazis-button::part(native) {
                border-radius: var(--border-radius);
            }
        }

        > bazis-button {
            --outline-width: none;
            --outline-color: none;
            --outline-style: none;

            &::part(native) {
                border-radius: 0;
                border: var(--border-width) var(--border-style) var(--border-color);
                border-left: none;
            }

            &:hover::part(native),
            &:focus-visible::part(native) {
                background: var(--background-hover);
                color: var(--color-hover);
            }

            &.bazis-btn_large {
                bazis-icon {
                    font-size: 24px;
                }
            }

            &.bazis-btn_clear {
                &::part(native)::after {
                    top: var(--padding-top);
                    bottom: var(--padding-bottom);
                    left: auto;
                    opacity: 1;
                }

                bazis-icon[slot='icon-only'] {
                    margin-left: 0;
                    margin-right: 0;
                }
                @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
                    bazis-icon[slot='icon-only'] {
                        -webkit-margin-start: 0;
                        margin-inline-start: 0;
                        -webkit-margin-end: 0;
                        margin-inline-end: 0;
                    }
                }
            }
        }
    }

    &--disabled {
        .bazis-btn-group__item > bazis-button {
            cursor: default;
            pointer-events: none;

            &::part(native) {
                background: var(--background-disabled);
                opacity: var(--disabled-opacity);
                color: var(--color-disabled);
            }
        }
    }

    // types
    &_h {
        flex-direction: column;

        .bazis-btn-group__item {
            > bazis-button {
                &::part(native) {
                    border: var(--border-width) var(--border-style) var(--border-color);
                    border-top: none;
                }
            }

            &:first-child {
                > bazis-button::part(native) {
                    border-radius: var(--border-radius) var(--border-radius) 0 0;
                    border-top: var(--border-width) var(--border-style) var(--border-color);
                }
            }
            &:last-child {
                > bazis-button::part(native) {
                    border-radius: 0 0 var(--border-radius) var(--border-radius);
                }
            }
        }
    }
}
