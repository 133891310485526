.bazis-list-page {
    --background-head: var(--bazis-background-medium);
    --background-filters: var(--bazis-background-medium);
    --background-body: transparent;
    --gap-applied-filters: var(--bazis-margin-2x);

    &__head {
        background: var(--background-head);

        .bazis-breadcrumbs {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__title {
        display: flex;
        align-items: center;

        @media (min-width: 1024.98px) {
            h1 {
                margin: 0;

                // .os-Windows & {
                //     position: relative;
                //     top: -6px;
                // }
            }
        }
    }
    &__filters {
        background: var(--background-filters);
    }

    &__applied-filters {
        --margin-top: var(--bazis-margin-6x);
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-applied-filters);
        margin-top: var(--margin-top);
    }

    &__body {
        background: var(--background-body);
    }

    &__tools {
        --gap: var(--bazis-margin-4x);
        --gap-group: var(--bazis-margin-3x);
        --column-gap: var(--gap);
        --row-gap: var(--bazis-margin-5x);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: var(--gap);

        .bazis-tools__group {
            display: flex;
            gap: var(--gap-group);
        }

        .bazis-tools__button {
            display: flex;
            gap: var(--gap-group);
            margin-left: var(--gap);
        }
    }

    @media (max-width: 1024px) {
        &__tools {
            justify-content: flex-end;
        }
    }

    @media (max-width: 768px) {
        &__tools {
            display: grid;
            grid-template-columns: auto min-content;
            grid-auto-columns: min-content;
            grid-auto-flow: column;
            column-gap: var(--column-gap);
            justify-content: normal;
        }
    }

    @media (max-width: 576px) {
        .bazis-list-page__title {
            display: block;
        }

        &__tools {
            grid-auto-flow: row;
            row-gap: var(--row-gap);
            justify-content: stretch;

            .bazis-tools__button {
                width: 100%;
                margin: 0;
    
                bazis-button {
                    display: block;
                    width: 100%;
                }
            }

            .bazis-tools__button {
                grid-column: span 2;
            }

            .bazis-tools__group_long {
                grid-column: span 2;
            }
        }
    }
}
