.bazis-card {
    --padding-top: var(--bazis-padding-3x);
    --padding-start: var(--bazis-padding-3x);
    --padding-end: var(--bazis-padding-3x);
    --padding-bottom: calc(var(--bazis-padding-3x) * 3);
    --background: var(--bazis-background);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-color-hover: var(--bazis-color-primary);

    // так как карточка распологается внутри флекс контейнера, и имеет в дочерних канву
    // (канва не дружит с флекс контейнером вычисляя свои размеры)
    // необходимо задать на каком либо уровне обертки фиксированную, а не плавающую ширину.
    // для адаптивного дизайна - необходимо будет, либо отступы делать динамическими,
    // либо для каждой точки задавать свою фиксированную ширину
    // --width: 316px;
    height: 100%;
    position: relative;
    line-height: 20px;

    .bazis-map-wrapper {
        --height: 132px;
        --margin-bottom: var(--bazis-margin-3x);
        position: relative;

        &,
        .leaflet-container {
            border-radius: var(--bazis-border-radius);
        }

        .bazis-dropdown {
            position: absolute;
            z-index: 150;
            top: 4px;
            right: 4px;

            &__trigger {
                --height: 28px;
                --background: var(--bazis-color-white);
                --padding-start: var(--bazis-padding-3x);
                --padding-end: var(--bazis-padding-3x);
                --color: var(--bazis-placeholder-color);

                width: 28px;
            }
        }
    }

    .bazis-status {
        --margin-bottom: var(--bazis-margin-2x);
        --margin-top: var(--bazis-margin-2x);
    }

    .bazis-card__title {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: var(--bazis-link-color);
    }

    &:hover {
        .bazis-card__title {
            --color: var(--bazis-link-hover-color);
            color: var(--bazis-link-hover-color);
        }
    }
}

a.bazis-card {
    display: block;
    text-decoration: none;
}
