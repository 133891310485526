.bazis-list {
    &_select {
        .sl-dropdown_action &,
        .bazis-accordion_doc .bazis-dropdown &,
        .bazis-map__base-control &,
        .bazis-signed-file-inline & {
            --border-radius-list: var(--bazis-border-radius);
            --border-width-list: 1px;
            --border-style-list: solid;
            --border-color-list: var(--bazis-background-medium);
            --padding-top-list: var(--bazis-padding-3x);
            --padding-bottom-list: var(--bazis-padding-3x);
            --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
        }

        .bazis-dropdown & {
            bazis-button.bazis-item {
                --background: transparent;
                --background-hover: var(--bazis-background-dark);
                --padding-start: var(--bazis-padding-3x);
                --padding-end: var(--bazis-padding-3x);
                --padding-top: var(--bazis-padding-2x);
                --padding-bottom: var(--bazis-padding-2x);
                text-align: left;

                &::part(inner) {
                    --padding-start: 0;
                    --padding-end: 0;
                    justify-content: flex-start;
                }

                &:active:hover {
                    &::part(inner) {
                        background: var(--background-selected);
                        color: var(--color-selected);
                    }
                }
            }
        }

        .bazis-context-menu & {
            --padding-top-list: var(--bazis-padding-3x);
            --padding-bottom-list: var(--bazis-padding-3x);
            --border-width-list: 1px;
            --border-style-list: solid;
            --border-color-list: var(--bazis-background-medium);
            --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
            width: 200px;
        }
    }

    &_control-dd {
        &-wrapper {
            .sl-form app-input-address & {
                --padding-bottom: 0;
            }
        }

        app-select-vehicle & {
            position: static;
        }
    }

    .sl-item_last-total {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.sl-list {
    &_easy {
        > .bazis-item + .bazis-item {
            --padding-top: var(--bazis-padding-4x);

            app-profile-licenses & {
                --padding-top: var(--bazis-padding-6x);
            }
        }

        > .bazis-item {
            bazis-tooltip & {
                --padding-top: var(--bazis-padding-3x);
            }
        }
    }

    &_info {
        --margin-bottom: var(--bazis-margin-3x);
    }

    &_carrier-task {
        @extend .bazis-list_underline;
        --padding-top: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);

        .bazis-item {
            &:first-child {
                --padding-top: 0;
            }

            &:last-child {
                --border-width: 0;
                --padding-bottom: 0;

                .sl-grid_flight-head:last-child {
                    > bazis-row > bazis-col {
                        --bazis-grid-column-padding-bottom: 0;
                    }
                }
            }
        }

        app-carrier-tasks-task:not(:first-of-type) .bazis-block_accent {
            --margin-top: var(--bazis-margin-2x);
        }
    }

    &_ticket {
        @extend .bazis-list_history;

        > .bazis-item {
            &::before {
                top: 3px;
                width: 12px;
                height: 12px;
                border: 2px solid var(--bazis-color-primary);
            }

            &::after {
                left: 7.5px;
                top: 25px;
                bottom: calc(var(--margin-bottom) * -1 + 3px);
            }
            &:last-child {
                &::before {
                    border-color: var(--bazis-color-action);
                }
            }
        }
    }

    &_trip {
        @extend .bazis-list_history;
        --margin-start-list: var(--bazis-margin-4x);
        --margin-top-list: var(--bazis-margin-3x);

        > .bazis-item {
            --padding-start: var(--bazis-padding-4x);
            --margin-bottom: var(--bazis-margin-3x);
            --font-size: 13px;
            --line-height: 16px;

            &::before {
                top: 3px;
                width: 5px;
                height: 5px;
                background: var(--bazis-color-base, var(--bazis-placeholder-color));
                // border: 2px solid var(--bazis-color-primary);
            }

            &::after {
                left: 3px;
                top: 10px;
                // bottom: calc(var(--margin-bottom) * -1 + 3px);
            }
            &:last-child {
                &::before {
                    border-color: var(--bazis-color-base, var(--bazis-placeholder-color));
                }
            }
        }

        &_time {
            > .bazis-item {
                --padding-start: 66px;

                .sl-item__time {
                    --line-height: 20px;
                    position: absolute;
                    left: 0;
                }

                &::before {
                    left: 50px;
                }
                &::after {
                    left: 53px;
                }
            }
        }
    }

    &_vtabs {
        --color: var(--bazis-text-color);
        // --color-hover: var(--color);
        // --color-selected: var(--color);
        --font-size: 13px;
        --font-weight: 400;
        --line-height: 20px;
        --padding-start: calc(var(--bazis-padding-4x) - 2px);
        --padding-end: var(--bazis-padding-5x);
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        --border-width: 0 0 0 2px;
        --border-color: transparent;
        --border-style: solid;
        --border-radius: 0;

        aside & {
            --margin-start-list: calc(var(--bazis-margin-4x) * -1);
            --margin-top-list: var(--bazis-margin-2x);
        }

        aside.sl-aside_left & {
            --margin-start-list: calc(var(--bazis-margin-5x) * -1);
        }

        .bazis-item {
            cursor: pointer;

            &--active {
                --font-weight: 500;
                --border-color: var(--bazis-text-color);
            }
        }
    }

    &_search-org {
        @extend .bazis-list_underline;
        --margin-top-list: 56px;

        > .bazis-item {
            display: flex;
        }

        bazis-icon {
            margin-right: var(--bazis-margin-4x);
            color: var(--bazis-placeholder-color);
        }

        .bazis-list_short-info {
            --margin-list-top: var(--bazis-margin-2x);
            --font-size: 13px;
            --line-height: 20px;
        }
    }
}
