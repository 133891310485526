.bh-text-uppercase {
    text-transform: uppercase !important;
}

.bh-text-lowercase {
    text-transform: lowercase !important;
}

.bh-text-capitalize {
    text-transform: capitalize !important;
}

@media (min-width: 576.98px) {
    .bh-text-sm-uppercase {
        text-transform: uppercase !important;
    }

    .bh-text-sm-lowercase {
        text-transform: lowercase !important;
    }

    .bh-text-sm-capitalize {
        text-transform: capitalize !important;
    }
}
@media (min-width: 768.98px) {
    .bh-text-md-uppercase {
        text-transform: uppercase !important;
    }

    .bh-text-md-lowercase {
        text-transform: lowercase !important;
    }

    .bh-text-md-capitalize {
        text-transform: capitalize !important;
    }
}
@media (min-width: 992.98px) {
    .bh-text-lg-uppercase {
        text-transform: uppercase !important;
    }

    .bh-text-lg-lowercase {
        text-transform: lowercase !important;
    }

    .bh-text-lg-capitalize {
        text-transform: capitalize !important;
    }
}
@media (min-width: 1024.98px) {
    .bh-text-xl-uppercase {
        text-transform: uppercase !important;
    }

    .bh-text-xl-lowercase {
        text-transform: lowercase !important;
    }

    .bh-text-xl-capitalize {
        text-transform: capitalize !important;
    }
}
@media (min-width: 1280.98px) {
    .bh-text-xxl-uppercase {
        text-transform: uppercase !important;
    }

    .bh-text-xxl-lowercase {
        text-transform: lowercase !important;
    }

    .bh-text-xxl-capitalize {
        text-transform: capitalize !important;
    }
}
