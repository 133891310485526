@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Regular'), local('Gordita-Regular'),
        url('/assets/fonts/Gordita-Regular.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Italic'), local('Gordita-Italic'),
        url('/assets/fonts/Gordita-Italic.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Medium'), local('Gordita-Medium'),
        url('/assets/fonts/Gordita-Medium.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Medium Italic'), local('Gordita-Medium-Italic'),
        url('/assets/fonts/Gordita-Medium-Italic.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Medium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Bold'), local('Gordita-Bold'),
        url('/assets/fonts/Gordita-Bold.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'RoadNumbers';
    font-display: swap;
    src: local('RoadNumbers'), local('RoadNumbers2.0'),
        url('/assets/fonts/RoadNumbers2.0.otf') format('otf'),
        url('/assets/fonts/RoadNumbers2.0.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap');

// h2, в агро нету
h4,
h5 {
    margin: 0; // необходимо будет уточнение
}

h1,
h2,
h3 {
    font-weight: 400;
}
h1,
.bazis-h1 {

    @media (max-width: 1024px) {
        --bazis-font-size-h1: var(--bazis-font-size-h3);
        --bazis-line-height-h1: var(--bazis-line-height-h3);
        --bazis-h1-margin: var(--bazis-h3-margin);
    }
}

h3,
.bazis-h3 {
    bazis-toolbar & {
        --bazis-h3-margin: 0;
    }

    @media (max-width: 1024px) {
        &:not(.bazis-modal__header h3) {
            --bazis-font-size-h3: var(--bazis-font-size-h5);
            --bazis-line-height-h3: var(--bazis-line-height-h5);
        }
    }
}

h4,
.bazis-h4 {
    font-weight: 500;
}

h5,
.bazis-h5 {
    font-weight: 700;

    &.bh-font-medium {
        font-weight: 500;
    }
    &.bh-font-normal {
        font-weight: 400;
    }
    &.bazis-text-action,
    .bazis-text-action & {
        letter-spacing: 0.75px;
    }

    @media (max-width: 1024px) {
        --bazis-font-size-h5: var(--bazis-font-size-p-major);
        --bazis-line-height-h5: var(--bazis-line-height-p-major);
        // --bazis-h3-margin: var(--bazis-h3-margin);
    }
}

h6,
.bazis-h6 {
    font-weight: 700;

    bazis-button {
        vertical-align: middle;
    }

    &.bh-font-medium {
        font-weight: 500;
    }
    &.bh-font-normal {
        font-weight: 400;
    }
    &.bazis-text-action,
    .bazis-text-action & {
        letter-spacing: 0.5px;
    }

    &:only-child {
        margin-bottom: 0;
    }
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

strong {
    font-weight: 700;
}

span.bazis-color {
    color: var(--bazis-color-base);
}

.bazis-text {
    &-action {
        font-weight: 500;
        letter-spacing: 0.25px;

        .bazis-p_major &,
        &.bazis-p_major,
        .bazis-p_minor &,
        &.bazis-p_minor,
        .bazis-text_major &,
        &.bazis-text_major,
        .bazis-text_minor &,
        &.bazis-text_minor {
            letter-spacing: 0.5px;
        }
    }

    &_reduced {
        &,
        &.bazis-p,
        &.bazis-p_major,
        &.bazis-text_major {
            line-height: 16px;
        }
        &.bazis-p_minor,
        &.bazis-text_minor {
            line-height: 12px;
        }
    }
}

.bazis-small_uppercase {
    --bazis-font-size-small: 10px;
    --bazis-line-height-small: 12px;
    font-weight: 500;
}

.bazis-link {
    &_s {
        &.bazis-text-action:not(.bazis-text_reduced),
        .bazis-text-action:not(.bazis-text_reduced) & {
            letter-spacing: 0.25px;
        }
    }
    &_m {
        &.bazis-text-action:not(.bazis-text_reduced),
        .bazis-text-action:not(.bazis-text_reduced) & {
            letter-spacing: 0.5px;
        }
    }
}

.bazis-icon_fill {
    width: 20px;
    height: 20px;
    padding: 6px;
    border-radius: var(--bazis-border-radius);
}
