.bazis-timeline {
    .sl-dispatcher-on-map-layer & {
        position: absolute;
        bottom: 0;
        left: 404px;
        right: -42px;
        z-index: 150;
        border-radius: var(--bazis-border-radius);
        background: var(--bazis-color-white);
        padding: 10px var(--bazis-padding-3x);
        transition: left 300ms ease-in-out;
        will-change: left;
        pointer-events: all;
    }

    .sl-dispatcher-on-map-layer .sl-dispatcher-panel--closed ~ & {
        left: 16px;
    }
}
