.sl-avatar_userpic {
    @extend .bazis-avatar;

    --width: 48px;
    --height: 48px;
    --background: var(--bazis-background);
    --color: var(--bazis-text-color-secondary);

    float: left;
    margin-right: var(--bazis-margin-4x);
    border: 1px solid var(--bazis-border-color);
}
