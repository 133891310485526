.bazis-datetime-control {
    --margin-bottom: 0;
    position: relative;
    margin-bottom: var(--margin-bottom);

    > .bazis-control__note {
        display: flex;
        align-items: center;

        .bazis-checkbox label::before {
            margin-left: 0;
        }
    }
}
