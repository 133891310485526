.bazis-modal {
    /*
    * @prop --backdrop-opacity:	прозрачность подложки
    * @prop --background: фон модалки
    * @prop --border-radius: радиус скругления модалки
    * @prop --border-width:	толщина границы
    * @prop --border-style:	стиль границы
    * @prop --border-color:	цвет границы
    * @prop --box-shadow: тень модального окна
    * @prop --height: высота модального окна
    * @prop --width: ширина модального окна
    * @prop --max-height: максимальная высота модального окна
    * @prop --max-width: максимальная ширина модального окна
    * @prop --min-height: минимальная высота модального окна
    * @prop --min-width: минимальная ширина модального окна
    *
    * @prop --top-close: позиционирование кнопки закрытия по верху окна
    * @prop --bottom-close: позиционирование кнопки закрытия по низу окна
    * @prop --left-close: позиционирование кнопки закрытия слева окна
    * @prop --right-close: позиционирование кнопки закрытия справа окна
    *
    * @prop --padding-start-header: внутренний начальный отступ шапки модального окна
    * @prop --padding-end-header: внутренний конечный отступ шапки модального окна
    * @prop --padding-top-header: внутренний верхний отступ шапки модального окна
    * @prop --padding-bottom-header: внутренний нижний отступ шапки модального окна
    *
    * @prop --padding-start-content: внутренний начальный отступ контента модального окна
    * @prop --padding-end-content: внутренний конечнй отступ контента модального окна
    * @prop --padding-top-content: внутренний верхний отступ контента модального окна
    * @prop --padding-bottom-content: внутренний начальный отступ контента модального окна
    *
    * @prop --padding-start-footer: внутренний начальный отступ подвала модального окна
    * @prop --padding-end-footer: внутренний конечный отступ подвала модального окна
    * @prop --padding-top-footer: внутренний верхний отступ подвала модального окна
    * @prop --padding-bottom-footer: внутренний нижний отступ подвала модального окна
    */
    --backdrop-opacity: initial;
    --background: initial;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --box-shadow: initial;
    --height: initial;
    --width: initial;
    --max-height: initial;
    --max-width: initial;
    --min-height: initial;
    --min-width: initial;

    position: fixed;
    z-index: 1101;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: var(--background);
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    box-shadow: var(--box-shadow);
    height: var(--height);
    max-height: var(--max-height);
    min-height: var(--min-height);
    width: var(--width);
    max-width: var(--max-width);
    min-width: var(--min-width);

    &-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__close {
        position: absolute;
        z-index: 1;
        right: var(--right-close);
        top: var(--top-close);
        left: var(--left-close);
        bottom: var(--bottom-close);
    }

    &__header {
        padding-left: var(--padding-start-header);
        padding-right: var(--padding-end-header);
        padding-top: var(--padding-top-header);
        padding-bottom: var(--padding-bottom-header);
    }

    &__content {
        height: 100%;
        overflow: auto;
        overscroll-behavior: contain;
        padding-left: var(--padding-start-content);
        padding-right: var(--padding-end-content);
        padding-top: var(--padding-top-content);
        padding-bottom: var(--padding-bottom-content);
    }

    &__footer {
        padding-left: var(--padding-start-footer);
        padding-right: var(--padding-end-footer);
        padding-top: var(--padding-top-footer);
        padding-bottom: var(--padding-bottom-footer);
    }

    &__header,
    &__footer {
        flex-shrink: 1;
    }

    &__header_shadow {
        @extend .bazis-modal__header;
        box-shadow: var(--box-shadow);
    }

    // types
    &.map {
        .bazis-map-wrapper {
            --margin-start: calc(var(--padding-start-content) * -1);
            --margin-end: calc(var(--padding-end-content) * -1);
            --margin-bottom: calc(var(--padding-bottom-content) * -1);
            --width: calc(100% + var(--padding-end-content) + var(--padding-start-content));
        }
    }

    &.fullscreen {
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
    }

    &.dummy {
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
    }

    &.gallery {
        .bazis-modal__content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img,
        video {
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }

        bazis-button {
            --background: transparent;
            --border-color: var(--bazis-color-white);
            --color: var(--bazis-color-white);
            --color-hover: var(--bazis-text-color);

            &.bazis-btn-prev,
            &.bazis-btn-next {
                position: absolute;
                top: calc(50% - 18px); // половина на высоты кнопок
            }

            &.bazis-btn-prev {
                left: 40px;
            }

            &.bazis-btn-next {
                right: 40px;
            }
        }
    }

    &.video {
        .bazis-modal__content {
            display: flex;
            align-items: center;
            justify-content: center;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &.mobile-bottom {
    }

    &.files {
        --max-width: 480px;
        --padding-top-header: var(--bazis-padding-2x);
        --padding-bottom-header: var(--bazis-padding-2x);
        --padding-start-header: var(--bazis-padding-6x);
        --padding-end-header: var(--bazis-padding-6x);

        --padding-top-content: var(--bazis-padding-6x);
        --padding-bottom-content: 0;
        --padding-start-content: var(--bazis-padding-6x);
        --padding-end-content: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: transparent;
        box-shadow: none;

        .bazis-modal-wrap {
            height: auto;
            max-height: 100%;
            background: var(--background);
            box-shadow: var(--box-shadow);
        }

        .bazis-modal__header {
            position: relative;
            border-bottom: 1px solid var(--bazis-border-color);
        }

        .bazis-modal__content {
            // overflow: visible;
        }

        .bazis-modal__close {
            position: static;
        }

        .bazis-modal__title {
            margin: 0;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
        }
    }

    &.docusign {
        --height: 100%;
        --max-width: 1142px;

        .bazis-modal__content {
            display: flex;
            flex-direction: column;

            .bazis-list {
                flex-shrink: 0;
            }

            .bazis-docusign {
                height: calc(100% - 40px);
            }
        }
    }

    &_small {
    }

    @media (max-width: 576px) {
        &.signing {
            bazis-signing {
                display: contents;
            }

            .bazis-modal-wrap {
                --max-height: auto;
            }

            .bazis-modal__footer {
                bazis-toolbar {
                    flex-direction: row;

                    &::part(start),
                    &::part(end) {
                        width: calc(50% - 4px);
                    }

                    > *[slot] {
                        display: block;
                    }

                    bazis-button {
                        display: block;
                    }
                }
            }
        }
    }
}
