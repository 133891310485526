.bazis-tile {
    --padding-start: var(--bazis-padding-5x);
    --padding-end: var(--bazis-padding-5x);
    --height-header: 48px;

    &:not(.bazis-tile_full) {
        max-height: 282px;
    }

    &__header {
        --margin-start: calc(var(--bazis-margin-5x) * -1);
        --margin-end: calc(var(--bazis-margin-5x) * -1);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --padding-top: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-2x);
    }

    &__content {
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --margin-end: -19px;
    }

    bazis-tabs {
        width: 100%;
        border-bottom: 1px solid var(--bazis-border-color);
    }

    &--active {
        .bazis-tile__header {
            box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
        }
    }
}
