.bazis-loadmore-button {
    --margin-bottom: var(--bazis-margin-8x);

    bazis-button {
        font-size: 18px;

        bazis-icon {
            font-size: 24px;
        }
    }

    @media (max-width: 1024px) {
        --margin-top: var(--bazis-margin-5x);
        --margin-bottom: var(--bazis-margin-5x);
    }
}
