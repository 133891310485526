.bazis-switcher {
    /**
     * @prop --color: цвет текста чекбокса
     * @prop --color-checked: цвет текста при установленном флажке
     * @prop --color-disabled: цвет текста при отключенном флажке
     */
    @include props-font;

    --color: inherit;
    --color-checked: var(--color);
    --color-disabled: var(--color);

    bazis-toggle {
        vertical-align: middle;
    }

    &__label {
        @include font;
        vertical-align: middle;
        color: var(--color);

        .bazis-toggle--checked + & {
            color: var(--color-checked);
        }

        .bazis-toggle--disabled + & {
            color: var(--color-disabled);
        }
    }
}
