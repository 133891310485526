.bazis-stepper {
    --min-width: 213px;

    @media (max-width: 1313px) {
        > .bazis-item {
            --padding-start: 33px;
            --padding-end: var(--bazis-padding-2x);
        }

        bazis-step {
            --font-size: 13px;
        }
    }

    @media (max-width: 1250px) {
        > .bazis-item {
            min-width: 100px;
        }

        bazis-step {
            --font-size: 12px;
        }
    }
    @media (max-width: 1188px) {
        position: relative;
        left: -30px;
    }

    @media (max-width: 1160px) {
        bazis-step {
            &::part(sign) {
                display: none;
            }
        }
    }

    // где используется? использовался?
    // &_content {
    //     --min-height: 40px;
    //     --min-width: 25%;
    //     --padding-start: var(--bazis-padding-3x);
    //     --padding-end: var(--bazis-padding-3x);
    //     --padding-top: var(--bazis-padding-3x);
    //     --padding-bottom: var(--bazis-padding-3x);
    //     --background: var(--bazis-color-action);
    //     --background-disabled: var(--bazis-background-dark);
    //     --background-active: var(--bazis-color-primary);
    //     --color: var(--bazis-color-white);

    //     --width-item-after: 22px;

    //     .bazis-item {
    //         cursor: default;
    //         pointer-events: none;

    //         &::after {
    //             left: 100%;
    //         }

    //         &:last-of-type {
    //             &::after {
    //                 display: none;
    //             }
    //         }
    //     }

    //     bazis-step {
    //         --font-size: 12px;
    //         --font-weight: 500;
    //         --line-height: 14px;
    //         --color-muted: var(--bazis-text-color-secondary);
    //         --color-active: var(--bazis-color-white);
    //         justify-content: center;
    //     }
    // }
}
