.bazis-selectable-cards {
    @include props-padding;
    @include props-border;
    @include props-colors;

    --background-active: var(--background);
    --border-color-active: var(--border-color);
    --color-active: var(--color);
    --background-disabled: var(--background);
    --border-color-disabled: var(--border-color);
    --color-disabled: var(--color);

    --columns: 3;
    --gap: var(--bazis-margin-6x);
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: var(--gap);

    .bazis-selectable-card {
        position: relative;
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: -1;
    }

    &__item {
        @include padding;
        @include border;
        @include colors;

        display: block;
        height: 100%;

        &__title {
            display: flex;
            justify-content: space-between;
        }

        input:checked + & {
            background: var(--background-active);
            border-color: var(--border-color-active);
            color: var(--color-active);
        }
        input:disabled + & {
            background: var(--background-disabled);
            border-color: var(--border-color-disabled);
            color: var(--color-disabled);
        }
    }

    @media (max-width: 768px) {
        --columns: 2;
    }

    @media (max-width: 576px) {
        --columns: 1;
    }
}
