.bazis-control {

    .sl-form & {
        // TODO: может уже не нужно, переменная для всех контролов определена
        --margin-bottom: var(--bazis-margin-6x);
    }

    .sl-form_oneline &,
    .sl-toolbar_table &,
    .sl-section .bazis-grid_form > bazis-row:last-child &,
    .sl-grid_table &,
    .sl-toolbar_table-header &,
    .sl-toolbar_notification &,
    .sl-toolbar_header-dispatcher &,
    app-faq-search-block &,
    .sl-control_no-margin & {
        --margin-bottom: 0;
    }

    app-modal-permission-info .sl-form &,
    app-modal-report & {
        --margin-bottom: var(--bazis-margin-8x);
    }


    &__label {
        .sl-grid_accordion & {
            --font-size-label: 12px;
            --color-label: var(--bazis-text-color-secondary);
            font-weight: 400;
            line-height: 16px;
        }

        .sl-form_oneline & {
            line-height: 36px;
        }
    }

    @media (max-width: 992px) {
        &--activated {
            app-select-search &,
            app-search-filter & {
                --border-radius: 0;
                --border-width: 1px 0 1px 0;
                position: fixed;
                z-index: 1002;
                left: 0;
                right: 0;
                top: 0;

                .bazis-eraser {
                    display: none;
                }

                .bazis-control__label {
                    display: none;
                }

                .bazis-list_control-dd {
                    --max-height: calc(100vh - 36px - 1px);
                    --margin-top-list: 1px;
                    --padding-top-list: 0;
                    // --padding-end-list: var(--bazis-padding-5x);
                    // --padding-start-list: var(--bazis-padding-5x);
                    --border-radius-list: 0;

                    --border-width: 0 0 1px 0;
                    --border-color: var(--bazis-border-color);
                    --border-style: solid;
                    --padding-end: 0;
                    --padding-start: 0;
                    --padding-top: var(--bazis-padding-6x);
                    --padding-bottom: var(--bazis-padding-5x);

                    height: var(--max-height);

                    .bazis-item {
                        --background-hover: var(--background);

                        &.bazis-radio,
                        &.bazis-checkbox {
                            border-bottom: 1px solid var(--border-color);
                        }
                    }
                }
            }
        }
    }

    @media (max-width:768px) {
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col[sizesm='6'] & {
            --margin-bottom: var(--bazis-margin-6x);
        }
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col[sizesm='6']:nth-child(odd):last-child & {
            --margin-bottom: 0;
        }
    }

    @media (max-width: 576px) {
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col:first-child & {
            --margin-bottom: var(--bazis-margin-6x);
        }
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col:last-child &,
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col.bh-next-empty & {
            --margin-bottom: 0;
        }

        .sl-form bazis-input-map-point bazis-col:first-child & {
            --margin-bottom: var(--bazis-margin-6x);
        }
    }
}
