.sl-legal-page {
    max-width: 900px;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    app-legal-document {
        bazis-toolbar {
            align-items: flex-start;
            margin-top: var(--bazis-margin-8x);
        }
    }
}
