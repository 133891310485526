.sl-notification-block {
    color: var(--bazis-text-color);

    &__body {
        max-height: 376px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    header {
        padding: var(--bazis-padding-3x) 0 var(--bazis-padding-2x);

        bazis-toolbar {
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);

            @media (max-width: 576px) {
                .bh-line-elements {
                    --gap: var(--bazis-margin-3x);
                }
            }
        }
    }

    hr {
        --margin-top: 0;
        --margin-bottom: 0;
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }
}

.sl-notification-item {
    display: block;
    background: var(--bazis-background);
    border-bottom: 1px solid var(--bazis-border-color);
    padding: var(--bazis-padding-3x);

    .sl-announcement & {
        padding: var(--bazis-padding-5x) 0;

        &:first-child {
            border-top: 1px solid var(--bazis-border-color);
        }
    }

    &:last-of-type {
        border-bottom: 0;

        .sl-announcement & {
            border-bottom: 1px solid var(--bazis-border-color);
        }
    }

    &__checkbox {
        margin-right: var(--bazis-margin-6x);

        .sl-notification-block & {
            display: none;
        }
    }

    &__major-line {
        display: flex;
        align-items: center;

        .sl-announcement & {
            align-items: flex-start;
        }

        bazis-button {
            &:hover {
                color: var(--bazis-color-action);
            }
        }

        & + * {
            padding-left: var(--bazis-padding-7x);
            padding-top: 6px;
            padding-bottom: var(--bazis-padding-2x);

            .sl-announcement & {
                padding-left: var(--bazis-padding-8x);
                padding-top: var(--bazis-padding-3x);
                padding-bottom: 0;
            }
        }
    }

    &__action {
        flex-shrink: 0;
        width: 32px;
    }

    &__type {
        --bazis-icon-s-size: 16px;
        margin-right: var(--bazis-margin-4x);
        flex-shrink: 0;
    }

    &__message {
        position: relative;
        display: flex;
        width: 100%;
        font-size: 13px;
        line-height: 16px;

        a {
            color: var(--bazis-color-action);
        }

        &__content {
            font-size: 13px;
            line-height: 16px;

            p {
                // font-size: var(--bazis-font-size-p-minor);
                line-height: var(--bazis-line-height-p-minor);

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .sl-more-mute {
            max-height: 48px;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 28px;
                height: 20px;
                background: linear-gradient(
                    0deg,
                    rgba(var(--bazis-color-white-rgb), 1),
                    rgba(var(--bazis-color-white-rgb), 0)
                );
            }
        }
    }

    &__datetime {
        padding-left: var(--bazis-padding-3x);
        padding-right: var(--bazis-padding-2x);
        font-size: 13px;
        line-height: 20px;
        text-align: right;
        color: var(--bazis-text-color-secondary);
        white-space: nowrap;

        .sl-announcement & {
            // font-size: 13px;
            line-height: 24px;
        }

        @media (max-width: 576px) {
            padding-left: 0;
            padding-top: var(--bazis-padding-1x);
            text-align: left;
        }
    }

    &--active {
        background: var(--bazis-background-medium);
    }

    @media (max-width: 1024px) {
        &__datetime {
            padding-left: 0;
            padding-top: var(--bazis-padding-2x);
            text-align: left;
            line-height: 16px;
        }
    }

    @media (max-width: 576px) {
        &__datetime {
            .bazis-dropdown &,
            .sl-announcement & {
                padding-top: 0;
            }
        }

        &__major-line {
            flex-direction: column-reverse;
            gap: var(--bazis-padding-2x);

            .sl-announcement & {
                flex-direction: column;
            }

            .bazis-dropdown & {
                flex-direction: row;
            }
        }

        &__message {
            .sl-announcement & {
                flex-direction: column;
                gap: var(--bazis-padding-2x);
            }
        }
    }
}
