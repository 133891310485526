.bazis-options-content {
    --margin-top: 0;
    --margin-bottom: 0;
    --max-height: inherit;

    margin-top: var(--margin-top);
    margin-bottom: var(--margin-top);
    max-height: var(--max-height);
    overflow: auto;

    .bazis-dropdown__body & {
        margin-top: 0;
    }
}
