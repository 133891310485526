.sl-table_twice {
    > thead > tr > th {
        --background: transparent;
        --padding-start: var(--bazis-padding-2x);
        --padding-top: var(--bazis-padding-1x);
        --padding-end: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-1x);

        &:first-child {
            --padding-start: 0;
        }
    }

    > tbody > tr > td {
        --background: var(--bazis-background);
        --padding-start: var(--bazis-padding-4x);
        --padding-top: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-4x);

        --border-color: var(--bazis-background-accent);
        --border-width: 0 0 8px 0;
        --border-radius: var(--bazis-border-radius);
    }

    &__child {
        --margin-bottom: 0;

        td {
            --padding-top: var(--bazis-padding-1x);
            --padding-bottom: var(--bazis-padding-1x);
            --border-width: 0;

            &:first-child {
                --padding-start: 0;
            }
            &:last-child {
                --padding-end: 0;
            }

            &.bazis-td-top-zero {
                --padding-top: 0;
            }
        }
        tr {
            &:first-child td {
                --padding-top: 0;
            }
            &:last-child td {
                --padding-bottom: 0;
            }
        }
    }
}
