.bazis-vehicle-number {
    --border-color: var(--bazis-text-color);
    --background: var(--bazis-background-medium);
    --box-shadow: 0px 0px 0px 2px rgba(255,255,255);
    --color: var(--bazis-text-color);

    &_ae {
        --height: 32px;

        .bazis-vehicle-number__part {
            &_0 {
                font-size: 16px;
                line-height: 12px;
                font-weight: 400;
                width: 22px;
                text-align: center;
                margin-left: 12px;
                position: relative;
                display: inline-block;

                &:before {
                    content: '';
                    width: 22px;
                    height: 8px;
                    display: inline-block;
                    position: absolute;
                    margin-top: -10px;
                    left: 0;
                    background-image: url('/assets/icons/other/dubai.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
            &_1 {
                font-size: 24px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 3px;
                width: 80px;
                text-align: left;
                margin-left: 12px;
                padding-right: 12px;
            }

            .os-Windows & {
                &_0,
                &_1,
                &_2 {
                    padding-bottom: 2px;
                }
            }
        }
        .bazis-vehicle-number__noparts {
            margin: 0 10px;
            font-family: 'PT Sans Caption', sans-serif;
            font-size: 7px;
            line-height: 26px;
            white-space: nowrap;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            width: 108px;

            & + * {
                display: none
            }
        }
    }
}
