.bazis-collapsible-to-more {

    .bazis-page__tabs &,
    .bazis-page-header & {
        padding: 0;
        min-height: auto;

        bazis-tabs {
            --dropdown-z-index: 160;
        }
    }

    @media (max-width: 576px) {
        &_start {
            .sl-search-control {
                width: 100%;
            }
        }
    }
}
