.bh-font-normal {
    font-weight: 400;
}

.bh-font-medium {
    font-weight: 500;
}

.bh-font-bold {
    font-weight: 700;
}
