.bazis-slider {
    --indicator-margin-top: var(--bazis-margin-1x);
    --indicator-bg: var(--bazis-placeholder-color);

    --indicator-dot-width-active: 16px;
    --indicator-radius-active: 2px;

    &__indicator {
        &__item {
            transition: width 0.2s linear;
        }
    }
}
