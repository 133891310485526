bazis-chat.sl-chat_full {
    width: 100%;
}

.bazis-chat {
    &__header {
        bazis-toolbar  {
            --padding-start: var(--bazis-padding-6x);
            --padding-end: var(--bazis-padding-6x);
        }
    }

    &__control {
        bazis-button.bazis-color-primary {
            --bazis-color-base: var(--bazis-color-action) !important;
            --bazis-color-base-rgb: var(--bazis-color-action-rgb) !important;
            --bazis-color-contrast: var(--bazis-color-action-contrast) !important;
            --bazis-color-contrast-rgb: var(--bazis-color-action-contrast-rgb) !important;
            --bazis-color-shade: var(--bazis-color-action-shade) !important;
            --bazis-color-shade-grb: var(--bazis-color-action-shade-rgb) !important;
            --bazis-color-tint: var(--bazis-color-action-tint) !important;
        }
    }
}

.bazis-list_chat .bazis-item {
    --background-selected: var(--bazis-color-action);

    bazis-badge.bazis-color-warning {
        --bazis-color-base: var(--bazis-color-primary) !important;
        --bazis-color-base-rgb: var(--bazis-color-primary-rgb) !important;
        --bazis-color-contrast: var(--bazis-color-primary-contrast) !important;
        --bazis-color-contrast-rgb: var(--bazis-color-primary-contrast-rgb) !important;
        --bazis-color-shade: var(--bazis-color-primary-shade) !important;
        --bazis-color-shade-grb: var(--bazis-color-primary-shade-rgb) !important;
        --bazis-color-tint: var(--bazis-color-primary-tint) !important;
    }
}