.bazis-btn-group {
    .bazis-btn-group__item > bazis-button.bazis-btn_solid {
        .sl-dispatcher-page .sl-header & {
            --border-color: rgba(var(--bazis-background-info-rgb), 0.1);

            --background: rgba(var(--bazis-background-medium-rgb), 0.1);
            --color: var(--bazis-color-white);

            --background-activated: var(--bazis-color-primary);
            --color-activated: var(--color);

            --background-hover: var(--bazis-color-primary-shade);
            --color-hover: var(--color);

            --color-focused: var(--color);
        }
    }
}
