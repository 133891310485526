.sl-trip-ticket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--bazis-margin-2x);

    &__line {
        margin: 0 30px;
        width: 100%;
        text-align: center;

        &.bh-no-margin {
            margin: 0;
        }

        .bazis-list {
            display: inline-flex;

            &.bazis-list_short-info > .bazis-item {
                white-space: nowrap;
            }
        }

        bazis-trip-line {
            --margin-bottom: var(--bazis-margin-5x);
            --border-width: 2px;
            --border-style: solid;
            --size-point: 12px;
            --color: var(--bazis-border-color);

            &::part(start-point) {
                box-sizing: border-box;
                background: transparent;
                border: 2px solid var(--color);
            }

            &::part(line) {
                margin: 0 6px;
            }
        }
    }

    @media (max-width: 1024px) and (min-width: 992.98px) {
        &__line {
            margin: 0 var(--bazis-margin-2x);
        }
    }
}
