.bazis-collapsible-to-more {
    gap: var(--bazis-margin-4x);
    min-height: 100px;

    &__shake {
        bazis-skeleton {
            height: 48px;
        }
    }

    @media (max-width: 576px) {
        gap: 0;
    }
}
