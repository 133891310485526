.bazis-contact {
    --font-size: 13px;
    --line-height: 20px;

    &__name {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }
    &__position {
        margin-bottom: var(--bazis-margin-3x);
    }

    & + & {
        --margin-top: var(--bazis-margin-6x);
    }
}
