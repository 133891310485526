.sl-filter {
    @include props-padding;
    @include padding;

    &__base {
        @include props-margin;
        @include margin;
        display: flex;
        align-items: center;

        &__wrapper {
            display: flex;
            width: 100%;
        }

        filter {
            width: 100%;
        }

        > bazis-button {
            --multiple: 11;
            --height: 56px;
            --border-radius: 0 var(--bazis-border-radius) var(--bazis-border-radius) 0;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 1px;
            flex-shrink: 0;
        }
    }

    &__additional {
        @include props-padding;
        @include padding;
    }

    @media (max-width: 992px) {
        .sl-filter__base {
            &,
            .sl-filter__base__wrapper {
                display: block;
            }

            > bazis-button {
                --multiple: 4;
                --height: 44px;
                --border-radius: var(--bazis-border-radius);
                margin-right: var(--bazis-margin-8x);
            }
        }
    }
}

// implemented
.sl-filter {
    --padding-top: var(--bazis-padding-4x);
    --padding-bottom: var(--bazis-padding-5x);

    &__base {
        --margin-bottom: var(--bazis-margin-4x);
        background: var(--bazis-background);
        border-radius: var(--bazis-border-radius);
    }

    &__additional {
        --bazis-grid-columns: 15;
        --padding-top: calc(var(--bazis-padding-5x) - 5px);
    }

    &__secondary {
        padding-bottom: var(--padding-bottom);
    }

    .bazis-list_control-dd-wrapper {
        --padding-bottom: 0;
    }

    @media (min-width: 992.98px) {
        app-search-filter .bazis-list_control-dd {
            overflow: hidden;
        }
    }

    @media (max-width: 992px) {
        &__additional {
            --bazis-grid-columns: 10;
        }
    }

    @media (max-width: 576px) {
        &__secondary {
            padding-bottom: 0;
        }

        &__additional {
            --bazis-grid-columns: 5;
            --padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;
        }

        &-apply {
            --height: 44px;
            display: block;
            margin: var(--bazis-margin-8x) 0;
        }
    }
}
