.bazis-accordion_underline {
    &::part(body) {
        line-height: 20px;
    }
}

.bazis-accordion_clear {
    --bazis-h6-margin-major: 0;

    --padding-top-header: 0;
    --padding-bottom-header: 0;

    --padding-top-body: var(--bazis-padding-5x);
    --padding-bottom-body: 0;

    &::part(body) {
        line-height: 20px;
    }
}
