bazis-segment {
    --width-segment: 460px;
    --margin-bottom-segment: 20px;

    --background-hover: var(--bazis-color-white);
    --background-hover-opacity: 0.5;
    --border-radius: 18px;
    --height: 36px;
    --indicator-box-shadow: none;
    --indicator-color: var(--bazis-color-action);
    --padding-top: var(--bazis-padding-2x);
    --padding-end: var(--bazis-padding-4x);
    --padding-bottom: var(--bazis-padding-2x);
    --padding-start: var(--bazis-padding-4x);

    --color-hover: var(--bazis-color-action);
    --color-selected: var(--bazis-text-color-contrast);
}
