.bazis-percentage-block {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-color-hover: var(--bazis-border-color-dark);

    &__count {
        @include props-font();
        @include font();
    }

    &__percent {
        @include props-font();
        @include font();
    }

    // types
    &_medium {
    }

    // states
    &:hover {
        background: var(--background-hover);
        border-color: var(--border-color-hover);
        box-shadow: var(--box-shadow-hover);
    }
    &--active {
    }
}
