.sl-layout {
    &_flex {
        --margin-side: 44px;
        display: flex;
        align-items: flex-start;
        width: 100%;

        aside {
            flex-shrink: 0;

            &.sl-aside_left {
                width: 280px;
                margin-right: var(--margin-side);
            }

            &.sl-aside_right {
                margin-left: var(--margin-side);
            }
        }

        main {
            width: 100%;
        }

        @media screen and (max-width: 1024px) {
            aside + main {
                margin-top: var(--bazis-margin-8x);
            }
        }
    }
}
