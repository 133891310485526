bazis-card-value {
    --background: transparent;
    --background-hover: var(--bazis-background-medium);
    --background-active: var(--bazis-color-primary);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-color-active: var(--bazis-color-primary);
    --padding-start: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-4x);
    --padding-top: var(--bazis-padding-4x);
    --padding-bottom: var(--bazis-padding-4x);
    --font-size-title: 13px;
    --font-weight-title: 400;
    --line-height-title: 20px;
    --color-active-title: var(--bazis-color-primary-contrast);
    --font-size-value: 18px;
    --font-weight-value: 700;
    --line-height-value: 24px;
    --color-value-active: var(--bazis-color-primary-contrast);

    // if only card in col in row
    height: 100%;
}

.bazis-card-value__value {
    margin-bottom: var(--bazis-margin-2x);
}

.bazis-card-value__title {
    opacity: 0.6;
}
