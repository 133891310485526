.bazis-list-page {
    @media (max-width: 1024px) {
        &__tools {
            margin-bottom: var(--bazis-margin-4x);
        }
    }

    @media (max-width: 576px) {
        .bazis-list-page__title {
            .bazis-tab-status {
                --margin-top: var(--bazis-margin-3x);
                --margin-bottom: var(--bazis-margin-4x);
            }
        }

        .bazis-list-page__body {
            .bazis-block_shadow {
                --padding-start: var(--bazis-padding-5x);
                --padding-end: var(--bazis-padding-5x);

                .bazis-list_short-info > .bazis-item {
                    --padding-start: var(--bazis-padding-2x);
                }
            }
        }
    }
}
