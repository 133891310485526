.sl-search {
    &_global {
        display: flex;
        align-items: center;

        .bazis-control {
            --border-radius: 0 var(--bazis-border-radius) var(--bazis-border-radius) 0;
            --margin-bottom: 0;
            width: 100%;
        }

        bazis-button.bazis-dropdown__trigger {
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --border-radius: var(--bazis-border-radius) 0 0 var(--bazis-border-radius);
            font-size: 13px;

            + .bazis-dropdown__body {
                --right: auto;
                --left: 0;
                --width: 284px;
            }
        }

        .bazis-list_control-dd-wrapper {
            width: 100%;
            padding-top: var(--bazis-padding-2x);

            &:first-child .bazis-control {
                --border-radius: var(--bazis-border-radius);
            }
        }

        .bazis-list_control-dd {
            --margin-top-list: var(--bazis-margin-1x);
            --color: var(--bazis-text-color);
            top: 44px;

            .bazis-item:last-child {
                border-top: 1px solid var(--bazis-border-color);
            }
        }

        .bazis-dropdown + .bazis-list_control-dd-wrapper {
            color: var(--bazis-text-color);
        }
    }
}
