@import './src/bazis/styles/bazis';

@import './src/bazis/styles/themes/sw/core.scss';

@import 'typography';

/* Layout Components of alphabet */
@import 'layout/aside';
@import 'layout/footer';
@import 'layout/header';
@import 'layout/layout';
@import 'layout/section';

/* Bazis components and classes, extended */
@import 'base/main.scss';
@import 'components/web/main.scss';
@import 'components/common/main.scss';
@import 'components/form/main.scss';
@import 'components/map/map';
@import "components/table/main.scss";

@import 'components/chat/chat';

/* Project Components of alphabet */
@import 'components/project/main';

/* Filters Components */
@import 'components/filter/filter';
@import 'components/filter/control.base';
@import 'components/filter/control.additional';

/* Trip Classes */
@import 'trip/trip-tape';
@import 'trip/trip-ticket';
@import 'trip/trip';

/* Widgets */
@import 'widgets/visit-facts';

/* Pages */
@import 'pages/page';
@import 'pages/landing-page';
@import 'pages/download-apps-page';
@import 'pages/dispatcher-page';
@import 'pages/dummy-page';
@import 'pages/entrance-page';
@import 'pages/legal-page';
@import 'pages/list-page';
@import 'pages/main-page';
@import 'pages/profile-page';
@import 'pages/referral-page';
@import 'pages/service-page';
@import 'pages/simple-page';
@import 'pages/summary-page';

/* Monitoring */
@import 'monitoring/base';
@import 'monitoring/filters';
@import 'monitoring/panels';
@import 'monitoring/widgets';

/* Blocks */
@import 'blocks/announcement';
@import 'blocks/carrier-tasks-group';
@import 'blocks/faq';
@import 'blocks/lot-item';
@import 'blocks/notifications';
@import 'blocks/propositions';
@import 'blocks/other';
