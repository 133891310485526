.bh-no-margin {
    --margin-start: 0 !important;
    --margin-end: 0 !important;
    --margin-top: 0 !important;
    --margin-bottom: 0 !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

@for $i from 1 through 8 {
    .bh-margin-#{$i}x {
        --bazis-margin: var(--bazis-margin-#{$i}x);
        --margin-start: var(--bazis-margin, 12px);
        --margin-end: var(--bazis-margin, 12px);
        --margin-top: var(--bazis-margin, 12px);
        --margin-bottom: var(--bazis-margin, 12px);
        margin-left: var(--bazis-margin, 12px) !important;
        margin-right: var(--bazis-margin, 12px) !important;
        margin-top: var(--bazis-margin, 12px) !important;
        margin-bottom: var(--bazis-margin, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-margin-#{$i}x {
            margin-left: unset !important;
            margin-right: unset !important;
            -webkit-margin-start: var(--bazis-margin, 12px) !important;
            margin-inline-start: var(--bazis-margin, 12px) !important;
            -webkit-margin-end: var(--bazis-margin, 12px) !important;
            margin-inline-end: var(--bazis-margin, 12px) !important;
        }
    }

    .bh-margin-top-#{$i}x {
        --bazis-margin-top: var(--bazis-margin-#{$i}x);
        --margin-top: var(--bazis-margin-top, 12px) !important;
        margin-top: var(--bazis-margin-top, 12px) !important;
    }
    .bh-margin-start-#{$i}x {
        --bazis-margin-start: var(--bazis-margin-#{$i}x);
        --margin-start: var(--bazis-margin-start, 12px);
        margin-left: var(--bazis-margin-start, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-margin-start-#{$i}x {
            margin-left: unset !important;
            -webkit-margin-start: var(--bazis-margin-start, 12px) !important;
            margin-inline-start: var(--bazis-margin-start, 12px) !important;
        }
    }

    .bh-margin-end-#{$i}x {
        --bazis-margin-end: var(--bazis-margin-#{$i}x);
        --margin-end: var(--bazis-margin-end, 12px);
        margin-right: var(--bazis-margin-end, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-margin-end-#{$i}x {
            margin-right: unset !important;
            -webkit-margin-end: var(--bazis-margin-end, 12px) !important;
            margin-inline-end: var(--bazis-margin-end, 12px) !important;
        }
    }

    .bh-margin-bottom-#{$i}x {
        --bazis-margin-bottom: var(--bazis-margin-#{$i}x);
        --margin-bottom: var(--bazis-margin-bottom, 12px);
        margin-bottom: var(--bazis-margin-bottom, 12px) !important;
    }
    .bh-margin-vertical-#{$i}x {
        --bazis-margin-vertical: var(--bazis-margin-#{$i}x);
        --margin-top: var(--bazis-margin-vertical, 12px);
        --margin-bottom: var(--bazis-margin-vertical, 12px);
        margin-top: var(--bazis-margin-vertical, 12px) !important;
        margin-bottom: var(--bazis-margin-vertical, 12px) !important;
    }
    .bh-margin-horizontal-#{$i}x {
        --bazis-margin-horizontal: var(--bazis-margin-#{$i}x);
        --margin-start: var(--bazis-margin-horizontal, 12px);
        --margin-end: var(--bazis-margin-horizontal, 12px);
        margin-left: var(--bazis-margin-horizontal, 12px) !important;
        margin-right: var(--bazis-margin-horizontal, 12px) !important;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        .bh-margin-horizontal-#{$i}x {
            margin-left: unset !important;
            margin-right: unset !important;
            -webkit-margin-start: var(--bazis-margin-horizontal, 12px) !important;
            margin-inline-start: var(--bazis-margin-horizontal, 12px) !important;
            -webkit-margin-end: var(--bazis-margin-horizontal, 12px) !important;
            margin-inline-end: var(--bazis-margin-horizontal, 12px) !important;
        }
    }
}
@media screen and (min-width: 576.98px) {
    .bh-no-margin-sm-up {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (max-width: 576px) {
    .bh-no-margin-sm-down {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 768.98px) {
    .bh-no-margin-md-up {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (max-width: 768px) {
    .bh-no-margin-md-down {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 992.98px) {
    .bh-no-margin-lg-up {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (max-width: 992px) {
    .bh-no-margin-lg-down {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 1024.98px) {
    .bh-no-margin-xl-up {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (max-width: 1024px) {
    .bh-no-margin-xl-down {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 1280.98px) {
    .bh-no-margin-xxl-up {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (max-width: 1280px) {
    .bh-no-margin-xxl-down {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 768px) {
    .bh-no-margin-sm-md {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 992px) {
    .bh-no-margin-sm-lg {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 1024px) {
    .bh-no-margin-sm-xl {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 1280px) {
    .bh-no-margin-sm-xxl {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 992px) {
    .bh-no-margin-md-lg {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 1024px) {
    .bh-no-margin-md-xl {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 1280px) {
    .bh-no-margin-md-xxl {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 992.98px) and (max-width: 1024px) {
    .bh-no-margin-lg-xl {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 992.98px) and (max-width: 1280px) {
    .bh-no-margin-lg-xxl {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (min-width: 1024.98px) and (max-width: 1280px) {
    .bh-no-margin-xl-xxl {
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

