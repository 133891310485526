.bazis-select {
    &_secondary {
        .bazis-select__trigger {
            app-reservations & {
                text-overflow: initial;
                overflow: visible;
            }
        }
    }
}
