bazis-sign-status {
    .bazis-loader_inline {
        --height: 56px;
        --min-width: 56px;
        --spinner-size: 56px;
        padding: 0;

        .bazis-loader__spinner {
            margin: auto;
            padding: 3px;
        }
    }
}
