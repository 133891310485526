.bazis-accordion_doc {
    .bazis-file-inline bazis-link {
        font-weight: 400;
    }

    .bazis-dropdown {
        --top: 24px;
        --width: 250px;
    }

    .bh-line-elements {
        --gap: var(--bazis-margin-2x);
    }

    .bazis-btn_small {
        bazis-link[slot='icon-only'] {
            margin-left: calc(var(--bazis-margin-1x) * -1);
            margin-right: calc(var(--bazis-margin-1x) * -1);
        }
        @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
            bazis-link[slot='icon-only'] {
                margin-left: unset;
                margin-right: unset;
                -webkit-margin-start: calc(var(--bazis-margin-1x) * -1);
                margin-inline-start: calc(var(--bazis-margin-1x) * -1);
                -webkit-margin-end: calc(var(--bazis-margin-1x) * -1);
                margin-inline-end: calc(var(--bazis-margin-1x) * -1);
            }
        }
    }

    bazis-button bazis-icon {
        color: var(--bazis-text-color-secondary);
    }

    .iframe_desktop {
        display: block;

        .dt-desktop & {
            display: block;
        }
        .dt-mobile & {
            display: none;
        }
    }
    .iframe_mobile {
        display: none;

        .dt-desktop & {
            display: none;
        }
        .dt-mobile & {
            display: block;
        }
    }

    @media screen and (max-device-width: 666px) {
        .iframe_desktop {
            display: none;

            .dt-desktop & {
                display: block;
            }
            .dt-mobile & {
                display: none;
            }
        }
        .iframe_mobile {
            display: block;

            .dt-desktop & {
                display: none;
            }
            .dt-mobile & {
                display: block;
            }
        }
    }
}
