.sl-dispatcher-panel {
    flex-shrink: 0;
    position: relative;
    height: 100%;
    width: 388px; // по макетам 384, но тогда не все помещается, так как 4px съедает скролл
    margin-right: calc(var(--bazis-margin-6x) * 2);
    border-radius: var(--bazis-border-radius);
    transition: margin-left 300ms ease-in-out;
    will-change: margin-left;
    pointer-events: all;

    &--closed {
        margin-left: -376px;
    }

    &__controls {
        position: absolute;
        right: -24px;
        top: 12px;
        display: flex;
        flex-direction: column;

        bazis-button {
            --background: var(--bazis-color-white);
            margin-bottom: var(--bazis-margin-2x);
        }
    }

    &__main {
        position: relative;
        height: 100%;
        background: var(--bazis-color-white);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        border-radius: var(--bazis-border-radius);
        transition: opacity 500ms;
        will-change: opacity;

        .sl-dispatcher-panel--closed & {
            opacity: 0;
        }

        bazis-tabs {
            --height: 36px;
            --padding-top: var(--bazis-padding-3x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --padding-bottom: 0;
            --border-width: 0 0 1px 0;
            --border-color: var(--bazis-border-color);
            --border-style: solid;
            --tab-margin-end: var(--bazis-margin-4x);
            --font-size: 14px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &_list {
            @extend .sl-dispatcher-panel__content;
            height: calc(100% - 49px);
        }
    }

    .sl-toolbar_header-dispatcher {
        background: var(--bazis-color-white);
        gap: var(--bazis-margin-3x);

        &::part(start) {
            width: 100%;
        }

        bazis-input-default {
            width: 100%;

            .bazis-control,
            .bazis-control__field-group__end bazis-button.bazis-btn_clear {
                --height: 30px;
            }
        }
    }

    .bazis-list_underline {
        > .bazis-item {
            --padding-top: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);

            &:hover {
                background: var(--bazis-background-accent);
            }
        }
    }

    .sl-marker {
        width: 4px;
        height: 16px;
        margin-right: var(--bazis-margin-3x);
        background: var(--bazis-color-base);
    }

    .sl-list_trip {
        + hr {
            --margin-top: var(--bazis-margin-3x);
            --margin-bottom: var(--bazis-margin-2x);
            --border-style: dashed;
            --border-color: var(--bazis-border-color-dark);
            margin-left: var(--bazis-margin-8x);
        }

        &_time + hr,
        &_time + hr + div {
            margin-left: 84px;
        }
    }

    .bazis-dateplate__time_small {
        font-size: 13px;
    }

    app-left-task-item {
        bazis-toolbar > *[slot='end'] {
            position: relative;
            top: -10px;
        }
    }

    app-left-vehicle-item {
        bazis-toolbar > *[slot='start'] {
            align-items: center;
        }
    }

    app-left-position-item {
        position: relative;

        .sl-btn-create {
            display: none;
            position: absolute;
            bottom: 0;
            right: 16px;
        }
    }

    .bazis-item:hover > app-left-position-item {
        .sl-btn-create {
            display: block;
        }
    }
}

.sl-dispatcher-panel__card {
    header {
        position: relative;
        border-radius: var(--bazis-border-radius) var(--bazis-border-radius) 0 0;
        background: var(--bazis-background-medium);
        padding: var(--bazis-padding-4x);
        text-align: center;

        .sl-back {
            position: absolute;
            left: 16px;
            top: 16px;
        }

        h5 {
            margin: var(--bazis-margin-2x) 0 2px;
        }

        .bazis-list_short-info {
            --margin-bottom-list: var(--bazis-margin-6x);
            justify-content: center;

            > .bazis-item {
                &:last-child {
                    --padding-end: 0;
                }
            }
        }

        .sl-list_trip {
            --margin-start-list: 0;
            text-align: left;

            + hr {
                --border-style: solid;
                --margin-top: var(--bazis-margin-4x);
                --margin-bottom: var(--bazis-margin-4x);
                margin-left: 0;
            }
        }

        .bazis-vehicle-number {
            text-align: left;
        }

        .bh-line-elements {
            --gap: var(--bazis-margin-2x);
        }

        hr {
            --margin-top: var(--bazis-margin-4x);
            --margin-bottom: var(--bazis-margin-3x);
            --border-color: var(--bazis-border-color-dark);
        }
    }

    &__sign {
        --border-radius: 50%;
    }

    main {
        padding: var(--bazis-padding-8x) var(--bazis-padding-4x);
    }

    .bh-ellipsis {
        display: block;
    }

    bazis-accordion {
        --margin-bottom: var(--bazis-margin-8x);

        --padding-top-body: var(--bazis-padding-2x);

        .bazis-list_underline:not(.sl-scheduler) {
            --margin-start-list: calc(var(--bazis-margin-4x) * -1);
            --margin-end-list: calc(var(--bazis-margin-4x) * -1);

            > .bazis-item {
                &:first-child {
                    --border-width: 1px 0;
                    --margin-top: 2px;
                }
            }
        }

        .bazis-list_history:not(.sl-list_trip_time) {
            --margin-end-list: -10px;

            > .bazis-item {
                --padding-start: var(--bazis-padding-6x);

                &::before {
                    width: 7px;
                    height: 7px;
                }

                &::after {
                    top: 14px;
                    left: 4px;
                }

                .bazis-list_short-info > .bazis-item {
                    --padding-end: var(--bazis-padding-2x);
                }
            }

            hr {
                --border-style: dashed;
                --margin-top: var(--bazis-margin-2x);
                --margin-bottom: var(--bazis-margin-2x);
                margin-right: 10px;
                border-radius: 4px 4px 0 0;
            }
        }

        .bazis-list_gallery {
            flex-wrap: wrap;

            .bazis-figure {
                --width: 59px;
                --height: 59px;
                --border-radius: 2px;
                --border-width: 0;
            }

            > .bazis-item {
                --margin-end: var(--bazis-margin-2x);
                --margin-bottom: var(--bazis-margin-2x);
            }
        }

        .bazis-list_short-info:not(.bazis-list_history .bazis-list_short-info) {
            --margin-start-list: var(--bazis-margin-4x);
        }

        .bazis-dl_dot-leaders {
            --width-dt: 35%;
            --width-dd: 65%;
            --padding-start-dd: 6px;
            --margin-bottom-dt: var(--bazis-margin-3x);
            --margin-bottom-dd: var(--bazis-margin-3x);
            --color-dt: #888888;
            // --color-dot: var(--bazis-text-color-secondary);
            // --margin-bottom-dl: 0;
            dt {
                font-size: 12px;
                line-height: 16px;

                &:after {
                    top: -2px;
                }
            }

            dd {
                font-size: 13px;
                line-height: 16px;
            }
        }
    }

    .bazis-block_warning {
        --margin-top: var(--bazis-margin-3x);
        --margin-bottom: var(--bazis-margin-4x);
        font-size: 13px;
        line-height: 20px;

        > bazis-icon {
            float: none;
            margin-bottom: var(--bazis-margin-3x);
        }

        .bazis-list_short-info {
            --margin-bottom-list: var(--bazis-margin-1x);
            flex-wrap: wrap;
        }
    }

    .sl-scheduler {
        --margin-top-list: var(--bazis-margin-3x);
        --margin-bottom-list: var(--bazis-margin-6x);
        --width: auto;

        > .bazis-item {
            --padding-top: var(--bazis-padding-1x);
            --padding-bottom: var(--bazis-padding-1x);
        }
    }

    .bazis-contact {
        padding-bottom: var(--bazis-padding-3x);
        margin-bottom: var(--bazis-margin-4x);
        border-bottom: 1px solid var(--bazis-border-color);

        &:first-of-type {
            --margin-top: var(--bazis-margin-3x);
        }

        + .bazis-contact {
            --margin-top: 0;
        }
    }
}
