.sl-dispatcher-map {
    position: relative;
    width: 100%;
    height: 100%;

    .leaflet-popup {
        --min-height: 120px;
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-top: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-4x);

        &-content-wrapper {
            max-height: 240px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .bazis-list_underline {
            & > .bazis-item:last-child {
                --padding-bottom: 0;
                --margin-bottom: 0;
                --border-width: 0;
            }
        }

        .sl-list_trip {
            --margin-start-list: 0;
        }
    }
}

.sl-dispatcher-on-map-layer {
    position: absolute;
    z-index: 40;
    left: 8px;
    right: 51px;
    top: 8px;
    bottom: 8px;
    pointer-events: none;
}

.sl-dispatcher-map-legend {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    float: left;
    pointer-events: all;

    bazis-toolbar {
        --padding-top: var(--bazis-padding-3x);
        --padding-bottom: var(--bazis-padding-1x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
    }

    .bazis-list {
        --padding-top-list: var(--bazis-padding-4x);
        --padding-bottom-list: var(--bazis-padding-5x);
        --padding-start-list: var(--bazis-padding-4x);
        --padding-end-list: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-3x);

        .bazis-item {
            display: flex;
            align-items: center;
        }

        bazis-icon {
            margin-right: var(--bazis-margin-3x);
        }
    }
}
