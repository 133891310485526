.bazis-color-primary {
    --bazis-color-base: var(--bazis-color-primary) !important;
    --bazis-color-base-rgb: var(--bazis-color-primary-rgb) !important;
    --bazis-color-contrast: var(--bazis-color-primary-contrast) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-primary-contrast-rgb) !important;
    --bazis-color-shade: var(--bazis-color-primary-shade) !important;
    --bazis-color-shade-rgb: var(--bazis-color-primary-shade-grb) !important;
    --bazis-color-tint: var(--bazis-color-primary-tint) !important;
}

.bazis-color-secondary {
    --bazis-color-base: var(--bazis-color-secondary) !important;
    --bazis-color-base-rgb: var(--bazis-color-secondary-rgb);
    --bazis-color-contrast: var(--bazis-color-secondary-contrast) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-secondary-contrast-rgb) !important;
    --bazis-color-shade: var(--bazis-color-secondary-shade) !important;
    --bazis-color-shade-rgb: var(--bazis-color-secondary-shade-rgb) !important;
    --bazis-color-tint: var(--bazis-color-secondary-tint) !important;
}

.bazis-color-tertiary {
    --bazis-color-base: var(--bazis-color-tertiary) !important;
    --bazis-color-base-rgb: var(--bazis-color-tertiary-rgb) !important;
    --bazis-color-contrast: var(--bazis-color-tertiary-contrast) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-tertiary-contrast-rgb) !important;
    --bazis-color-shade: var(--bazis-color-tertiary-shade) !important;
    --bazis-color-shade-rgb: var(--bazis-color-tertiary-shade-grb) !important;
    --bazis-color-tint: var(--bazis-color-tertiary-tint) !important;
}

.bazis-color-action {
    --bazis-color-base: var(--bazis-color-action) !important;
    --bazis-color-base-rgb: var(--bazis-color-action-rgb) !important;
    --bazis-color-contrast: var(--bazis-color-action-contrast) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-action-contrast-rgb) !important;
    --bazis-color-shade: var(--bazis-color-action-shade) !important;
    --bazis-color-shade-grb: var(--bazis-color-action-shade-rgb) !important;
    --bazis-color-tint: var(--bazis-color-action-tint) !important;
}

.bazis-color-info {
    --bazis-color-base: var(--bazis-color-info) !important;
    --bazis-color-base-rgb: var(--bazis-color-info-rgb) !important;
    --bazis-color-contrast: var(--bazis-color-info-contrast) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-info-contrast-rgb) !important;
    --bazis-color-shade: var(--bazis-color-info-shade) !important;
    --bazis-color-shade-grb: var(--bazis-color-info-shade-rgb) !important;
    --bazis-color-tint: var(--bazis-color-info-tint) !important;
}

.bazis-color-success {
    --bazis-color-base: var(--bazis-color-success) !important;
    --bazis-color-base-rgb: var(--bazis-color-success-rgb) !important;
    --bazis-color-contrast: var(--bazis-color-success-contrast) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-success-contrast-rgb) !important;
    --bazis-color-shade: var(--bazis-color-success-shade) !important;
    --bazis-color-shade-rgb: var(--bazis-color-success-shade-rgb) !important;
    --bazis-color-tint: var(--bazis-color-success-tint) !important;
}

.bazis-color-warning {
    --bazis-color-base: var(--bazis-color-warning) !important;
    --bazis-color-base-rgb: var(--bazis-color-warning-rgb) !important;
    --bazis-color-contrast: var(--bazis-color-warning-contrast) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-warning-contrast-rgb) !important;
    --bazis-color-shade: var(--bazis-color-warning-shade) !important;
    --bazis-color-shade-rgb: var(--bazis-color-warning-shade-rgb) !important;
    --bazis-color-tint: var(--bazis-color-warning-tint) !important;
}

.bazis-color-danger {
    --bazis-color-base: var(--bazis-color-danger) !important;
    --bazis-color-base-rgb: var(--bazis-color-danger-rgb) !important;
    --bazis-color-contrast: var(--bazis-color-danger-contrast) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-danger-contrast-rgb) !important;
    --bazis-color-shade: var(--bazis-color-danger-shade) !important;
    --bazis-color-shade-rgb: var(--bazis-color-danger-shade-rgb) !important;
    --bazis-color-tint: var(--bazis-color-danger-tint) !important;
}

.bazis-color-light {
    --bazis-color-base: var(--bazis-color-light, #f4f5f8) !important;
    --bazis-color-base-rgb: var(--bazis-color-light-rgb, 244, 245, 248) !important;
    --bazis-color-contrast: var(--bazis-color-light-contrast, #000) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-light-contrast-rgb, 0, 0, 0) !important;
    --bazis-color-shade: var(--bazis-color-light-shade, #d7d8da) !important;
    --bazis-color-shade-rgb: var(--bazis-color-light-shade-rgb, 215, 216, 218) !important;
    --bazis-color-tint: var(--bazis-color-light-tint, #f5f6f9) !important;
}

.bazis-color-medium {
    --bazis-color-base: var(--bazis-color-medium, #92949c) !important;
    --bazis-color-base-rgb: var(--bazis-color-medium-rgb, 146, 148, 156) !important;
    --bazis-color-contrast: var(--bazis-color-medium-contrast, #fff) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-medium-contrast-rgb, 255, 255, 255) !important;
    --bazis-color-shade: var(--bazis-color-medium-shade, #808289) !important;
    --bazis-color-shade-rgb: var(--bazis-color-medium-shade-rgb, 128, 130, 137) !important;
    --bazis-color-tint: var(--bazis-color-medium-tint, #9d9fa6) !important;
}

.bazis-color-dark {
    --bazis-color-base: var(--bazis-color-dark, #111214) !important;
    --bazis-color-base-rgb: var(--bazis-color-dark-rgb, 17, 18, 20) !important;
    --bazis-color-contrast: var(--bazis-color-dark-contrast, #fff) !important;
    --bazis-color-contrast-rgb: var(--bazis-color-dark-contrast-rgb, 255, 255, 255) !important;
    --bazis-color-shade: var(--bazis-color-dark-shade, #0f1012) !important;
    --bazis-color-shade-rgb: var(--bazis-color-dark-shade-rgb, 15, 16, 18) !important;
    --bazis-color-tint: var(--bazis-color-dark-tint, #292a2c) !important;
}

.bazis-bg-base {
    --bazis-bg-base: var(--bazis-background) !important;
    --bazis-bg-base-rgb: var(--bazis-background-rgb) !important;
}

.bazis-bg-light {
    --bazis-bg-base: var(--bazis-background-light, var(--bazis-background)) !important;
    --bazis-bg-base-rgb: var(--bazis-background-light-rgb, var(--bazis-background-rgb)) !important;
}

.bazis-bg-medium {
    --bazis-bg-base: var(--bazis-background-medium) !important;
    --bazis-bg-base-rgb: var(--bazis-background-medium-rgb) !important;
}

.bazis-bg-dark {
    --bazis-bg-base: var(--bazis-background-dark) !important;
    --bazis-bg-base-rgb: var(--bazis-background-dark-rgb) !important;
}

.bazis-bg-accent {
    --bazis-bg-base: var(--bazis-background-accent) !important;
    --bazis-bg-base-rgb: var(--bazis-background-accent-rgb) !important;
}

.bazis-bg-info-secondary {
    --bazis-bg-base: var(--bazis-background-info-secondary) !important;
    --bazis-bg-base-rgb: var(--bazis-background-info-secondary-rgb) !important;
}

.bazis-bg-header {
    --bazis-bg-base: var(--bazis-header-background) !important;
    --bazis-bg-base-rgb: var(--bazis-header-background-rgb) !important;
}

.bazis-bg-footer {
    --bazis-bg-base: var(--bazis-footer-background) !important;
    --bazis-bg-base-rgb: var(--bazis-footer-background-rgb) !important;
}
