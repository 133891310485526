.bazis-select-date-control {
    --width: 275px;

    .bazis-dropdown__body {
        --top: calc(var(--height) + 3px);
        --right: 0;
        --left: auto;
        --width: 360px;
        --padding-start: var(--bazis-padding-3x);
        --padding-end: var(--bazis-padding-3x);
        --padding-top: var(--bazis-padding-3x);
        --padding-bottom: var(--bazis-padding-3x);
        --background: var(--bazis-background);
        --border-width: 1px;
        --border-color: var(--bazis-background-medium);

        .bazis-control {
            --padding-field-end: 0;
        }

        .bazis-grid_form > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: var(--bazis-padding-1x);
            --bazis-grid-column-padding-end: var(--bazis-padding-1x);
            --bazis-grid-column-padding-start-xs: var(--bazis-padding-1x);
            --bazis-grid-column-padding-end-xs: var(--bazis-padding-1x);

            &:first-child {
                --bazis-grid-column-padding-start: 0;
                --bazis-grid-column-padding-start-xs: 0;
            }
            &:last-child {
                --bazis-grid-column-padding-end: 0;
                --bazis-grid-column-padding-end-xs: 0;
            }
        }

        .bazis-control__field-group__end bazis-button {
            --padding-start: var(--bazis-padding-2x);
            --padding-end: var(--bazis-padding-2x);
        }

        .bazis-eraser {
            margin-right: calc(var(--padding-end) * -1);
        }
    }
}
