dl {
    &.bazis-dl_inline {
        .sl-section &:last-child {
            dt:last-of-type,
            dd:last-of-type {
                --margin-bottom-dt: 0;
                --margin-bottom-dd: 0;
            }
        }

        app-profile-okveds & {
            --width-dt: 96px;
            --color-dt: var(--bazis-text-color);
            --margin-bottom-dt: var(--bazis-margin-2x);
            --margin-bottom-dd: var(--bazis-margin-2x);
        }

        .sl-block_long-card .bazis-block__content_main & {
            --margin-bottom-dt: var(--bazis-margin-2x);
            --margin-bottom-dd: var(--bazis-margin-2x);
        }
    }

    @media (max-width: 576px) {
        &.bazis-dl_inline {
            app-profile-okveds & {
                --margin-bottom-dt: var(--bazis-margin-1x);
                --margin-bottom-dd: var(--bazis-margin-3x);
            }
        }
    }
}
