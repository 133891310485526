.bazis-browse-default {
    .sl-grid_accordion > bazis-row:last-child > bazis-col &,
    .sl-profile-page .bazis-grid_form > bazis-row:last-child > bazis-col & {
        --margin-bottom: 0;
    }

    @media (max-width: 576px) {
        .sl-grid_accordion > bazis-row:last-child > bazis-col &,
        .sl-profile-page .bazis-grid_form > bazis-row:last-child > bazis-col & {
            --margin-bottom: var(--bazis-margin-6x);
        }

        .sl-grid_accordion > bazis-row:last-child > bazis-col:last-child &,
        .sl-profile-page .bazis-grid_form > bazis-row:last-child > bazis-col:last-child & {
            --margin-bottom: 0;
        }
    }
}

.sl-grid_accordion,
.sl-profile-page .bazis-grid_form {
    bazis-row:last-child > bazis-col[sizesm='6']:nth-child(3) {
        .bazis-browse-default {
            --margin-top: var(--bazis-margin-6x);
        }
    }
}
