.sl-contacts-group {
    margin-bottom: var(--bazis-margin-8x);

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: var(--bazis-margin-4x);
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;

        bazis-button {
            margin-right: var(--bazis-margin-3x);

            bazis-icon[slot='icon-only'] {
                margin-left: -6px;
                margin-right: -6px;
            }

            @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
                bazis-icon[slot='icon-only'] {
                    margin-left: unset;
                    margin-right: unset;
                    margin-inline-start: -6px;
                    margin-inline-end: -6px;
                }
            }
        }
    }
}
