.bazis-page {
    @include props-padding;
    @include padding;

    &-header {
        &--scrolling.bh-sticky {
            border-bottom: 0;
            z-index: 160;
        }
    }

    &__tabs {}
}
