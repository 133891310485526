.bazis-tag {
    --height: 36px;
    --height-s: calc(var(--height) - var(--bazis-padding-4x));
    --height-l: calc(var(--height) + var(--bazis-padding-8x));
    --background: var(--bazis-background-medium);
    --border-radius: var(--bazis-border-radius);
    --padding-start: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-3x);
    --color: var(--bazis-text-color);

    // types
    &_large {
        // 44px
        font-size: 14px;
        letter-spacing: 0.5px;
    }
}
