.bazis-block {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    &__content {
        &_start,
        &_main,
        &_end {
            @include props-block-model;
            @include props-width;
            @include props-margin;

            @include block-model;
            @include width;
            @include margin;

            position: relative;
        }
    }

    &__footer,
    &__header {
        @include props-block-model;
        @include props-width;
        @include props-margin;

        @include block-model;
        @include width;
        @include margin;
    }

    // types
    &_hoverable {
        &:hover {
            background: var(--background-hover);
            border-color: var(--border-color-hover);
            box-shadow: var(--box-shadow-hover);
        }
    }
    &_outline {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --border-color-hover: var(--bazis-border-color-dark);
    }
    &_shadow {
        --box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
        --box-shadow-hover: 0px 10px 20px -10px rgba(144, 150, 162, 0.2);
    }
    &_editable {
        @extend .bazis-block_hoverable;
        display: block;
        position: relative;
        cursor: pointer;

        > bazis-icon {
            position: absolute;
            right: 16px;
            bottom: 10px;
            visibility: hidden;
            color: var(--bazis-color-action);
        }

        &:hover {
            bazis-icon {
                visibility: visible;
            }
        }
    }
    &_on-map {
        position: absolute;
        z-index: 150;
        top: var(--bazis-margin-4x);
        left: var(--bazis-margin-4x);
    }
    &_popup {
        --margin-bottom: 0;
        position: absolute;
        z-index: 150;
        bottom: 0;
        left: 0;

        &.bazis-block_shadow {
            --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
        }
    }
    &_search {
        @extend .bazis-block_popup;

        --max-width: 612px;
        --padding-top: var(--bazis-padding-5x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-5x);

        .bazis-block__footer {
            --margin-bottom: calc(var(--bazis-margin-5x) * -1);
            --margin-start: calc(var(--bazis-margin-5x) * -1);
            --margin-end: calc(var(--bazis-margin-5x) * -1);
            --padding-top: var(--bazis-padding-3x);
            --padding-bottom: var(--bazis-padding-3x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
        }

        .bazis-empty & {
            text-align: left;
        }
    }
    &_accent {
    }
    &_warning {
    }
    &_secondary {
    }

    // mini, large

    // states
    &--active {
    }

    &.bh-bg {
        --background: var(--bazis-bg-base);
    }
}
