.bazis-mask {
    position: absolute;
    width: 100%;
    opacity: .4;
    pointer-events: none;
    border-color: transparent!important;
    top: 0;
    left: 0;
    outline: none;
}
