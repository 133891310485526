.bazis-pane {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    --height-header: initial;

    height: 100%;

    &__footer,
    &__header {
        @include props-block-model;
        @include props-margin;

        @include block-model;
        @include margin;
    }

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        height: var(--height-header);

        bazis-tabs {
            --height: var(--height-header);
        }

        &__btns {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 11;

            display: flex;
            align-items: center;
        }
    }

    &__content {
        height: calc(100% - var(--height-header));
    }

    // state
    &--expanded {
    }
}
