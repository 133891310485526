.bazis-modal {
    &__content {
        .sl-section:first-child {
            --margin-top: 0;
        }
    }

    // types
    &.map {
        .bazis-map-wrapper {
            .sl-open-btn {
                display: none;
            }
        }
    }

    &.gallery {
        app-plot-visit-facts,
        #plotCanvas {
            width: 100%;
            height: 100%;
        }

        .plot-container {
            width: 100%;
            height: 100%;
            overflow: hidden;

            > .svg-container {
                margin: auto;
            }
        }
    }

    @media (max-width: 576px) {
        app-modal-signature {
            .bazis-modal__footer {
                bazis-button {
                    display: block;
                }
            }
        }

        &.video {
            app-video-modal {
                display: flex;
                flex-direction: column;
                height: 100vh;
                justify-content: center;
            }
        }

        &.map {
            .sl-desktop-content {
                display: none;
            }

            .sl-mobile-content {
                .bazis-control {
                    --height: 34px;
                    --control-width: calc(100% - var(--bazis-margin-2x));
                    margin-right: var(--bazis-margin-2x);
                }

                .bazis-list_short-info > .bazis-item {
                    --font-size: 16px;
                    --line-height: 24px;
                    --font-weight: 500;
                    --color: var(--bazis-text-color);
                }
            }
        }
    }
}

// types
.sl-modal {
    &_ossig-confirm {
        --min-height: 565px;
        --max-height: 658px;
        --max-width: 780px;

        --padding-top-header: var(--bazis-padding-5x);
        --padding-bottom-header: var(--bazis-padding-6x);
    }

    &_xs {
        --min-height: 401px;
        --max-height: 430px;
        --max-width: 664px;
        --padding-bottom-header: 0;
        --padding-top-content: 0;
        --padding-bottom-content: 0;

        &.sl-modal_help {
            --max-height: 475px;
        }
    }

    &_role {
        --max-width: 640px;
        --max-height: 599px;
        --padding-top-content: var(--bazis-padding-5x);
    }

    &_filter {
        --max-width: 512px;
        --max-height: 568px;

        --padding-start-header: var(--bazis-padding-6x);
        --padding-end-header: var(--bazis-padding-6x);

        --padding-top-content: var(--bazis-padding-6x);
        --padding-bottom-content: var(--bazis-padding-6x);
        --padding-start-content: var(--bazis-padding-6x);
        --padding-end-content: var(--bazis-padding-6x);

        --padding-start-footer: var(--bazis-padding-6x);
        --padding-end-footer: var(--bazis-padding-6x);
        --padding-top-footer: var(--bazis-padding-5x);
        --padding-bottom-footer: var(--bazis-padding-5x);

        .bazis-control {
            --margin-bottom: var(--bazis-margin-5x);
        }
    }

    &_form {
        --max-width: 640px;
        --min-height: 552px;
        --height: 552px;
        --padding-bottom-content: var(--bazis-padding-6x);
        --padding-top-footer: var(--bazis-padding-5x);
        --padding-bottom-footer: var(--bazis-padding-5x);

        &_large {
            --max-width: 700px;
            --min-height: 720px;
            --height: 720px;
        }

        &_xs {
            --min-height: 384px;
            --height: 384px;
        }

        h3 {
            --bazis-h3-margin: 0;
        }
    }
}
