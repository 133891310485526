.bazis-paginator {
    &_secondary {
        .sl-announcement & {
            margin-top: 40px;
        }
    }

    @media (max-width: 576px) {
        &,
        &_secondary {
            .sl-paginator__total {
                line-height: 36px;
            }
        }
    }
}
