.bazis-grid_form {
    > bazis-row > bazis-col {
        --bazis-grid-column-padding-start-sm: var(--bazis-padding-3x);
        --bazis-grid-column-padding-end-sm: var(--bazis-padding-3x);
        --bazis-grid-column-padding-start-md: var(--bazis-padding-3x);
        --bazis-grid-column-padding-end-md: var(--bazis-padding-3x);
        --bazis-grid-column-padding-top: 0;
        --bazis-grid-column-padding-bottom: 0;

        --bazis-grid-column-padding-start-xs: 0;
        --bazis-grid-column-padding-end-xs: 0;

        &[sizesm='6']:nth-child(odd) {
            --bazis-grid-column-padding-start-sm: 0;

            &:last-child {
                --bazis-grid-column-padding-end-sm: var(--bazis-padding-3x);
            }
        }
        &[sizesm='6']:nth-child(even) {
            --bazis-grid-column-padding-end-sm: 0;
        }

        &:first-child {
            --bazis-grid-column-padding-start: 0;
            --bazis-grid-column-padding-start-xs: 0;
            --bazis-grid-column-padding-start-sm: 0;
            --bazis-grid-column-padding-start-md: 0;
            --bazis-grid-column-padding-start-xl: 0;
        }
        &:last-child {
            --bazis-grid-column-padding-end: 0;
            --bazis-grid-column-padding-end-xs: 0;
            --bazis-grid-column-padding-end-sm: 0;
            --bazis-grid-column-padding-end-md: 0;
            --bazis-grid-column-padding-end-xl: 0;
        }
    }
}

.bazis-grid_medium {
    > bazis-row {
        > bazis-col {
            --bazis-grid-column-padding-start: var(--bazis-padding-3x);
            --bazis-grid-column-padding-end: var(--bazis-padding-3x);
            --bazis-grid-column-padding-top: var(--bazis-padding-3x);
            --bazis-grid-column-padding-bottom: var(--bazis-padding-3x);

            &:first-child {
                --bazis-grid-column-padding-start: 0;
                --bazis-grid-column-padding-start-xs: 0;
                --bazis-grid-column-padding-start-sm: 0;
                --bazis-grid-column-padding-start-xl: 0;
            }
            &:last-child {
                --bazis-grid-column-padding-end: 0;
                --bazis-grid-column-padding-end-xs: 0;
                --bazis-grid-column-padding-end-sm: 0;
                --bazis-grid-column-padding-end-xl: 0;
            }
        }

        &:first-child > bazis-col {
            --bazis-grid-column-padding-top: 0;
        }
        &:last-child > bazis-col {
            --bazis-grid-column-padding-bottom: 0;
        }
    }
}

.bazis-grid_mini {
    > bazis-row > bazis-col {
        --bazis-grid-column-padding-start: var(--bazis-padding-2x);
        --bazis-grid-column-padding-end: var(--bazis-padding-2x);

        &:first-child {
            --bazis-grid-column-padding-start: 0;
            --bazis-grid-column-padding-start-xs: 0;
            --bazis-grid-column-padding-start-sm: 0;
            --bazis-grid-column-padding-start-xl: 0;
        }
        &:last-child {
            --bazis-grid-column-padding-end: 0;
            --bazis-grid-column-padding-end-xs: 0;
            --bazis-grid-column-padding-end-sm: 0;
            --bazis-grid-column-padding-end-xl: 0;
        }
    }
}

bazis-input-timeinterval bazis-grid {
    > bazis-row {
        > bazis-col {
            --bazis-grid-column-padding-start-sm: var(--bazis-padding-2x);
            --bazis-grid-column-padding-end-sm: var(--bazis-padding-2x);
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;

            --bazis-grid-column-padding-start-xs: 0;
            --bazis-grid-column-padding-end-xs: 0;

            .bazis-modal & {
                --bazis-grid-column-padding-start-sm: var(--bazis-padding-3x);
                --bazis-grid-column-padding-end-sm: var(--bazis-padding-3x);
            }

            &:first-child {
                --bazis-grid-column-padding-start: 0;
                --bazis-grid-column-padding-start-xs: 0;
                --bazis-grid-column-padding-start-sm: 0;
                --bazis-grid-column-padding-start-xl: 0;
            }
            &:last-child {
                --bazis-grid-column-padding-end: 0;
                --bazis-grid-column-padding-end-xs: 0;
                --bazis-grid-column-padding-end-sm: 0;
                --bazis-grid-column-padding-end-xl: 0;
            }
        }
    }
}

bazis-grid {
    .bazis-pane & {
        --bazis-grid-padding: var(--bazis-padding-2x);

        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: var(--bazis-padding-2x);
            --bazis-grid-column-padding-end: var(--bazis-padding-2x);
        }
    }
}

bazis-row {
    &.bazis-list_vline {
        > bazis-col.bazis-item::before {
            --padding-start: var(--bazis-padding-4x);
        }

        &:first-child {
            > bazis-col {
                --bazis-grid-column-padding-top: 0;
            }
        }
        &:last-child {
            > bazis-col {
                --bazis-grid-column-padding-bottom: 0;
            }
        }
    }
}
