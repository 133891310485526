.sl-dispatcher-widget {
    --padding-top: var(--bazis-padding-3x);
    --padding-bottom: var(--bazis-padding-3x);
    --padding-start: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-4x);
    --margin-bottom: 0;
    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    --width: 332px;
    float: left;
    margin-right: var(--bazis-margin-6x);
    pointer-events: all;

    .bazis-list_between {
        font-size: 12px;
        line-height: 16px;

        bazis-badge {
            --margin-start: 0;
            --margin-end: var(--bazis-margin-2x);
        }

        .bazis-item {
            > *:last-child {
                display: flex;
                justify-content: space-between;
                width: 90px;
                text-align: right;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .bazis-percentage-bar__part {
        position: static;
    }
}
