.bazis-contacts-control {
    &__group {
        --margin-bottom: 0;
        margin-bottom: var(--margin-bottom);

        &:last-of-type {
            margin-bottom: 0;
        }

        + bazis-button,
        + bazis-link {
            display: inline-block;
            margin-top: var(--margin-bottom);
        }
    }
}
