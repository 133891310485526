.bazis-modal {
    --background: var(--bazis-color-white);
    --border-radius: var(--bazis-border-radius);
    --box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
    --width: 100%;
    --max-height: calc(100vh - 80px);
    --max-width: 976px;
    --min-height: 62.5vh; //640px;

    --top-close: var(--bazis-padding-5x);
    --right-close: var(--bazis-padding-5x);

    --padding-start-header: 40px;
    --padding-end-header: 40px;
    --padding-top-header: var(--bazis-padding-5x);
    --padding-bottom-header: var(--bazis-padding-5x);

    --padding-start-content: 40px;
    --padding-end-content: 40px;
    --padding-top-content: var(--bazis-padding-8x);
    --padding-bottom-content: var(--bazis-padding-8x);

    --padding-start-footer: 40px;
    --padding-end-footer: 40px;
    --padding-top-footer: var(--bazis-padding-5x);
    --padding-bottom-footer: var(--bazis-padding-5x);

    z-index: 1100; // TODO: fix for the situation when confirmation modal must be over form modal

    + .bazis-backdrop {
        --backdrop-opacity: 0.6;
        --opacity: var(--backdrop-opacity);
    }

    &,
    &-wrap {
        &--scrolling {
            .bazis-modal__header {
                position: relative;
                height: 100%;
                max-height: 119px; // 76px

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 76px;
                    box-shadow: var(--box-shadow);
                }

                h1 {
                    padding-top: 0;
                    font-size: 28px;
                    line-height: 36px;
                }
            }
        }
    }

    &__header {
        h1 {
            padding-bottom: 0;
            padding-top: 27px;
            transition: padding-top 150ms, font-size 150ms, line-height 150ms;
            --bazis-h1-margin: 0;
        }

        h3 {
            --bazis-h3-margin: 0;
        }
    }

    &__content {
    }

    &__footer {
        box-shadow: 0px -15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
    }

    .bazis-alert__wrapper {
        --box-shadow: none;
    }

    // types
    &.map {
        --height: 640px;

        h4 {
            margin-right: var(--bazis-margin-4x);
        }

        .bazis-map-wrapper {
            --height: calc(80% - var(--margin-bottom));

            .bazis-map {
                border-radius: 0 0 var(--border-radius) var(--border-radius);
            }
        }
    }

    &.gallery {
        --background: transparent;
        --top-close: 40px;
        --right-close: 40px;

        + .bazis-backdrop {
            --backdrop-opacity: 0.9;
            --opacity: var(--backdrop-opacity);
        }

        .bazis-loader_inline {
            --height: auto;
        }
    }

    &.video {
        --background: transparent;
        --top-close: 20px;
        --right-close: 78px;

        + .bazis-backdrop {
            --backdrop-opacity: 0.9;
            --opacity: var(--backdrop-opacity);
        }

        .bazis-modal__content {
            --padding-start-content: 0;
            --padding-end-content: 0;
            --padding-top-content: 0;
            --padding-bottom-content: 0;
        }
    }

    &.mobile-bottom {
        --width: calc(100% - 16px);
        --min-height: 0;

        --padding-start-header: var(--bazis-padding-5x);
        --padding-end-header: var(--bazis-padding-5x);
        --padding-top-header: var(--bazis-padding-5x);
        --padding-bottom-header: var(--bazis-padding-5x);

        --padding-top-content: 0;
        --padding-bottom-content: var(--bazis-padding-5x);
        --padding-start-content: var(--bazis-padding-5x);
        --padding-end-content: var(--bazis-padding-5x);

        --padding-start-footer: var(--bazis-padding-5x);
        --padding-end-footer: var(--bazis-padding-5x);
        --padding-top-footer: var(--bazis-padding-5x);
        --padding-bottom-footer: var(--bazis-padding-5x);
        bottom: 0;
        top: auto;
        margin: var(--bazis-padding-2x);

        .bazis-list_underline {
            --border-style: dashed;
            --padding-top-list: 0;
            --padding-bottom-list: 0;

            --padding-bottom: var(--bazis-padding-4x);
            --padding-top: 0;
            --margin-bottom: var(--bazis-margin-4x);

            > .bazis-item {
                &:last-child {
                    border: none;
                }
            }
        }

        .bazis-control & {
            --padding-top-content: var(--bazis-padding-5x);

            .bazis-control__label {
                --font-size-label: 18px;
                line-height: 24px;
                color: var(--bazis-text-color);
            }

            .bazis-radio__label::before {
                margin-left: 0;
            }

            bazis-button {
                --height: 44px;
                margin: 40px 0 var(--bazis-margin-5x);
            }
        }
    }

    &.files {
        .bazis-chat__modal-content {
            max-height: 524px;
            overflow: auto;
        }
    }

    &_small {
        --max-height: 580px;
        --max-width: 780px;

        --padding-top-header: var(--bazis-padding-5x);
        --padding-bottom-header: var(--bazis-padding-4x);
        --padding-top-content: var(--bazis-padding-4x);

        &.map {
            --max-height: 490px;
            --max-width: 976px;

            --padding-top-content: var(--bazis-padding-8x);
        }
    }

    @media (max-width: 992px) {
        &:not(.fullscreen, .mobile-bottom, .dummy) {
            --width: calc(100% - 72px);
            --min-height: auto;
            // --max-height: 100%;  // пропадает скролл у модалки подписи

            margin: 40px calc(var(--bazis-margin-3x) * 3);
            bottom: auto;
        }

        .bazis-modal__footer {
            .bazis-btn_clear {
                margin-left: calc(var(--bazis-margin-4x) * -1);
                margin-right: calc(var(--bazis-margin-4x) * -1);
            }
        }
    }

    @media (min-width: 576.98px) and (max-width: 992px) {
        &:not(.fullscreen, .mobile-bottom, .dummy) {
            .bazis-modal-wrap {
                max-height: var(--max-height);
            }
        }
    }

    @media (max-width: 576px) {
        &:not(.mobile-bottom, .dummy) {
            --min-height: 100vh;
            --width: 100%;
            --border-radius: 0;
            --box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);

            --top-close: 10px;
            --right-close: var(--bazis-padding-5x);

            --padding-start-header: var(--bazis-padding-5x);
            --padding-end-header: calc(
                var(--bazis-padding-5x) + var(--bazis-padding-3x) * 3 + var(--bazis-padding-6x)
            );
            --padding-top-header: var(--bazis-padding-4x);
            --padding-bottom-header: var(--bazis-padding-4x);

            --padding-top-content: var(--bazis-padding-5x);
            --padding-start-content: var(--bazis-padding-5x);
            --padding-end-content: var(--bazis-padding-5x);

            --padding-start-footer: var(--bazis-padding-5x);
            --padding-end-footer: var(--bazis-padding-5x);
            --padding-top-footer: var(--bazis-padding-5x);
            --padding-bottom-footer: var(--bazis-padding-5x);

            margin: 0;
            bottom: 0;

            .bazis-modal__header {
                border-bottom: 1px solid var(--bazis-border-color);
                box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
            }
            .bazis-modal__footer {
                border-top: 1px solid var(--bazis-border-color);
            }
        }

        .bazis-modal__header {
            h1 {
                --bazis-h1-margin: 0;
                --bazis-font-size-h1: 18px;
                --bazis-line-height-h1: 24px;
                padding-top: 0;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            h4 {
                --bazis-h4-margin: 0;
                --bazis-font-size-h4: 18px;
                --bazis-line-height-h4: 24px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .bazis-modal__footer {
            bazis-button {
                display: block;
            }

            bazis-toolbar {
                flex-direction: column;
                gap: var(--bazis-padding-4x);
                width: 100%;

                &::part(start),
                &::part(end) {
                    gap: var(--bazis-padding-4x);
                    flex-direction: column;
                    width: 100%;
                }
                .bh-line-elements {
                    flex-direction: column;
                    width: 100%;
                }
            }
        }

        &.video {
            --top-close: 8px;
            --right-close: 8px;
            --left-close: auto;
            padding: 0;

            .bazis-modal__close {
                z-index: 100;
            }

            .bazis-modal__content {
                position: relative;
                height: 0;
                padding-top: 56.25%; /* 16:9 */
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;

                iframe {
                    // height: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        &.map {
            --padding-top-content: 0;
            --padding-bottom-content: 0;
            --padding-start-content: 0;
            --padding-end-content: 0;

            --top-close: 8px;
            --right-close: 8px;
            --left-close: auto;

            .bazis-map-wrapper {
                --height: 100vh;
            }
        }
    }
}
