.bh-pointer {
    cursor: pointer;
}

.bh-events-none {
    pointer-events: none;
}

.bh-opacity {
    @for $i from 1 through 9 {
        &_#{$i} {
            opacity: calc(#{$i} / 10);
        }
    }
}

.bh-sticky {
    position: sticky;
    top: var(--bazis-sticky-top);
    bottom: var(--bazis-sticky-bottom);
    left: var(--bazis-sticky-start);
    right: var(--bazis-sticky-end);
    z-index: var(--bazis-sticky-z, 150);
    // background: var(--bazis-background-color);
}

.bh-popper-z-index {
    z-index: 1000;
}

.bh-bg {
    --background: var(--bazis-bg-base);
    background: var(--background);
}

.bh-relative {
    position: relative;
}

.bh-static {
    position: static;
}

.bh-overhide {
    overflow: hidden;
}

.bh-scroll {
    overflow: auto;
}
.bh-scroll-x {
    overflow-x: auto;
    overflow-y: hidden;
}
.bh-scroll-y {
    overflow-x: hidden;
    overflow-y: auto;
}
// for webkit
.bh-scroll_hidden::-webkit-scrollbar {
    display: none;
}
.bh-scroll_hidden-x::-webkit-scrollbar:horizontal {
    display: none;
}
.bh-scroll_hidden-y::-webkit-scrollbar:vertical {
    display: none;
}

.bh-scroll_hidden {
    -ms-overflow-style: none; /* IE и Edge */
    scrollbar-width: none; /* Firefox */
}
.bh-scroll_hidden-x {
    /* нет решения для мозиллы */
}
.bh-scroll_hidden-y {
    margin-right: -14px;
    padding-right: 14px;
}

.bh-ellipsis {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
p.bh-ellipsis,
h1.bh-ellipsis {
    display: block;
}

.bh-underconstruction,
.bh-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;

    *,
    bazis-button::part(native) {
        pointer-events: none;
        cursor: not-allowed !important;
    }
}

.bh-stop-scroll {
    position: fixed;
    left: 0;
    right: 0;
    padding-right: var(--bazis-padding-1x); // for scroll
}

.bh-word-break {
    word-break: break-all;
}

.bh-clear-left {
    clear: left;
}

.bh-mobile-rtl-ellipsis {
    @media screen and (max-width: 576px) {
        text-overflow: ellipsis;
        direction: rtl;
        text-align: left;
    }
}
