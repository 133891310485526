.bazis-tab-status {
    --margin-start: var(--bazis-margin-8x);

    &__item {
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);

        --color: var(--bazis-color-action);
        --background-active: var(--bazis-color-action);
        --color-active: var(--bazis-color-action-contrast);
        --background-hover: var(--bazis-background-medium);
        --color-hover: var(--bazis-color-action);
        --background-disabled: var(--bazis-background-dark);
        --color-disabled: var(--bazis-text-color-secondary);
        --background-active-disabled: var(--bazis-text-color-secondary);
        --color-active-disabled: var(--bazis-text-color-contrast);

        --border-radius: 16px;
        --border-style: solid;
        --border-width: 1px;
        --border-color: transparent;
        --border-color-active: var(--background-active);
        --border-color-hover: var(--bazis-border-color);
        --border-color-disabled: var(--background-disabled);
        --border-color-active-disabled: var(--background-active-disabled);
    }
}
