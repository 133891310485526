.bazis-gallery {
    &-preview {
        // TODO: пересмотреть остальные макеты, скорей всего этот вариант размером превьюшки будет общим
        app-sale-lot-summary &,
        app-sale-lot &,
        app-sale-contract &,
        app-sale-carrier-lot & {
            --width: 215px;
            --height: 134px;

            .bazis-figure {
                --width: 215px;
                --height: 134px;
            }
        }
    }

    @media (min-width: 1180.98px), (max-width: 1024px) and (min-width: 820.98px) {
        &-preview {
            app-wasteplace-widget .bazis-dl_inline &,
            app-facility-widget .bazis-dl_inline & {
                display: none;
            }
        }
    }

    @media (max-width: 1180px) and (min-width: 1024.98px), (max-width: 820px) {
        &-preview {
            app-wasteplace-widget &:not(.bazis-dl_inline &),
            app-facility-widget &:not(.bazis-dl_inline &) {
                display: none;
            }

            app-wasteplace-widget .bazis-dl_inline &,
            app-facility-widget .bazis-dl_inline & {
                --height: 106px;
                --width: 156px;
                margin-left: var(--bazis-margin-2x);

                .bazis-figure {
                    --height: 106px;
                    --width: 156px;
                }

                .bazis-gallery-preview__more {
                    --height: 106px;
                }
            }
        }
    }

    @media (max-width: 576px) {
        &-preview {
            app-wasteplace-widget .bazis-dl_inline &,
            app-facility-widget .bazis-dl_inline & {
                --height: 72px;
                --width: 104px;

                .bazis-figure {
                    --height: 72px;
                    --width: 104px;
                }

                .bazis-gallery-preview__more {
                    --height: 72px;
                }
            }
        }
    }
}

bazis-gallery-slider {
    max-width: 864px;

    &.sl-gallery_square {
        display: block;
        margin-bottom: var(--bazis-margin-8x);
    
        .bazis-list_gallery {
            > .bazis-item {
                --margin-end: var(--bazis-margin-1x);
            }
    
            .bazis-figure {
                --width: 260px;
                --height: 260px;
                --border-width: 0;
                cursor: pointer;
    
                bazis-button {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                }
    
                &_medium {
                    --width: 336px;
                }
                &_btn {
                    --border-width: 1px;
                    display: flex;
                }
            }
        }
    }
}
