app-lot-propositions {
    .sl-btn_prev,
    .sl-btn_next {
        --border-color: var(--bazis-border-color-action);
        position: absolute;
        top: 50%;
        margin-top: -22px;
    }
    .sl-btn_prev {
        left: -22px;
    }
    .sl-btn_next {
        right: -22px;
    }

    .bazis-chip_clear {
        --padding-start: 0;
        --padding-end: 0;
    }

    hr {
        --border-color: var(--bazis-border-color-dark);
        --border-style: dashed;
        --margin-top: var(--bazis-margin-5x);
        --margin-bottom: var(--bazis-margin-5x);
    }

    @media (max-width: 576px) {
        .bazis-block {
            &__header {
                .bazis-block_accent & {
                    // --border-style: dashed;
                }

                > .bh-flex {
                    > * {
                        width: 100%;
                        flex-shrink: 0;
                    }
                }
            }
            &__footer_normal {
                .bazis-block_accent & {
                    --margin-top: var(--bazis-margin-5x);
                    --padding-top: var(--bazis-padding-5x);
                }
            }
        }

        &,
        &-mobile {
            .bazis-file-inline {
                .bh-ellipsis {
                    max-width: 198px;
                }
            }

            bazis-chip {
                --margin-start: 0;
                --margin-end: 0;

                &.bazis-chip_clear {
                    --padding-start: 0;
                    --padding-end: 0;
                }
            }

            .sl-btn_prev,
            .sl-btn_next {
                position: static;
                margin-top: 0;
            }

            // .bazis-block__footer {
            //     .bazis-btn_solid {
            //         display: block;
            //     }
            //     bazis-toolbar {
            //         flex-wrap: wrap;

            //         &::part(start),
            //         &::part(end) {
            //             width: 100%;
            //             flex-shrink: 0;
            //         }

            //         .bh-line-elements[slot] {
            //             display: block;

            //             // > * {
            //             //     margin: 0;
            //             // }
            //         }
            //     }
            // }
        }
    }
}

app-lot-proposition,
app-proposition-skeleton {
    .bazis-block_accent .bazis-block__footer {
        --margin-top: var(--bazis-margin-6x);
        --padding-top: var(--bazis-padding-6x);
        --margin-start: 0;
        --margin-end: 0;
        --padding-start: 0;
        --padding-end: 0;
        --border-color: var(--bazis-border-color-dark);
    }

    app-entity-transits bazis-toolbar,
    bazis-entity-transits bazis-toolbar {
        gap: var(--bazis-margin-4x);

        &::part(start),
        &::part(end) {
            gap: var(--bazis-margin-4x);
        }

        [slot='end']:not(bazis-icon) {
            display: flex;
            gap: var(--bazis-margin-4x);
        }
    }
}

app-lot-proposition {
    .bazis-block {
        --padding-bottom: var(--bazis-padding-4x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --margin-bottom: var(--bazis-margin-2x);

        .bazis-control__label {
            --font-size-label: 13px;
            --color-label: var(--bazis-text-color-secondary);
            font-weight: 400;
        }
    }

    .bazis-grid_form > bazis-row:not(:last-child) > bazis-col {
        --bazis-grid-column-padding-bottom: var(--bazis-padding-6x);
    }

    @media (max-width: 576px) {
        .bazis-grid_form {
            > bazis-row > bazis-col {
                --bazis-grid-column-padding-bottom: var(--bazis-padding-4x);
                --bazis-grid-column-padding-start: 0;
                --bazis-grid-column-padding-end: 0;
            }
            > bazis-row:last-child > bazis-col:last-child {
                --bazis-grid-column-padding-bottom: 0;
            }
        }

        .sl-text_field-height {
            line-height: 20px;
        }
    }
}

#propositions {
    @media (max-width: 576px) {
        &.bazis-block {
            --padding-top: var(--bazis-padding-5x);
            --padding-start: var(--bazis-padding-5x);
            --padding-end: var(--bazis-padding-5x);
        }
    }
}

.sl-modal_propositions {
    .bazis-modal {
        &-wrap {
            --max-height: 100%;
        }
        &__header {
            --padding-end-header: var(--bazis-padding-5x);
        }
        &__content {
            --padding-top-content: 0;
            --padding-bottom-content: 0;
            --padding-start-content: 0;
            --padding-end-content: 0;
        }
    }
    .bazis-block_accent {
        --padding-top: var(--bazis-padding-5x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --padding-bottom: 0;
        --margin-bottom: 0;
        min-height: 100%;

        .bazis-block__header {
            --margin-bottom: var(--bazis-margin-5x);
            --padding-bottom: var(--bazis-padding-5x);
        }

        .bazis-block__footer {
            --padding-top: var(--bazis-padding-5x);
            --padding-start: var(--bazis-padding-5x);
            --padding-end: var(--bazis-padding-5x);
            --padding-bottom: var(--bazis-padding-5x);
            --margin-start: calc(var(--bazis-margin-5x) * -1);
            --margin-end: calc(var(--bazis-margin-5x) * -1);
            position: sticky;
            bottom: 0;
            background: var(--bazis-color-white);
        }
    }

    hr {
        --border-color: var(--bazis-border-color-dark);
        // --border-style: dashed;
        --margin-top: var(--bazis-margin-5x);
        --margin-bottom: var(--bazis-margin-5x);
    }
}

app-sale-carrier-propositions-view {
    h3 {
        --bazis-h3-margin: 40px 0 var(--bazis-margin-6x);
    }
}
