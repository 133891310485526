.bazis-alert {
    --top: 0;
    --bottom: 0;
    --left: 0;
    --right: 0;

    --max-width: 100%;
    --width: 976px;
    --padding-start: calc(var(--bazis-padding-3x) * 3);
    --padding-end: calc(var(--bazis-padding-3x) * 3);
    --padding-top: calc(var(--bazis-padding-3x) * 3);
    --padding-bottom: calc(var(--bazis-padding-3x) * 3);

    --background: var(--bazis-background);
    --border-radius: var(--bazis-border-radius);
    --box-shadow: 0px 10px 20px -10px rgba(144, 150, 162, 0.2);
    --max-height: calc(100vh - var(--bazis-padding-3x) * 3);

    &__message {
        bazis-transit-modal & {
            padding-bottom: 40px;
        }
    }

    &__buttons {
        bazis-transit-modal & bazis-button {
            margin-top: 0;
        }
    }

    &_small {
        --max-width: 664px;

        .bazis-alert__mesage {
            color: var(--bazis-text-color);
        }
    }

    #cadesplugin_ovr & {
        --width: 450px;

        .bazis-alert__wrapper {
            position: relative;

            .bazis-alert__close {
                position: absolute;
                right: 16px;
                top: 16px;
            }
        }
    }

    //TODO: fot modal alert ?
    &_medium {
        --width: 664px;
    }

    &_medium2 {
        --width: 752px;
    }
}
