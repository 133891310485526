.sl-widget_visit-facts.bazis-block {
    --padding-bottom: 0;

    .bazis-block__footer {
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
