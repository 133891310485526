html {
    font-family: var(--bazis-font-family);
    font-weight: 400;
    font-size: var(--bazis-font-size);
    line-height: var(--bazis-line-height);
    color: var(--bazis-text-color);
}

h1,
.bazis-h1 {
    margin: var(--bazis-h1-margin);
    font-size: var(--bazis-font-size-h1);
    line-height: var(--bazis-line-height-h1);
}
.bazis-h1_major {
    margin: var(--bazis-h1-margin-major, var(--bazis-h1-margin));
    font-size: var(--bazis-font-size-h1-major, var(--bazis-font-size-h1));
    line-height: var(--bazis-line-height-h1-major, var(--bazis-line-height-h1));
}
.bazis-h1_minor {
    margin: var(--bazis-h1-margin-minor, var(--bazis-h1-margin));
    font-size: var(--bazis-font-size-h1-minor, var(--bazis-font-size-h1));
    line-height: var(--bazis-line-height-h1-minor, var(--bazis-line-height-h1));
}

h2,
.bazis-h2 {
    margin: var(--bazis-h2-margin);
    font-size: var(--bazis-font-size-h2);
    line-height: var(--bazis-line-height-h2);
}
.bazis-h2_major {
    margin: var(--bazis-h2-margin-major, var(--bazis-h2-margin));
    font-size: var(--bazis-font-size-h2-major, var(--bazis-font-size-h2));
    line-height: var(--bazis-line-height-h2-major, var(--bazis-line-height-h2));
}
.bazis-h2_minor {
    margin: var(--bazis-h2-margin-minor, var(--bazis-h2-margin));
    font-size: var(--bazis-font-size-h2-minor, var(--bazis-font-size-h2));
    line-height: var(--bazis-line-height-h2-minor, var(--bazis-line-height-h2));
}

h3,
.bazis-h3 {
    margin: var(--bazis-h3-margin);
    font-size: var(--bazis-font-size-h3);
    line-height: var(--bazis-line-height-h3);
}
.bazis-h3_major {
    margin: var(--bazis-h3-margin-major, var(--bazis-h3-margin));
    font-size: var(--bazis-font-size-h3-major, var(--bazis-font-size-h3));
    line-height: var(--bazis-line-height-h3-major, var(--bazis-line-height-h3));
}
.bazis-h3_minor {
    margin: var(--bazis-h3-margin-minor, var(--bazis-h3-margin));
    font-size: var(--bazis-font-size-h3-minor, var(--bazis-font-size-h3));
    line-height: var(--bazis-line-height-h3-minor, var(--bazis-line-height-h3));
}

h4,
.bazis-h4 {
    margin: var(--bazis-h4-margin);
    font-size: var(--bazis-font-size-h4);
    line-height: var(--bazis-line-height-h4);
}
.bazis-h4_major {
    margin: var(--bazis-h4-margin-major, var(--bazis-h4-margin));
    font-size: var(--bazis-font-size-h4-major, var(--bazis-font-size-h4));
    line-height: var(--bazis-line-height-h4-major, var(--bazis-line-height-h4));
}
.bazis-h4_minor {
    margin: var(--bazis-h4-margin-minor, var(--bazis-h4-margin));
    font-size: var(--bazis-font-size-h4-minor, var(--bazis-font-size-h4));
    line-height: var(--bazis-line-height-h4-minor, var(--bazis-line-height-h4));
}

h5,
.bazis-h5 {
    margin: var(--bazis-h5-margin);
    font-size: var(--bazis-font-size-h5);
    line-height: var(--bazis-line-height-h5);
}
.bazis-h5_major {
    margin: var(--bazis-h5-margin-major, var(--bazis-h5-margin));
    font-size: var(--bazis-font-size-h5-major, var(--bazis-font-size-h5));
    line-height: var(--bazis-line-height-h5-major, var(--bazis-line-height-h5));
}
.bazis-h5_minor {
    margin: var(--bazis-h5-margin-minor, var(--bazis-h5-margin));
    font-size: var(--bazis-font-size-h5-minor, var(--bazis-font-size-h5));
    line-height: var(--bazis-line-height-h5-minor, var(--bazis-line-height-h5));
}

h6,
.bazis-h6 {
    margin: var(--bazis-h6-margin);
    font-size: var(--bazis-font-size-h6);
    line-height: var(--bazis-line-height-h6);
}
.bazis-h6_major {
    margin: var(--bazis-h6-margin-major, var(--bazis-h6-margin));
    font-size: var(--bazis-font-size-h6-major, var(--bazis-font-size-h6));
    line-height: var(--bazis-line-height-h6-major, var(--bazis-line-height-h6));
}
.bazis-h6_minor {
    margin: var(--bazis-h6-margin-minor, var(--bazis-h6-margin));
    font-size: var(--bazis-font-size-h6-minor, var(--bazis-font-size-h6));
    line-height: var(--bazis-line-height-h6-minor, var(--bazis-line-height-h6));
}

p,
.bazis-p {
    margin: var(--bazis-p-margin);
    font-size: var(--bazis-font-size-p);
    line-height: var(--bazis-line-height-p);
}
.bazis-p_major {
    margin: var(--bazis-p-margin-major, var(--bazis-p-margin));
    font-size: var(--bazis-font-size-p-major, var(--bazis-font-size-p));
    line-height: var(--bazis-line-height-p-major, var(--bazis-line-height-p));
}
.bazis-p_minor {
    margin: var(--bazis-p-margin-minor, var(--bazis-p-margin));
    font-size: var(--bazis-font-size-p-minor, var(--bazis-font-size-p));
    line-height: var(--bazis-line-height-p-minor, var(--bazis-line-height-p));
}

small {
    font-size: 75%;
}
.bazis-small {
    font-size: var(--bazis-font-size-small);
    line-height: var(--bazis-line-height-small);
}
.bazis-small_uppercase {
    @extend .bazis-small;
    text-transform: uppercase;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

a {
    background-color: transparent;
    color: var(--bazis-link-color);

    &:hover:not(.bazis-block) {
        color: var(--bazis-link-hover-color)
    }
}

.bazis-text {
    font-size: var(--bazis-font-size-p);
    line-height: var(--bazis-line-height-p);

    // text action
    &-action {
        color: var(--bazis-color-action);
    }

    // text secondary
    &_secondary {
        color: var(--bazis-text-color-secondary);
    }

    // text placeholder
    &_placeholder {
        color: var(--bazis-placeholder-color);
    }

    &_major {
        font-size: var(--bazis-font-size-p-major);
        line-height: var(--bazis-line-height-p-major);
    }
    &_minor {
        font-size: var(--bazis-font-size-p-minor);
        line-height: var(--bazis-line-height-p-minor);
    }

    &_reduced {

    }
}
