bazis-toast {
    --background: var(--bazis-background);
    --border-radius: var(--bazis-border-radius);
    --box-shadow: 0px 10px 20px -10px rgba(144, 150, 162, 0.2);
    --max-width: 416px;
    --width: 416px;
    --max-height: calc(100vh - var(--bazis-padding-3x) * 3);

    &::part(toast) {
        margin-bottom: var(--bazis-margin-3x);
    }
}
