bazis-stars {
    --gap: var(--bazis-margin-1x);

    &::part(star) {
        --star-active: var(--bazis-color-warning);
        --star-inactive: rgba(var(--bazis-text-color-secondary-rgb), 0.8);
        --size: 16px;
    }
}

bazis-star {
    --star-active: var(--bazis-color-warning);
    --star-inactive: rgba(var(--bazis-text-color-secondary-rgb), 0.8);
    // --size: 16px;
}
