// TODO: перевести в атрибуты bazis-row
bazis-row {
    &.bazis-row_vertical-zero {
        > bazis-col {
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;
            --bazis-grid-column-padding-top-xs: 0;
            --bazis-grid-column-padding-bottom-xs: 0;
            --bazis-grid-column-padding-top-sm: 0;
            --bazis-grid-column-padding-bottom-sm: 0;
            --bazis-grid-column-padding-top-md: 0;
            --bazis-grid-column-padding-bottom-md: 0;
            --bazis-grid-column-padding-top-xl: 0;
            --bazis-grid-column-padding-bottom-xl: 0;
        }
    }
}
