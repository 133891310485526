.bazis-browse-default {
    @include props-margin();
    @include props-font();

    --margin-title: 0;
    --color: inherit;

    @include margin();
    @include font();

    &__title {
        margin:  var(--margin-title);
        color: var(--color);
    }
}
