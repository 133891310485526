.sl-dispatcher-panel-filter {
    overflow: hidden;
    height: 100%;

    header {
        bazis-toolbar {
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --padding-top: var(--bazis-padding-3x);
            --padding-bottom: 0;
        }
    }

    main {
        padding: var(--bazis-padding-4x);
        height: calc(100% - 46px);
        overflow-y: auto;
        overflow-x: hidden;

        .bazis-control {
            --margin-bottom: var(--bazis-margin-6x);
        }
    }
}

.sl-dispatcher-filter-map-panel {
    display: flex;
    margin-right: var(--bazis-margin-6x);
    margin-bottom: var(--bazis-margin-3x);
    pointer-events: all;

    > * {
        margin-right: var(--bazis-margin-2x);

        &:last-child {
            margin-right: 0;
        }
    }

    .sl-control__field_action {
        cursor: pointer;

        bazis-icon {
            margin-right: var(--bazis-margin-2x);
            color: var(--bazis-text-color-secondary);
        }

        bazis-badge {
            --height-large: 20px;
            --font-size-large: 13px;
            --margin-end: 0;
            align-items: center;

            // @media only screen and ( -webkit-max-device-pixel-ratio: 1 ){
            //     line-height: var(--height-large);
            // }
        }

        + .bazis-control__field-group__end {
            cursor: pointer;
        }
    }

    bazis-select {
        --control-width: 206px;

        .bazis-select__trigger_noempty {
            color: var(--bazis-color-primary);
        }
    }
}
