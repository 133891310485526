.bazis-schedule-control {
    table {
        --margin-top: 13px;

        td {
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);

            &:first-child {
                --padding-start: 0;
                min-width: 240px;
            }

            min-width: 218px;

            &.bazis-table__cell_action {
                --padding-end: 0;
                min-width: 0;
            }

            .bazis-control {
                max-width: 226px;
                white-space: nowrap;
            }
        }
    }
}
