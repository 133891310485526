.bazis-file-inline {
    .bazis-list {
        .bazis-item {
            bazis-link {
                --line-height: 20px;
            }

            @media only screen and ( -webkit-max-device-pixel-ratio: 1 ){
                bazis-icon {
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    .bazis-list .bazis-item:last-child & {
        margin-bottom: 40px;
    }
}

