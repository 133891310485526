.bazis-block {
    --padding-top: var(--bazis-padding-4x);
    --padding-start: var(--bazis-padding-6x);
    --padding-end: var(--bazis-padding-6x);
    --padding-bottom: var(--bazis-padding-6x);
    --background: var(--bazis-background);
    --border-radius: var(--bazis-border-radius);
    --margin-bottom: var(--bazis-margin-5x);

    &__header {
        --margin-bottom: var(--bazis-margin-4x);

        &:last-child {
            --margin-bottom: 0;
        }

        .bazis-block_accent & {
            --margin-bottom: var(--bazis-margin-6x);
            --padding-bottom: var(--bazis-padding-6x);
            --border-width: 0 0 1px 0;
            --border-style: solid;
            --border-color: var(--bazis-border-color-dark);
        }
    }

    &__content {
        &_start {
            flex-shrink: 0;
        }
        &_main {
            --width: 100%;

            &__left {
                flex-shrink: 0;
                width: 200px;
                margin-right: 40px;

                bazis-dateplate + bazis-dateplate {
                    display: block;
                    margin-top: var(--bazis-margin-3x);
                }
            }
            &__center {
                width: 100%;
            }
            .bazis-block__footer {
                --padding-top: var(--bazis-padding-3x);
                --margin-start: 0;
                --margin-end: 0;
                --padding-start: 0;
                --padding-end: 0;
            }
        }
        &_end {
            --padding-start: var(--bazis-padding-8x);
            --border-width: 0 0 0 1px;
            --border-style: solid;
            --border-color: var(--bazis-border-color);
            --width: 337px;
            --margin-start: var(--bazis-margin-8x);
            flex-shrink: 0;
        }
    }

    &__footer {
        --margin-top: var(--bazis-margin-8x);
        --margin-start: calc(var(--bazis-margin-6x) * -1);
        --margin-end: calc(var(--bazis-margin-6x) * -1);
        --border-width: 1px 0 0 0;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --padding-top: var(--bazis-padding-5x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);

        .bazis-block_accent & {
            --margin-top: var(--bazis-margin-4x);
            --margin-start: calc(var(--bazis-margin-5x) * -1);
            --margin-end: calc(var(--bazis-margin-8x) * -1);
            --padding-start: var(--bazis-padding-5x);
            --padding-end: var(--bazis-padding-8x);

            > .bh-flex {
                margin-left: 70px;
                gap: var(--bazis-margin-6x);

                > * {
                    width: 100%;
                }
            }

            .bazis-h6 {
                margin-bottom: var(--bazis-margin-1x);
                line-height: 16px;
            }
        }

        aside & {
            --margin-top: 0;
            --margin-start: calc(var(--bazis-margin-4x) * -1);
            --margin-end: calc(var(--bazis-margin-4x) * -1);
            --padding-top: var(--bazis-padding-5x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
        }
    }

    &_hoverable:hover {
        .bazis-block__title bazis-link,
        bazis-link.bazis-block__title,
        .bazis-block__title {
            --color: var(--bazis-link-hover-color);
            color: var(--bazis-link-hover-color);
        }
    }

    &_outline {
        --padding-top: var(--bazis-padding-8x);
        --padding-start: var(--bazis-padding-8x);
        --padding-end: var(--bazis-padding-8x);
        --padding-bottom: var(--bazis-padding-8x);

        @media (max-width: 1024px) {
            --padding-top: var(--bazis-padding-6x);
            --padding-start: var(--bazis-padding-5x);
            --padding-end: var(--bazis-padding-5x);
            --padding-bottom: var(--bazis-padding-6x);
        }

        .bazis-toolbar_baseline + & {
            --margin-top: var(--bazis-margin-4x);
            --padding-top: var(--bazis-padding-6x);
        }

        & + .bazis-toolbar_baseline {
            --margin-top: 40px;
        }
    }

    &_editable {
        --padding-top: var(--bazis-padding-2x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-2x);
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color-dark);
        --border-color-hover: var(--bazis-text-secondary);
        --margin-bottom: var(--bazis-margin-2x);

        .bazis-list_short-info {
            --color: var(--bazis-color-action);
        }
    }

    &_accent {
        // 32 32 32 32 синие блоки
        --padding-top: var(--bazis-padding-8x);
        --padding-start: var(--bazis-padding-8x);
        --padding-end: var(--bazis-padding-8x);
        --padding-bottom: var(--bazis-padding-8x);
        --background: var(--bazis-background-accent);

        .bazis-block & {
            --margin-top: var(--bazis-margin-5x);
            --margin-bottom: 0;
            --padding-top: var(--bazis-padding-2x);
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);
            --padding-bottom: var(--bazis-padding-2x);
        }

        hr {
            --margin-top: var(--bazis-margin-6x);
            --margin-bottom: var(--bazis-margin-6x);
        }

        bazis-skeleton {
            --background: var(--bazis-border-color-dark);
        }

        @media (max-width: 576px) {
            .bazis-block__footer > .bh-flex {
                display: block;
                margin-left: 0;

                .basiz-small {
                    font-size: 14px;
                    line-height: 20px;
                }

                > *:not(.bh-no-margin) {
                    margin-bottom: var(--bazis-margin-5x);
                }
            }
        }
    }

    &_warning {
        // желтоватые блоки
        --margin-bottom: 0;
        --padding-top: var(--bazis-padding-4x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-4x);
        --background: var(--bazis-background-info-secondary);

        > bazis-icon {
            float: left;
            margin-right: var(--bazis-margin-3x);
        }

        .bazis-block__content {
            overflow: hidden;
            line-height: 20px;

            .bazis-list_short-info {
                --margin-top-list: 3px;
            }
        }

        @media (max-width: 576px) {
            .bazis-block__content .bazis-list_short-info {
                flex-wrap: wrap;
            }
        }
    }

    &_secondary {
        // серые
        --background: var(--bazis-background-medium);
    }

    &_on-map {
        --width: 357px;
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
    }

    aside & {
        --padding-top: var(--bazis-padding-5x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-5x);

        hr {
            --margin-top: var(--bazis-margin-4x);
            --margin-bottom: var(--bazis-margin-4x);
        }

        &__header {
            h6 a {
                text-decoration: none;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 2px;
                    right: 2px;
                    bottom: -2px;

                    border-bottom: 1px dashed var(--bazis-link-color);
                }
            }
        }

        &.bazis-block_outline {
            bazis-toolbar:first-child {
                margin-top: -10px;
            }
        }
    }

    @media (max-width: 576px) {
        --margin-bottom: var(--bazis-margin-4x);
    }
}

a.bazis-block {
    display: block;
    text-decoration: none;
}
