/*
    TODO: подумать над преобразованием в компонент.

    Принять ко вниманию при классификации ссылку ниже
    https://ux.pub/editorial/vsie-chto-nuzhno-znat-o-raskryvaiushchikhsia-spiskakh-dropdown-5ea2

    Можно ипользовать теги <details><summary></summary></details>
*/
.bazis-dropdown {
    /*
    * @prop --width:
    *
    * @prop --padding-start:
    * @prop --padding-end:
    * @prop --padding-top:
    * @prop --padding-bottom:
    * @prop --background:
    * @prop --border-radius:
    * @prop --border-width:
    * @prop --border-style:
    * @prop --border-color:
    * @prop --box-shadow:
    *
    * @prop --top:
    * @prop --bottom:
    * @prop --left:
    * @prop --right:
    */
    --width: initial;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --background: initial;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --box-shadow: initial;
    --top: initial;
    --bottom: initial;
    --left: initial;
    --right: initial;

    position: relative;

    &__body {
        position: absolute;
        z-index: 161;
        top: var(--top);
        bottom: var(--bottom);
        left: var(--left);
        right: var(--right);
        width: var(--width);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        background: var(--background);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        box-shadow: var(--box-shadow);

        .bazis-dropdown_hoverable & {
            visibility: hidden;
        }

        .bazis-dropdown_hoverable:hover & {
            visibility: visible;
        }
    }

    bazis-button.bazis-dropdown__trigger,
    &__trigger bazis-button,
    a.bazis-dropdown__trigger,
    &__trigger a {
        --color: inherit;
        --color-link-hover: var(--color);
        --background-hover: var(--background);
        white-space: nowrap;
    }

    .bazis-list_select {
        position: static;
    }
}
