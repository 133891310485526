.bazis-vehicle-number-control {
    --width-group: 205px;

    &_rus {
        --height: 24px;

        .bazis-vehicle-number-control__part {
            &_0 {
                --margin-start: var(--bazis-margin-3x);
                --margin-end: var(--bazis-margin-1x);
                --margin-bottom: 2px;
                --font-size: 18px;
                --line-height: 15px;
                width: 17px;
            }
            &_1 {
                --margin-end: 0;
                --margin-bottom: 2px;
                --font-size: 26px;
                --height: 32px;
                --line-height: 17px;
                width: 64px;

                input {
                    letter-spacing: 4px;
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                    --margin-bottom: -1px;
                }
            }
            &_2 {
                --margin-start: var(--bazis-margin-1x);
                --margin-end: var(--bazis-margin-3x);
                --margin-bottom: 2px;
                --font-size: 19px;
                --line-height: 15px;
                width: 39px;

                input {
                    letter-spacing: 3px;
                }
            }
            &_3 {
                --margin-end: 7px;
                --margin-start: 5px;
                --margin-bottom: 2px;
                --font-size: 15.75px;
                --line-height: 17px;
                height: 32px;
                width: 39px;
                align-self: flex-start;

                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    position: relative;
                    top: 3px;
                    left: -5px;
                    height: 30px;
                    background: var(--bazis-border-color-dark);
                }

                &::after {
                    content: 'RUS';
                    position: absolute;
                    left: 5px;
                    right: 5px;
                    bottom: 0;
                    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAZCAYAAABdEVzWAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADHSURBVEiJ7dE7DgFRGIbh9zeDuBQKEzSITmUR9mELWp1KKbEHK1EotS4NUchElEaDmaNgWsmp/inOW33lk3wCEBszSTAjlPORoYiE3w0kJE1B+rosAArpyGkq/uVgtjmYbT7A9hJFz9gctTHlfNGk2wcYTNdVhJ4e6Zcxks7MXulgtjmYbZmF+QDz3fIWvO8rbcyhFiSz3xaAqB0sBBkrmgDIeV63dArPkOErHcw2B7MtszABeHTqLRN7dW1MpXHdy4aXtuNvH86TKeD83bcOAAAAAElFTkSuQmCC') no-repeat 100% 50%;
                    background-size: contain;
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: 7.36px;
                    font-weight: 700;
                    line-height: 6.35px;
                    letter-spacing: 0;
                    color: var(--bazis-text-color);
                }

                input {
                    letter-spacing: 2px;
                }
            }

            @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
                &_0 {
                    --line-height: 10px;
                }
                &_1 {
                    --line-height: 12px;
                }
                &_2 {
                    --line-height: 10px;
                }
            }
        }
    }
}
