.sl-header {
    /*
    * @prop --height:
    * @prop --padding-top: верхний внутренний отступ
    * @prop --padding-bottom: нижний внутренний отступ
    * @prop --background: фон шапки
    * @prop --color: цвет текста в шапке
    * @prop --color-link: цвет ссылок в шапке
    * @prop --color-link-hover: цвет ссылок в шапке при наведении
    * @prop --color-link-disabled: цвет отключенных ссылок в шапке
    */
    --height: initial;
    --padding-top: initial;
    --padding-bottom: initial;
    --color-link: var(--bazis-color-action);
    --color-link-hover: var(--color-link);
    --color-link-disabled: var(--color-link);

    height: var(--height);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    background: var(--background);
    color: var(--color);

    &-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        app-draft-header & {
            height: 100%;
        }
    }

    a {
        color: var(--color-link);

        &:hover:not(.sl-header p a, a.bazis-item),
        &.active:not(.sl-header p a, a.bazis-item) {
            color: var(--color-link-hover);
        }

        &:disabled {
            color: var(--color-link-disabled);
        }
    }

    &__logo {
        /*
        * @prop --margin-top: внешний верхний отступ лого
        * @prop --margin-end: внешний конечный отступ лого
        * @prop --margin-bottom: внешний нижний отступ лого
        * @prop --margin-start: внешний начальный отступ лого
        */
        display: inline-block;
        flex-shrink: 0;
        margin-left: var(--margin-start);
        margin-right: var(--margin-end);
        margin-top: var(--margin-top);
        margin-bottom: var(--margin-bottom);
    }

    &.bazis-container {
        &::before,
        &::after {
            display: none;
        }
    }
}

// implemented
.sl-header {
    --height: 56px;
    --background: var(--bazis-header-background);
    --color: var(--bazis-text-color-contrast);
    --color-link: var(--color);
    --color-link-hover: var(--bazis-color-primary);
    --color-link-disabled: var(--bazis-placeholder-color);

    a {
        line-height: 20px;
        text-decoration: none;

        &[nohref] {
            cursor: default;
        }
    }

    &__logo {
        --margin-end: 44px;
    }

    &__menu,
    &__user-menu {
        & > .bazis-item {
            --margin-end: var(--bazis-margin-5x);
        }

        .bazis-dropdown {
            display: flex;
            height: var(--height);
            align-items: center;

            &__trigger {
                --padding-start: 0;
                --padding-end: 0;
                min-width: 36px;
            }
        }
    }

    &__menu,
    .sl-search_global {
        &.bazis-list {
            --font-weight: 500;
            --font-size: 14px;

            &_h {
                > .bazis-item {
                    --margin-end: var(--bazis-margin-6x);
                }
            }

            .bazis-item {
                .bazis-dropdown__body {
                    --left: 0;
                    --right: auto;
                }
            }
        }

        .bazis-dropdown__body {
            --padding-top: var(--bazis-padding);
            --padding-bottom: var(--bazis-padding);
            --padding-start: 0;
            --padding-end: 0;

            .bazis-item {
                --margin-bottom: 0;
                --padding-top: var(--bazis-padding-2x);
                --padding-bottom: var(--bazis-padding-2x);
                --padding-start: var(--bazis-padding);
                --padding-end: var(--bazis-padding);
                --background-hover: var(--bazis-background-dark);
            }
        }
    }

    &__user-menu {
        & > .bazis-item:last-child {
            --margin-end: 0;
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__profile {
        bazis-chip {
            // хардкод, чтобы не добавлялось дефолтное инлайновое вертикальное позиционирование
            // при этом блочное поведение нам тоже не нужно
            float: left;
            --margin-start: 0;
            --margin-end: 0;
            --margin-bottom: 0;
            --margin-top: var(--bazis-margin-2x);
            --height: 20px;
            --border-radius: 12px;
        }

        .bazis-avatar {
            position: relative;

            &:not(.bazis-avatar_small) {
                margin-right: var(--bazis-margin-5x);
            }

            &_small {
                font-size: var(--font-size);
            }

            bazis-icon {
                --bazis-icon-s-size: 12px;
                position: absolute;
                bottom: 0;
                right: -4px;
                margin-right: 0;
                background: var(--color);
                border-radius: 50%;
                color: var(--bazis-header-background);
            }

            &:hover {
                color: var(--color-hover);
            }
        }

        .bazis-dropdown {
            &__trigger {
                padding-top: var(--bazis-padding-3x);
                padding-bottom: var(--bazis-padding-3x);
            }
        }

        .bazis-menu {
            --margin-bottom: 0;
            --border-width: 1px 0 0 0;
            --border-style: solid;
            --border-color: var(--bazis-border-color);

            --padding-top-list: var(--bazis-padding-5x);
        }
    }

    .bazis-dropdown {
        &__body {
            --bazis-margin: 18px;
            --color: var(--bazis-text-color);
            --color-link: var(--bazis-color-action);
            --color-link-hover: var(--color-link);
            --color-link-disabled: var(--color-link);

            .bazis-list_underline {
                a,
                bazis-button {
                    --color: var(--bazis-text-color);
                }
            }
        }
    }

    .sl-lang {
        --padding-top: 3px;
        --padding-bottom: 3px;
        --padding-start: calc(20px - 36px / 4);
        --border-width: 0 0 0 1px;
        --border-style: none none none solid;
        --border-color: transparent transparent transparent rgba(224, 222, 220, 0.5);

        .bazis-dropdown {
            --width: 112px;
            --top: 32px;
            height: 24px;
            line-height: 24px;

            &__body {
                &::before {
                    position: absolute;
                    content: '';
                    top: -16px;
                    height: 16px;
                    left: 0;
                    right: 0;
                }

                .bazis-list_select {
                    --padding-top-list: var(--bazis-padding-3x);
                    --padding-bottom-list: var(--bazis-padding-3x);
                    --border-radius-list: var(--bazis-border-radius);
                }
            }
        }
    }

    .sl-search_global {
        .bazis-control {
            --background-hover: var(--bazis-color-white);
            --background-focus: var(--bazis-color-white);
            --background-active: var(--bazis-color-white);
            --background: rgba(var(--bazis-color-white-rgb), 0.1);
            --border-color: rgba(241, 246, 251, 0.3);
            --border-color-hover: var(--border-color-focus);
        }

        .bazis-control__field-group__start,
        .bazis-control__field-group__end,
        .bazis-control__field {
            background: transparent;
        }

        width: 208px;
        max-width: 980px;
        margin-right: var(--bazis-margin-2x);
    }

    &_search-active {
        .sl-header__menu {
            display: none;
        }

        .sl-header__user-menu {
            flex-shrink: 0;
            width: calc(100% - 154px); // ширина логотипа + отступ

            & > .bazis-item:first-child {
                width: 100%;

                .sl-search_global {
                    .bazis-control {
                        --background: var(--background-hover);
                        --border-color: var(--border-color-focus);
                    }
                    width: 100%;
                }
            }
        }
    }

    &_home {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 160;

        @media (min-width: 1024.98px) {
            --background: transparent;
        }
    }

    app-header-mobile & {
        position: relative;
    }

    .sl-menu-burger {
        --background: var(--bazis-header-background);
        --border-radius: 50%;

        position: fixed;
        z-index: 1001;
        right: 16px;
        top: 8px;

        // transition: top 200ms;
    }

    &-dropdown {
        position: fixed;
        z-index: 1001;
        overflow-x: hidden;
        overflow-y: auto;
        right: 16px;
        top: 56px;
        bottom: 12px;
        width: 344px;
        // height: calc(100% - 56px);
        background: var(--bazis-color-white);
        border-radius: var(--bazis-border-radius);
        box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
        padding: var(--bazis-padding-8x) var(--bazis-padding-5x) var(--bazis-padding-5x);

        .bazis-avatar {
            margin-right: var(--bazis-margin-5x);

            + div {
                bazis-chip {
                    --margin-start: 0;
                }
            }
        }

        .bazis-menu {
            --color: var(--bazis-text-color);
            --color-link: var(--color);
            --background-hover: transparent;
            --margin-bottom: var(--bazis-margin-6x);

            .bazis-item:not(a, bazis-button, button) {
                --padding-top: 0;
                --padding-bottom: 0;
                --padding-start: 0;
                --padding-end: 0;
            }
        }

        app-header-mobile & .bazis-list_overline {
            --margin-top-list: var(--bazis-margin-5x);
            --padding-start: 0;
            --padding-top: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);
            --margin-bottom: 0;
            --font-size: 14px;

            --border-width: 1px 0 0 0;
            --border-style: solid;
            --border-color: var(--bazis-border-color);

            bazis-button:not(.bazis-item) {
                --padding-start: 0;
                --padding-top: var(--bazis-padding-4x);
                --padding-bottom: var(--bazis-padding-4x);
            }
        }

        .bazis-item {
            a {
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;

                + .bazis-item__child {
                    margin-top: var(--bazis-margin-3x);
                }
            }

            &__child {
                a {
                    --padding-top: var(--bazis-padding-2x);
                    --padding-bottom: var(--bazis-padding-2x);
                    --padding-start: var(--bazis-padding-5x);
                    font-weight: 400;
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        padding-right: 40px;

        .sl-header__logo img {
            display: block;
        }

        // закомментила, так как сбивает внешний вид кнопки настроек в выпадающем блоке уведомлений.
        // а для какой кнопки стили - не нашла. Возможно кнопка поиска.
        // .sl-header__user-menu {
        //     bazis-button.bazis-btn_clear {
        //         --color: var(--bazis-color-white);
        //         --background-hover: transparent;
        //         --background-activated: transparent;
        //         --background-focused: transparent;
        //     }
        // }

        .sl-header-wrap {
            height: 100%;
        }

        &.sl-header--fixed {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1001;
        }
    }

    @media (max-width: 768px), (max-device-width: 576px) {
        .sl-header-dropdown {
            right: 0;
            left: 0;
            bottom: auto;
            width: 100%;
            max-height: 100%;
            overflow: auto;
            border-radius: 0;
        }
    }

    @media only screen and (max-height: 575.98px) and (orientation: landscape) {
        .sl-header-dropdown {
            bottom: 0;
        }
    }

    @media (max-width: 576px) {
        .sl-header__logo img {
            height: 36px;
        }
    }
}
