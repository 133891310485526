bazis-button.bazis-button_nowrap {
    aside & {
        width: calc(100% - 52px);
    }
}

app-order-status-widget,
app-contract-status-widget,
app-sale-contract-status-widget {
    app-view-entity-transits-widget {
        .bazis-item:first-child:last-child {
            bazis-button {
                display: block;
            }
        }
    }
}

app-input-options-chip {
    bazis-chip {
        --height: 32px;
        --margin-start: 0;
        --margin-bottom: var(--bazis-margin-2x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --border-radius: 24px;
        --font-size: 13px;
        --font-weight: 400;

        &.bazis-chip_clear {
            background: var(--bazis-background-medium);
        }

        &.bazis-chip_solid {
            background: var(--bazis-color-base);
            color: var(--bazis-color-contrast);
        }
    }
}

.sl-tank-amount {
    padding-top: 6px;
}

.sl-click-statistic {
    margin-top: calc(var(--bazis-margin-8x) * 2);
    margin-bottom: var(--bazis-margin-5x);

    + .bazis-btn-group {
        margin-right: var(--bazis-margin-3x);
        margin-bottom: var(--bazis-margin-8x);
    }
}

@media screen and (max-width: 1024px) {
    app-top-notifications .bazis-block {
        margin: var(--bazis-margin-4x) 0 0;
    }
}
